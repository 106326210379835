<template>
    <div class="pricing-wrapper">
        <div class="pricing-bg"></div>

        <v-container tag="section" id="pricing">
            <v-row class="d-none d-lg-flex">
                <v-col cols="4">
                    <v-card class="overflow-hidden" width="100%" min-width="380px" rounded="xl" elevation="5" id="latest">
                        <div class="pricing-table">
                            <v-simple-table>
                                <template>
                                    <thead>
                                    <tr>
                                        <td class="pricing-table-title">ارزانترین ها</td>
                                        <td class="price-header"><v-icon tag="i" right>mdi-check-circle-outline</v-icon>ثبت</td>
                                        <td class="price-header"><v-icon tag="i" right>mdi-autorenew</v-icon>تمدید</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(l, i) in pricingTables.cheapest" :key="i">
                                        <td>.{{ l.name }}</td>
                                        <td class="price" :class="currentCurrency.code.toLowerCase()">
                                            <v-badge :color="!!l.register.badge?'warning':'secondary'" left
                                                     :value="l.register.badge || l.register.discountPrice"
                                                     :content="!!l.register.badge?l.register.badge:
                                                            `${Math.round((1-l.register.discountPrice/l.register.originalPrice)*100)}%`" >
                                                {{ !!l.register.discountPrice? l.register.discountPrice: l.register.originalPrice | formatNumber}}
	                                            <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                            </v-badge>
                                        </td>
                                        <td class="price" :class="currentCurrency.code.toLowerCase()">
                                            <v-badge :color="!!l.renew.badge?'warning':'secondary'" left
                                                     :value="l.renew.badge || l.renew.discountPrice"
                                                     :content="!!l.renew.badge?l.renew.badge:
                                                            `${Math.round((1-l.renew.discountPrice/l.renew.originalPrice)*100)}%`" >
                                                {{ !!l.renew.discountPrice? l.renew.discountPrice: l.renew.originalPrice | formatNumber}}
	                                            <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                            </v-badge>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-card-actions class="d-flex justify-lg-space-between justify-end pa-0 mt-4">
                                <v-card-subtitle class="table-update-time d-none d-lg-block">
                                    <v-icon size="20">mdi-update</v-icon>
                                    بروزرسانی : {{ pricingTables.last }}
                                </v-card-subtitle>
                                <v-btn class="show-all-btn normal" color="secondary" text
                                       x-large width="180" elevation="0"
                                       height="60px" tile link :to="{name: 'Pricing'}">
                                    مشاهده همه
                                    <v-icon right>mdi-arrow-left</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card class="overflow-hidden" width="100%" min-width="380px" rounded="xl" elevation="5" id="special">
                        <div class="pricing-table">
                            <v-simple-table>
                                <template>
                                    <thead>
                                    <tr>
                                        <td class="pricing-table-special-title">فروش ویژه</td>
                                        <td class="price-header"><v-icon tag="i" right>mdi-check-circle-outline</v-icon>ثبت</td>
                                        <td class="price-header"><v-icon tag="i" right>mdi-autorenew</v-icon>تمدید</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(s, i) in pricingTables.special" :key="i">
                                        <td>.{{ s.name }}</td>
                                        <td class="price" :class="currentCurrency.code.toLowerCase()">
                                            <v-badge :color="!!s.register.badge?'warning':'secondary'" left
                                                     :value="s.register.badge || s.register.discountPrice"
                                                     :content="!!s.register.badge?s.register.badge:
                                                            `${Math.round((1-s.register.discountPrice/s.register.originalPrice)*100)}%`" >
                                                {{ !!s.register.discountPrice? s.register.discountPrice: s.register.originalPrice | formatNumber}}
	                                            <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                            </v-badge>
                                        </td>
                                        <td class="price" :class="currentCurrency.code.toLowerCase()">
                                            <v-badge :color="!!s.renew.badge?'warning':'secondary'" left
                                                     :value="s.renew.badge || s.renew.discountPrice"
                                                     :content="!!s.renew.badge?s.renew.badge:
                                                            `${Math.round((1-s.renew.discountPrice/s.renew.originalPrice)*100)}%`" >
                                                {{ !!s.renew.discountPrice? s.renew.discountPrice: s.renew.originalPrice | formatNumber}}
	                                            <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                            </v-badge>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-card-actions class="d-flex justify-end pa-0 mt-4">
                                <v-btn class="show-all-btn offer" color="warning" text
                                       x-large width="180" elevation="0"
                                       height="60px" tile link :to="{name: 'Pricing'}">
                                    مشاهده همه
                                    <v-icon right>mdi-arrow-left</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card class="overflow-hidden" width="100%" min-width="380px" rounded="xl" elevation="5" id="popular">
                        <div class="pricing-table">
                            <v-simple-table>
                                <template>
                                    <thead>
                                    <tr>
                                        <td class="pricing-table-title">پرفروش ترین ها</td>
                                        <td class="price-header"><v-icon tag="i" right>mdi-check-circle-outline</v-icon>ثبت</td>
                                        <td class="price-header"><v-icon tag="i" right>mdi-autorenew</v-icon>تمدید</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(p, i) in pricingTables.popular" :key="i">
                                        <td>.{{ p.name }}</td>
                                        <td class="price"  :class="currentCurrency.code.toLowerCase()">
                                            <v-badge :color="!!p.register.badge?'warning':'secondary'" left
                                                     :value="p.register.badge || p.register.discountPrice"
                                                     :content="!!p.register.badge?p.register.badge:
                                                            `${Math.round((1-p.register.discountPrice/p.register.originalPrice)*100)}%`" >
                                                {{ !!p.register.discountPrice? p.register.discountPrice: p.register.originalPrice | formatNumber}}
	                                            <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                            </v-badge>
                                        </td>
                                        <td class="price"  :class="currentCurrency.code.toLowerCase()">
                                            <v-badge :color="!!p.renew.badge?'warning':'secondary'" left
                                                     :value="p.renew.badge || p.renew.discountPrice"
                                                     :content="!!p.renew.badge?p.renew.badge:
                                                            `${Math.round((1-p.renew.discountPrice/p.renew.originalPrice)*100)}%`" >
                                                {{ !!p.renew.discountPrice? p.renew.discountPrice: p.renew.originalPrice | formatNumber}}
	                                            <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                            </v-badge>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-card-actions class="d-flex justify-end pa-0 mt-4">
                                <v-btn class="show-all-btn normal" color="secondary" text
                                       x-large width="180" elevation="0"
                                       height="60px" tile link :to="{name: 'Pricing'}">
                                    مشاهده همه
                                    <v-icon right>mdi-arrow-left</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-col>
<!--                <v-col cols="4">-->
<!--                    <v-card class="overflow-hidden" width="100%" min-width="380px" rounded="xl" elevation="5" id="suggestions">-->
<!--                        <div class="pricing-table">-->
<!--                            <v-simple-table>-->
<!--                                <template>-->
<!--                                    <thead>-->
<!--                                    <tr>-->
<!--                                        <td class="pricing-table-title">پیشنهاد برای شما</td>-->
<!--                                        <td class="price-header"><v-icon tag="i" right>mdi-check-circle-outline</v-icon>ثبت</td>-->
<!--                                    </tr>-->
<!--                                    </thead>-->
<!--                                    <tbody>-->
<!--                                    <tr v-for="s in special" :key="s.id">-->
<!--                                        <td>{{ s.name }}</td>-->
<!--                                        <td class="price">-->
<!--                                            <v-badge :color="s.register.badge?'warning':'secondary'" left-->
<!--                                                     :value="s.register.badge || s.register.discountPrice"-->
<!--                                                     :content="s.register.badge?s.register.badge:-->
<!--                                                            `${Math.round((1-s.register.discountPrice/s.register.originalPrice)*100)}%`" >-->
<!--                                                {{ !!s.register.discountPrice? s.register.discountPrice: s.register.originalPrice}}-->
<!--                                            </v-badge>-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                    </tbody>-->
<!--                                </template>-->
<!--                            </v-simple-table>-->
<!--                            <v-card-actions class="d-flex justify-end pa-0 mt-4">-->
<!--                                <v-btn class="show-all-btn" color="secondary"-->
<!--                                       x-large width="180" elevation="0"-->
<!--                                       height="60px" tile link to="/">-->
<!--                                    مشاهده همه-->
<!--                                    <v-icon right>mdi-arrow-left</v-icon>-->
<!--                                </v-btn>-->
<!--                            </v-card-actions>-->
<!--                        </div>-->
<!--                    </v-card>-->
<!--                </v-col>-->
            </v-row>

            <v-row class="d-flex d-lg-none">
                <v-col cols="12">
                    <v-card class="overflow-hidden" rounded="xl" elevation="5" >

                        <v-tabs v-model="tab" background-color="transparent" color="secondary" grow slider-color="secondary">
                            <v-tab>ارزان ترین ها</v-tab>
                            <v-tab>پرفروش ترین ها</v-tab>
                            <v-tab><v-badge color="warning" dot>فروش ویژه</v-badge></v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-card class="" flat id="cheapest-m">
                                    <div class="pricing-table mobile">
                                        <v-simple-table>
                                            <template>
                                                <thead>
                                                <tr>
                                                    <td class="pricing-table-title"></td>
                                                    <td class="price-header"><v-icon tag="i" right>mdi-check-circle-outline</v-icon>ثبت</td>
                                                    <td class="price-header"><v-icon tag="i" right>mdi-autorenew</v-icon>تمدید</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(l, i) in pricingTables.cheapest" :key="i">
                                                    <td>.{{ l.name }}</td>
                                                    <td class="price" :class="currentCurrency.code.toLowerCase()">
                                                        <v-badge :color="!!l.register.badge?'warning':'secondary'" left
                                                                 :value="l.register.badge || l.register.discountPrice"
                                                                 :content="!!l.register.badge?l.register.badge:
                                                            `${Math.round((1-l.register.discountPrice/l.register.originalPrice)*100)}%`" >
                                                            {{ !!l.register.discountPrice? l.register.discountPrice: l.register.originalPrice | formatNumber}}
	                                                        <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                                        </v-badge>
                                                    </td>
                                                    <td class="price" :class="currentCurrency.code.toLowerCase()">
                                                        <v-badge :color="!!l.renew.badge?'warning':'secondary'" left
                                                                 :value="l.renew.badge || l.renew.discountPrice"
                                                                 :content="!!l.renew.badge?l.renew.badge:
                                                            `${Math.round((1-l.renew.discountPrice/l.renew.originalPrice)*100)}%`" >
                                                            {{ !!l.renew.discountPrice? l.renew.discountPrice: l.renew.originalPrice | formatNumber}}
	                                                        <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                                        </v-badge>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <v-card-actions class="d-flex justify-lg-space-between justify-end pa-0 mt-4">
                                            <v-card-subtitle class="table-update-time d-none d-lg-block">
                                                <v-icon size="20">mdi-update</v-icon>
                                                بروزرسانی : {{ pricingTables.last }}
                                            </v-card-subtitle>
                                            <v-btn class="show-all-btn normal" color="secondary" text
                                                   x-large width="180" elevation="0"
                                                   height="60px" tile link :to="{name: 'Pricing'}">
                                                مشاهده همه
                                                <v-icon right>mdi-arrow-left</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </div>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card class="" flat id="popular-m">
                                    <div class="pricing-table mobile">
                                        <v-simple-table>
                                            <template>
                                                <thead>
                                                <tr>
                                                    <td class="pricing-table-title"></td>
                                                    <td class="price-header"><v-icon tag="i" right>mdi-check-circle-outline</v-icon>ثبت</td>
                                                    <td class="price-header"><v-icon tag="i" right>mdi-autorenew</v-icon>تمدید</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(p, i) in pricingTables.popular" :key="i">
                                                    <td>.{{ p.name }}</td>
                                                    <td class="price" :class="currentCurrency.code.toLowerCase()">
                                                        <v-badge :color="!!p.register.badge?'warning':'secondary'" left
                                                                 :value="p.register.badge || p.register.discountPrice"
                                                                 :content="!!p.register.badge?p.register.badge:
                                                            `${Math.round((1-p.register.discountPrice/p.register.originalPrice)*100)}%`" >
                                                            {{ !!p.register.discountPrice? p.register.discountPrice: p.register.originalPrice | formatNumber}}
	                                                        <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                                        </v-badge>
                                                    </td>
                                                    <td class="price" :class="currentCurrency.code.toLowerCase()">
                                                        <v-badge :color="!!p.renew.badge?'warning':'secondary'" left
                                                                 :value="p.renew.badge || p.renew.discountPrice"
                                                                 :content="!!p.renew.badge?p.renew.badge:
                                                            `${Math.round((1-p.renew.discountPrice/p.renew.originalPrice)*100)}%`" >
                                                            {{ !!p.renew.discountPrice? p.renew.discountPrice: p.renew.originalPrice | formatNumber}}
	                                                        <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                                        </v-badge>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <v-card-actions class="d-flex justify-end pa-0 mt-4">
                                            <v-btn class="show-all-btn normal" color="secondary" text
                                                   x-large width="180" elevation="0"
                                                   height="60px" tile link :to="{name: 'Pricing'}">
                                                مشاهده همه
                                                <v-icon right>mdi-arrow-left</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </div>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card class="" flat id="special-m">
                                    <div class="pricing-table mobile">
                                        <v-simple-table>
                                            <template>
                                                <thead>
                                                <tr>
                                                    <td class="pricing-table-title"></td>
                                                    <td class="price-header"><v-icon tag="i" right>mdi-check-circle-outline</v-icon>ثبت</td>
                                                    <td class="price-header"><v-icon tag="i" right>mdi-autorenew</v-icon>تمدید</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(s, i) in pricingTables.special" :key="s.id">
                                                    <td>.{{ s.name }}</td>
                                                    <td class="price" :class="currentCurrency.code.toLowerCase()">
                                                        <v-badge :color="!!s.register.badge?'warning':'secondary'" left
                                                                 :value="s.register.badge || s.register.discountPrice"
                                                                 :content="!!s.register.badge?s.register.badge:
                                                            `${Math.round((1-s.register.discountPrice/s.register.originalPrice)*100)}%`" >
                                                            {{ !!s.register.discountPrice? s.register.discountPrice: s.register.originalPrice | formatNumber}}
	                                                        <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                                        </v-badge>
                                                    </td>
                                                    <td class="price" :class="currentCurrency.code.toLowerCase()">
                                                        <v-badge :color="!!s.renew.badge?'warning':'secondary'" left
                                                                 :value="s.renew.badge || s.renew.discountPrice"
                                                                 :content="!!s.renew.badge?s.renew.badge:
                                                            `${Math.round((1-s.renew.discountPrice/s.renew.originalPrice)*100)}%`" >
                                                            {{ !!s.renew.discountPrice? s.renew.discountPrice: s.renew.originalPrice | formatNumber}}
	                                                        <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                                        </v-badge>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <v-card-actions class="d-flex justify-end pa-0 mt-4">
                                            <v-btn class="show-all-btn offer" color="warning" text
                                                   x-large width="180" elevation="0"
                                                   height="60px" tile link :to="{name: 'Pricing'}">
                                                مشاهده همه
                                                <v-icon right>mdi-arrow-left</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </div>
                                </v-card>
                            </v-tab-item>

                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>

        </v-container>
    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "HomePricingSection",
        data(){
            return{
                tab: null,
                cheapest: [],
                popular: [],
                special: []
            }
        },
        computed: mapGetters(["pricingTables", "currentCurrency"]),
        methods:mapActions(["fetchIndexPricingTables"]),
        created() {
			const currency = this.currentCurrency.code
            this.fetchIndexPricingTables(currency)
        }
    }
</script>

<style>
    section#pricing{
        position: relative;
        margin-top: 80px;
        margin-bottom: 150px;
        padding-top: 50px;
        z-index: 1;
    }

    .pricing-wrapper{
        position: relative;
    }

    .pricing-bg {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 1800px;
        background: url("~@/assets/images/svg/pricing-bg.svg") no-repeat top center;
	    background-size: 2120px;
	    top: -465px;
	    left: -115px;
	    right: 0;
    }

    section#pricing .pricing-table:not(.mobile) table thead td.price-header{
        text-align: center;
        background: var(--c_gray_4);
        color: var(--c_gray_5);
        font-size: 1rem;
    }

    section#pricing .pricing-table.mobile{
        text-align: center;
        color: var(--c_gray_5);
        font-size: 1rem;
    }

    section#pricing .pricing-table.mobile thead{
        background: var(--c_gray_1);
    }

    section#pricing .pricing-table:not(.mobile) table thead td:nth-child(2){
        border-bottom-right-radius: var(--border_radius);
    }

    section#pricing .pricing-table table thead tr td{
        padding: 15px 0;
    }

    section#pricing .pricing-table table thead tr td i.v-icon{
        font-size: 1.1rem;
        vertical-align: middle;
        color: var(--c_gray_5);
    }

    section#pricing .pricing-table table tbody tr:hover{
        background-color: initial !important;
    }

    section#pricing .pricing-table table tbody tr td{
        padding: 20px 0;
        font-size: 1rem;
        font-weight: 500;
        color: var(--c_gray_5);
        border-bottom: none !important;
    }
    section#pricing .pricing-table table tbody tr td:first-child{
        direction: ltr !important;
        text-align: right;
        padding-right: 40px;
        color: var(--c_secondary);
    }

    /*section#pricing .pricing-table table tbody tr td:last-child{*/
    /*    border-right: 1px dashed var(--c_gray_4);*/
    /*}*/


    section#pricing .pricing-table-title {
        text-align: center;
        color: var(--c_indigo);
        text-shadow: 0 0 0px var(--c_indigo);
        font-family: var(--font_text_1);
        font-weight: 700;
        font-size: 0.9rem;
    }

    section#pricing .pricing-table-special-title {
        text-align: center;
        color: var(--c_warning);
        text-shadow: 0 0 0px var(--c_warning);
        font-family: var(--font_text_1);
        font-weight: 700 !important;
        font-size: 0.9rem;
    }

    section#pricing #suggestions .pricing-table table tbody tr td:last-child{
        border-right: none !important;
    }

    section#pricing .pricing-table-wrapper#suggestions .pricing-table table tbody tr td:last-child{
        border-right: none;
    }

    section#pricing .pricing-table table tbody tr td.price{
        text-align: center;
    }

    section#pricing .pricing-table table tbody tr td.price.irt:after{
        content: "";
        display: inline-block;
        background: url("~@/assets/images/svg/TOMAN.svg") no-repeat;
        background-size: contain;
        width: 17px;
        height: 17px;
        vertical-align: sub;
        margin-right: 5px;
    }

    section#pricing .table-update-time{
        display: block;
        flex: 1 0 auto;
        text-align: center;
        font-family: var(--font_text_1);
        font-size: .8rem;
        font-weight: 300;
        color: var(--c_gray_2);
    }

    section#pricing .table-update-time i.v-icon{
        color: var(--c_gray_2);
    }

    section#pricing .show-all-btn{
        border-top-right-radius: var(--border_radius);
    }

    section#pricing .show-all-btn.normal{
        background-color: #e8faf9 !important;
        border-color: #e8faf9 !important;
    }

    section#pricing .show-all-btn.offer{
        background-color: #feecd4 !important;
        border-color: #feecd4 !important;
    }

    @media (max-width: 600px) { /*XS*/

    }
    @media (min-width: 600px) and (max-width: 960px){ /*SM*/

    }
    @media (min-width: 960px) and (max-width: 1264px){ /*MD*/

    }
    @media (min-width: 1264px) and (max-width: 1904px){ /*LG*/
        /*section#pricing.container{*/
        /*    max-width: 1280px !important;*/
        /*}*/
    }
    @media (min-width: 1904px){ /*XL*/
        /*section#pricing.container{*/
        /*    max-width: 1480px !important;*/
        /*}*/
    }
</style>
