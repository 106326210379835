import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import fa from 'vuetify/es5/locale/fa'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: true,
  lang: {
    locales: { fa },
    current: 'fa'
  },
  icons: {
    iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: '#4e1778',
        secondary: '#08c9c3',
      }
    }
  }
})
