<template>
    <div>
        <!--<v-fade-transition>-->
        <!--    <v-btn fab fixed bottom right plain class="support-chat-btn pa-0"-->
        <!--           v-show="fab" v-scroll="onScroll" id="imber-top-parent" :ripple="false"-->
        <!--           elevation="0" color="transparent" width="60" height="60">-->
        <!--        <v-tooltip activator="#imber-top-parent" left><span>پشتیبانی آنلاین</span></v-tooltip>-->
        <!--    </v-btn>-->

        <!--</v-fade-transition>-->
        <GoToTopBtn />
        <v-snackbar v-model="message.active" transition="slide-x-reverse-transition"
                    style="padding-right: 100px; padding-bottom: 8px"
                    bottom left rounded="pill" :multi-line="false">
            <v-icon class="ml-1" :color="message.color">mdi-{{message.icon}}</v-icon>
            {{ message.text }}
            <template v-slot:action="{ attrs }">
	            <v-btn color="light" icon v-bind="attrs" @click="message.active = false">
		            <v-icon size="20">mdi-close</v-icon>
	            </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GoToTopBtn from "@/components/GoToTopBtn.vue";

export default {
    name: "SupportChatBtn",
    components: {GoToTopBtn},
    data(){
        return{
            fab: false,
            message: {
                active: false,
                code: "",
                color: "",
                icon: "",
                text: ""
            },
        }
    },
    computed: mapGetters(["notification"]),
    methods:{
        onScroll (e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset ||   e.target.scrollTop || 0
            // this.fab = top > 400
            this.fab = true

        },
    },
    mounted(){
        setTimeout(() => {
            this.fab = true
            this.message = this.notification
        },3000)

        // !(function () {
        //     window.IMBER_LANG = "fa";
        //     let a = window, d = document;
        //     function im() {
        //         window.IMBER_ID = "u3v749n9ikykgp5hz";
        //         window.IMBER_TOKEN = localStorage.getItem("imber_token");
        //         let x = document;
        //         let s = x.createElement("script");
        //         s.src = "https://widget.imber.live/imber?id=" +window.IMBER_ID +"&token=" +(window.IMBER_AUTH ? "null&auth=" + JSON.stringify(window.IMBER_AUTH): window.IMBER_TOKEN);
        //         s.async = true;
        //         x.getElementsByTagName("head")[0].appendChild(s);
        //         // x.getElementsByTagName("body")[0].appendChild(i);
        //         window.$imber = {};
        //     }
        //     "complete" === d.readyState? im(): a.attachEvent? a.attachEvent("onload", im) : a.addEventListener("load", im, !1);
        // })();

    }
}
</script>

<style scoped>
.support-chat-btn{
    z-index: 99999;
}
.support-chat-btn:focus{
    background: transparent !important;
}
</style>
