<template>
    <v-fade-transition>
        <v-btn large fab fixed bottom right dark class="go-to-top"
               v-show="fab" v-scroll="onScroll" @click="toTop" height="60" width="60"
               elevation="5" color="white" id="goToTop">
            <v-icon x-large color="secondary">mdi-chevron-up</v-icon>
            <v-tooltip activator="#goToTop" left><span>رفتن به بالای صفحه</span></v-tooltip>
        </v-btn>
    </v-fade-transition>
</template>

<script>
    export default {
        name: "GoToTopBtn",
        data(){
            return{
                fab: false
            }
        },
        methods:{
            onScroll (e) {
                if (typeof window === 'undefined') return
                const top = window.pageYOffset ||   e.target.scrollTop || 0
                this.fab = top > 400
            },
            toTop () {
                this.$vuetify.goTo(0)
            }
        }
    }
</script>

<style scoped>
.go-to-top{
    //margin-bottom: 80px;
    z-index: 99;
}
</style>
