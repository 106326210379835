<template>
    <div>
        <SearchHeaderSection v-if="fullyLoaded"
                             @searchInit="doSearchDomain"
                             @filterAvailable="filterAvailableToggle"
                             @filterPrice="filterPriceHandler"/>
        <v-expand-transition mode="out-in">
            <SearchResultsSection v-if="showResults" :domain="domain"
                                  :searchResults="advancedSearchResults"
                                  :filterAvailable="filterAvailability"
                                  :filterPrice="priceRange"
                                  :searchInitiated="goToFirstPage"
                                  @searchUpdate="doSearchDomainUpdate"
                                  @searchRetry="doSearchDomainRetry"/>
        </v-expand-transition>
        <v-expand-transition mode="out-in">
            <SearchFeaturesSection v-if="!showResults" />
        </v-expand-transition>
        <SearchNoticesSection />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import SearchHeaderSection from "@/views/index/Domain/Search/SearchHeaderSection";
    import SearchResultsSection from "@/views/index/Domain/Search/SearchResultsSection";
    import SearchFeaturesSection from "@/views/index/Domain/Search/SearchFeaturesSection";
    import SearchNoticesSection from "@/views/index/Domain/Search/SearchNoticesSection";

    export default {
        title:"جستجوی دامنه",
        name: "Search",
        components:{
            SearchHeaderSection,
            SearchResultsSection,
            SearchFeaturesSection,
            SearchNoticesSection
        },
        data(){
            return{
                fullyLoaded: false,
                searchInitiated: false,
                goToFirstPage: false,
                showResults: false,
                filterAvailability: false,
                priceRange:[],
                domain: ""
            }
        },
        methods:{
            ...mapActions([
                "advancedSearch", "advancedSearchUpdate", "advancedSearchRetry", "fixAppBar",
                "authChecker","configAppearance", "getExtensionPrices", "setLoadingScreen"
            ]),
            doSearchDomain: async function (searchData) {
                this.domain = searchData.name
                this.searchInitiated = true
                this.showResults = true
                await this.advancedSearch(searchData)
                this.searchInitiated = false
                this.goToFirstPage = !this.goToFirstPage
            },
            doSearchDomainUpdate: async function (searchData) {
                if (!this.searchInitiated) {
                    await this.advancedSearchUpdate(searchData)
                }
            },
            doSearchDomainRetry: async function (searchData) {
                if (!this.searchInitiated) {
                    await this.advancedSearchRetry(searchData)
                }
            },
            filterAvailableToggle: function (state) {
                this.filterAvailability = state
            },
            filterPriceHandler: function (range) {
                this.priceRange = range
            },
        },
        computed: mapGetters(["advancedSearchResults", "currentCurrency"]),
        async mounted() {
			const currency = this.currentCurrency.code
            await Promise.all([this.authChecker(currency), this.getExtensionPrices(currency)]).then(() => {
                this.fullyLoaded = true
                this.configAppearance('white')
                this.fixAppBar()
                window.onscroll = () => {
                    this.fixAppBar()
                };
                setTimeout(() => {
                    this.setLoadingScreen(false)
                },1000)
            })
        },
    }
</script>

<style scoped>

</style>
