<template>
    <v-row class="footer-footer">
        <v-col cols="12" lg="4" class="tel">
            <a href="tel:+989999901104"><v-icon color="white" left>mdi-phone-outline</v-icon> تلفن: 09999901104</a>
        </v-col>
        <v-col cols="12" lg="4" class="mail">
            <a href="mailto:info@vsabt.com"><v-icon color="white" left>mdi-at</v-icon> ایمیل: info [at] vsabt.com</a>
        </v-col>
        <v-col cols="12" lg="4" class="copyright">
            <v-icon color="white" left>mdi-copyright</v-icon>2024 VSABT. All rights reserved
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "FooterCopyrightWidget"
    }
</script>

<style scoped>
    .copyright,
    .mail,
    .tel{
        font-family: var(--font_text_1);
        font-weight: 300;
        align-self: center;
        color: white;
        padding-right: 25px;
        mix-blend-mode: overlay;
    }

    .mail a,
    .tel a{
        text-decoration: none;
        color: white;
    }

    .copyright i,
    .mail i,
    .tel i{
        font-size: 1.4rem;
        vertical-align: sub;
        margin-left: 5px;
    }

    .footer-footer{
        height: 80px;
        margin-bottom: 20px;
    }
</style>
