<template>
    <v-footer tag="footer" dark>
        <section class="semi-section">
            <div class="section-bg-3">
                <div class="particle-4"></div>
                <div class="particle-5"></div>
                <div class="particle-6"></div>
            </div>
        </section>
        <FooterCounterWidget :counter-data="footerStats"/>
        <v-container tag="section" id="footer-widgets">
            <v-row class="row">
                <v-col cols="12" lg="4">
                    <FooterAboutWidget />
                </v-col>
                <v-col cols="12" lg="8" class="col-lg-8">
                    <div class="row footer-widgets-wrapper">
                        <v-col cols="12" lg="4" v-for="fw in footerWidgets" :key="fw.id">
                            <FooterLinkListWidget v-if="fw.type === 'links'" :widgetTitle="fw.widgetTitle" :links="fw.content" />
                        </v-col>
                    </div>
                    <FooterCopyrightWidget />
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import FooterCounterWidget from "@/views/index/Footer/FooterCounterWidget";
    import FooterAboutWidget from "@/views/index/Footer/FooterAboutWidget";
    import FooterLinkListWidget from "@/views/index/Footer/FooterLinkListWidget";
    import FooterCopyrightWidget from "@/views/index/Footer/FooterCopyrightWidget";

    export default {
        name: "MainFooterWrapper",
        components: {
            FooterCounterWidget,
            FooterAboutWidget,
            FooterLinkListWidget,
            FooterCopyrightWidget
        },
        computed: mapGetters(["footerStats", "footerWidgets"]),
    }
</script>

<style scoped>
    footer{
        display: block;
        position: relative;
        background: url("~@/assets/images/svg/13.svg") no-repeat center center, linear-gradient(30deg, var(--c_secondary), var(--c_indigo),var(--c_purple), var(--c_primary)) !important;
        background-size: contain;
        padding: 50px 0;
        color: white;
        background-blend-mode: overlay;
        overflow: hidden;
        margin-top: 100px;
    }



    footer > section#footer-widgets{
        display: block;
        position: relative;
    }



    footer > section#footer-widgets .footer-widgets-wrapper:after{
        content: "";
        display: block;
        position: relative;
        width: 100%;
        border-bottom: 1px solid rgba(255,255,255, 0.4);
        mix-blend-mode: overlay;
        margin-top: 35px;
    }

    footer > section.semi-section > .section-bg-1:before{
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 200vh;
        background: url("~@/assets/images/svg/71.svg") no-repeat;
        background-size: 100% auto;
        top: -250px;
        left: 0;
        right: 0;
    }

    footer > section.semi-section > .section-bg-3 .particle-4 {
        content: "";
        position: absolute;
        display: block;
        width: 45vw;
        height: 100vh;
        background: url("~@/assets/images/svg/6.svg") no-repeat;
        background-size: contain;
        mix-blend-mode: overlay;
        top: 220px;
        right: -260px;
        transform: rotate(46deg);
        opacity: .3;
    }

    footer > section.semi-section > .section-bg-3 .particle-5 {
        content: "";
        position: absolute;
        display: block;
        width: 40vw;
        height: 100vh;
        background: url("~@/assets/images/svg/6.svg") no-repeat;
        background-size: contain;
        mix-blend-mode: overlay;
        top: -520px;
        right: 35%;
        transform: rotate(45deg);
        opacity: .3;
    }

    footer > section.semi-section > .section-bg-3 .particle-6 {
        content: "";
        position: absolute;
        display: block;
        width: 40vw;
        height: 100vh;
        background: url("~@/assets/images/svg/6.svg") no-repeat;
        background-size: contain;
        mix-blend-mode: overlay;
        top: 180px;
        left: -370px;
        transform: rotate(70deg );
        opacity: .4;
    }
</style>
