<template>
    <v-scroll-y-transition>
        <v-snackbar v-model="specialBottomNotice.show" :timeout="specialBottomNotice.duration" :color="specialBottomNotice.color" height="70" rounded="lg"
                    class="msg-container d-none d-lg-flex" multi-line app bottom elevation="5">
            <div>
                {{ specialBottomNotice.content }}
                <v-btn v-for="btn in specialBottomNotice.buttons" :key="btn.id" @click.prevent="noticeBtnActionChecker($event, btn.action)"
                       rounded outlined text depressed link class="mr-4" :color="btn.color" :to="btn.target">
                    {{ btn.text }}
                </v-btn>
            </div>
            <div v-show="specialBottomNotice.image!==''"><img class="msg-image" :src="specialBottomNotice.image" alt=""></div>
            <template v-slot:action="{ attrs }">
                <v-btn color="grey" text v-bind="attrs" @click="closeSpecialBottomNotice" rounded icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-scroll-y-transition>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "SpecialBottomNotice",
        computed: mapGetters(["specialBottomNotice"]),
        data(){
            return{
            }
        },
        methods:{
            ...mapActions([
                "toggleSpecialBottomNotice",
                "closeSpecialBottomNotice"
            ]),
            noticeBtnActionChecker: function (e, action) {
                if (action === 'close'){
                    this.closeSpecialBottomNotice()
                }
                else{
                    return true
                }
            }
        },
    }
</script>

<style>
    .special-bottom-notice .v-snack__wrapper{
        min-width: 74% !important;
    }
    .msg-image{
        width: 110px;
        display: inline-block;
        position: absolute;
        bottom: 10px;
        left: 50px;
    }
</style>
