<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-alert text border="left" color="secondary" elevation="0"
                         rounded="xl" class="overflow-hidden" icon="mdi-progress-alert" >
                    <v-row>
                        <v-col cols="12">
                            <h4 class="notice_title">لطفاً قبل از سفارش دامنه به موارد زیر توجه کنید</h4>
                            <v-list dense>
                                <v-list-item v-for="notice in notices" :key="notices.indexOf(notice)">
                                    <v-list-item-icon class="ml-4"><v-icon class="ma-0" color="secondary">mdi-chevron-left</v-icon></v-list-item-icon>
                                    <v-list-item-content v-html="notice"></v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-divider class="ma-4 secondary" style="opacity: 0.2"></v-divider>
                    <v-row>
                        <v-col cols="12" lg="2" md="12" class="d-flex align-center justify-center">
                            صفحات پیشنهادی:
                        </v-col>
                        <v-col cols="12" lg="2" md="4" sm="6">
                            <v-btn outlined large block rounded color="secondary" :to="{name: 'Terms'}"><v-icon left>mdi-scale-balance</v-icon>قوانین وی ثبت</v-btn>
                        </v-col>
                        <v-col cols="12" lg="2" md="4" sm="6">
                            <v-btn outlined large block rounded color="secondary" :to="{name: 'Pricing'}"><v-icon left>mdi-currency-usd</v-icon> تعرفه ها</v-btn>
                        </v-col>
                        <v-col cols="12" lg="2" md="4" sm="6">
                            <v-btn outlined large block rounded color="secondary" :to="{name: 'AllDomains'}"><v-icon left>mdi-autorenew</v-icon>تمدید دامنه</v-btn>
                        </v-col>
                        <v-col cols="12" lg="2" md="6" sm="6">
                            <v-btn outlined large block rounded color="secondary" :to="{name: 'Transfer'}"><v-icon left>mdi-transfer</v-icon>انتقال دامنه</v-btn>
                        </v-col>
                        <v-col cols="12" lg="2" md="6" sm="12">
                            <v-btn outlined large block rounded color="secondary" :to="{name: 'Whois'}"><v-icon left>mdi-database-search-outline</v-icon>ابزار Whois</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "SearchNoticesSection",
        data(){
            return{
                notices:[
                    "بعضی پسوندها دارای شرایط خاص هستند یا برای عموم قابل ثبت نیستند. برای دیدن شرایط در بخش تعرفه های ثبت دامنه روی پسوند مورد نظر کلیک کنید",
                    "ما مسئولیتی در قبال فیلتر بودن احتمالی دامنه ها نمیپذیریم و نقشی در فیلتر کردن یا رفع فیلتر نداریم",
                    "ما مسئولیتی در قبال ثبت نشدن دامنه های رند و دامنه هایی که در لحظه ممکن است چندین نفر درخواست ثبت آن را بدهند نداریم.",
                    "بعضی دامنه ها با اینکه در هویز آزاد هستند ممکن است قابل ثبت نباشند. (رجیسترار اجازه ثبت با تعرفه ی عادی را نمیدهد یا درکل مجاز به ثبت نیستند)",
                    "لطفا حتی‌الامکان برای کمتر شدن احتمال تحریم، از اطلاعات و مشخصات ایرانی برای ثبت دامنه بین‌المللی استفاده نکنید. (مشاهده یا تغییر اطلاعات هویز)",
                    "اگر به هر دلیل دامنه ثبت نشود و قصد برگشت موجودی به حساب بانکی را داشته باشید، واریز به حساب آخر ماه و فقط به حساب خودتان انجام میشود.",
                    "اطلاعات هویز برای بعضی پسوندهای بین المللی مخفی است و امکان نمایش آن وجود ندارد.",
                ]
            }
        }
    }
</script>

<style scoped>
    .notice_title{
        color: var(--c_secondary);
    }
</style>
