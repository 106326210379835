<template>
    <v-container fluid class="fill-height pa-0">
        <div class="login-return-btn">
            <v-btn large text top right rounded color="grey" link @click="goBackFn">
                <v-icon>mdi-arrow-right</v-icon>
                بازگشت
            </v-btn>
        </div>
        <v-row class="fill-height ma-0">
            <v-col cols="12" lg="6" class="login-form-wrapper">
                <v-snackbar v-model="message.active" top absolute rounded="pill" :multi-line="false">
                    <v-icon class="ml-1" :color="message.color">mdi-{{message.icon}}</v-icon>
                    {{ message.text }}
                    <template v-slot:action="{ attrs }">
	                    <v-btn color="light" icon v-bind="attrs" @click="message.active = false">
		                    <v-icon size="20">mdi-close</v-icon>
	                    </v-btn>
                    </template>
                </v-snackbar>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-col cols="10" lg="6" md="7" id="auth-wrapper">
                        <v-scroll-x-reverse-transition hide-on-leave mode="out-in">
                            <div v-if="authStage==='init'" class="init-form">
                                <div class="login-title">ورود یا ثبت نام</div>
                                <div class="login-subtitle">برای ورود یا ثبت نام شماره تماس یا ایمیل خود را وارد کنید
                                </div>
                                <v-form class="v-text-field--full-width custom_input"
                                        ref="initForm" id="initForm" lazy-validation @submit.prevent="sendEp">
                                    <v-text-field v-model="credentials.username" label="ایمیل یا شماره تلفن همراه شما"
                                                  id="username"
                                                  hint="برای مثال yourname@gmail.com یا 09123456789" height="60"
                                                  ref="ep"
                                                  color="secondary" background-color="grey lighten-5" rounded
                                                  :disabled="isLoadingInProgress.auth"
                                                  outlined :rules="[rules.requiredEp]"
                                                  @blur="$refs.initForm.resetValidation()">
                                        <template v-slot:append>
                                            <v-btn class="submit-btn" large rounded text height="60" name="username"
                                                   :loading="isLoadingInProgress.auth" :disabled="isLoadingInProgress.auth"
                                                   color="secondary"
                                                   @click.prevent="sendEp">ادامه
                                                <v-icon right>mdi-arrow-left</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-form>
                            </div>
                        </v-scroll-x-reverse-transition>
                        <v-scroll-x-reverse-transition hide-on-leave mode="out-in">
                            <div v-if="authStage==='login'" class="login-form">
                                <div class="login-title">ورود به حساب کاربری</div>
                                <div class="login-subtitle">
                                    <span>{{ credentials.type==='phone'? ' شماره همراه ': 'ایمیل' }}</span> شما
                                    <span class="shown-auth-cred">
                                        {{ credentials.type==='phone'? credentials.phone: credentials.email }}
                                    </span>
                                    <v-chip class="mr-2" color="grey lighten-4" small pill @click="setStage('init')">
                                        <v-icon left small color="grey">mdi-pencil</v-icon>
                                        تصحیح
                                    </v-chip>
                                    <div class="mt-4">گذرواژه خود را وارد کنید</div>
                                </div>
                                <v-form class="v-text-field--full-width custom_input"
                                        ref="loginForm" id="loginForm" lazy-validation @submit.prevent="do_login">
                                    <v-text-field v-model="credentials.password" label="گذرواژه" type="password"
                                                  height="60" color="secondary" background-color="grey lighten-5"
                                                  rounded outlined :disabled="isLoadingInProgress.auth"
                                                  :rules="[rules.requiredPassword]"
                                                  @blur="$refs.loginForm.resetValidation()">
                                        <template v-slot:append>
                                            <v-btn class="submit-btn" large rounded text height="60"
                                                   :loading="isLoadingInProgress.auth" :disabled="isLoadingInProgress.auth"
                                                   color="secondary"
                                                   @click.prevent="do_login">ورود
                                                <v-icon right>mdi-arrow-left</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-form>
                            </div>
                        </v-scroll-x-reverse-transition>
                        <v-scroll-x-reverse-transition hide-on-leave mode="out-in">
                            <div v-if="authStage==='verify'" class="verify-form">
                                <div class="login-title">اعتبار سنجی</div>
                                <div class="login-subtitle">
                                    <span>{{ credentials.type==='phone'? ' شماره همراه ': 'ایمیل' }}</span> شما
                                    <span class="shown-auth-cred">
                                        {{ credentials.type==='phone'? credentials.phone: credentials.email }}
                                    </span>
                                    <v-chip class="mr-2" color="grey lighten-4" small pill @click="setStage('init')">
                                        <v-icon left small color="grey">mdi-pencil</v-icon>
                                        تصحیح
                                    </v-chip>
                                    <div class="mt-4">کد 6 رقمی ارسال شده به این
                                        <span>{{ credentials.type==='phone'? ' شماره همراه ': 'ایمیل' }}</span>
                                        را وارد کنید
                                    </div>
                                </div>
                                <v-form class="v-text-field--full-width custom_input"
                                        ref="verifyForm" id="verifyForm" lazy-validation
                                        @submit.prevent="do_verification">
                                    <v-text-field v-model="credentials.verificationCode" label="کد تأیید 6 رقمی"
                                                  type="digits"
                                                  height="60" color="secondary" background-color="grey lighten-5"
                                                  maxlength="6" rounded outlined :disabled="isLoadingInProgress.auth"
                                                  :rules="[rules.requiredVerificationCode]"
                                                  @blur="$refs.verifyForm.resetValidation()">
                                        <template v-slot:append>
                                            <v-btn class="submit-btn" large rounded text height="60"
                                                   :loading="isLoadingInProgress.auth" :disabled="isLoadingInProgress.auth"
                                                   color="secondary"
                                                   @click.prevent="do_verification">بررسی کد
                                                <v-icon right>mdi-arrow-left</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-form>
                            </div>
                        </v-scroll-x-reverse-transition>
                        <v-scroll-x-reverse-transition hide-on-leave mode="out-in">
                            <div v-if="authStage==='register'" class="register-form">
                                <div class="login-title">ایجاد حساب کاربری</div>
                                <div class="login-subtitle">
                                    جهت ثبت نام موارد زیر را تکمیل کنید
                                </div>
                                <v-form class="v-text-field--full-width"
                                        ref="registerForm1" id="registerForm1" lazy-validation
                                        @submit.prevent="do_register">
                                    <v-row>
                                        <v-col cols="5">
                                            <v-text-field v-model="credentials.firstName" label="نام"
                                                          class="regular-input"
                                                          height="60" color="secondary"
                                                          background-color="grey lighten-5" rounded outlined
                                                          :disabled="isLoadingInProgress.auth"
                                                          :rules="[rules.requiredFirstName]">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-text-field v-model="credentials.lastName" label="نام خانوادگی"
                                                          height="60" color="secondary"
                                                          background-color="grey lighten-5" rounded outlined
                                                          :disabled="isLoadingInProgress.auth"
                                                          :rules="[rules.requiredLastName]">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete v-model="credentials.country" :items="countries" label="کشور"
                                                    height="60" color="secondary" background-color="grey lighten-5"
                                                    rounded outlined item-text="name" item-value="id"
                                                    item-color="secondary" append-icon="mdi-chevron-down"
                                                    :disabled="isLoadingInProgress.auth" :rules="[rules.requiredCountry]">
                                        <template v-slot:selection="{ item, index }">
                                            <v-avatar left size="30" class="ml-2">
                                                <v-img :src="item.flag_file"></v-img>
                                            </v-avatar>
                                            {{ item.name }}
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-avatar left size="30" class="ml-2">
                                                <v-img :src="item.flag_file"></v-img>
                                            </v-avatar>
                                            {{ item.name }}
                                        </template>
                                    </v-autocomplete>
                                </v-form>
                                <v-form class="v-text-field--full-width custom_input"
                                        ref="registerForm2" id="registerForm2" lazy-validation
                                        @submit.prevent="do_register">
                                    <v-text-field v-model="credentials.password" label="گذرواژه" type="password"
                                                  name="password"
                                                  height="60" color="secondary" background-color="grey lighten-5"
                                                  rounded outlined
                                                  :disabled="isLoadingInProgress.auth" :rules="[rules.requiredPassword]">
                                        <template v-slot:append>
                                            <v-btn class="submit-btn" large rounded text height="60"
                                                   :loading="isLoadingInProgress.auth" :disabled="isLoadingInProgress.auth"
                                                   color="secondary"
                                                   @click.prevent="do_register">ثبت نام
                                                <v-icon right>mdi-arrow-left</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-form>
                            </div>
                        </v-scroll-x-reverse-transition>
                        <v-scroll-x-reverse-transition hide-on-leave mode="out-in">
                            <div v-if="authStage==='reset'" class="forget-form">
                                <div class="login-title">بازیابی گذرواژه</div>
                                <div class="login-subtitle">
                                    <div class="mt-4">گذرواژه جدید خود را وارد کنید</div>
                                </div>
                                <v-form class="v-text-field--full-width custom_input"
                                        ref="forgetForm" id="forgetForm" lazy-validation @submit.prevent="do_resetPass">
                                    <v-text-field v-model="credentials.newPassword" label="گذرواژه جدید" type="password"
                                                  height="60" color="secondary" background-color="grey lighten-5"
                                                  rounded outlined :disabled="isLoadingInProgress.auth"
                                                  :rules="[rules.requiredPassword]"
                                                  @blur="$refs.forgetForm.resetValidation()">
                                        <template v-slot:append>
                                            <v-btn class="submit-btn" large rounded text height="60"
                                                   :loading="isLoadingInProgress.auth" :disabled="isLoadingInProgress.auth"
                                                   color="secondary"
                                                   @click.prevent="do_resetPass">ثبت
                                                <v-icon right>mdi-arrow-left</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-form>
                            </div>
                        </v-scroll-x-reverse-transition>

                        <v-row align="center" class="login-divider-text mx-10 mt-8 mb-9">
                            <v-divider></v-divider>
                            <span class="mx-3">یا</span>
                            <v-divider></v-divider>
                        </v-row>
                        <v-row class=" ma-0">
                            <v-col cols="6">
                                <v-btn rounded outlined text block color="grey" height="60" :disabled="loadingGoogle || !googleLoginEnabled"
                                       :loading="loadingGoogle" @click.prevent="googleLogin" link>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                         aria-hidden="true" class="ml-3"><title>Google</title>
                                        <g fill="none" fill-rule="evenodd">
                                            <path :fill="!googleLoginEnabled?'#a0a0a0':'#4285F4'"
                                                  d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"></path>
                                            <path :fill="!googleLoginEnabled?'#868686':'#34A853'"
                                                  d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"></path>
                                            <path :fill="!googleLoginEnabled?'#c5c5c5':'#FBBC05'"
                                                  d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"></path>
                                            <path :fill="!googleLoginEnabled?'#868686':'#EA4335'"
                                                  d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"></path>
                                        </g>
                                    </svg>
                                    ورود با گوگل
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn v-if="authStage==='init' || authStage==='login'" rounded outlined
                                       text block color="grey" height="60" link @click="do_checkForget(authStage)">
                                    <v-icon left color="secondary">mdi-lock-question</v-icon>
                                    فراموشی گذرواژه
                                </v-btn>
                                <v-btn v-if="authStage==='register' || authStage==='reset'" rounded outlined text block
                                       color="grey" height="60"
                                       :disabled="registerCancelDisabled" link @click="discardOperation">
                                    <v-icon left color="secondary">mdi-backup-restore</v-icon>
                                    لغو عملیات
                                </v-btn>
                                <v-btn v-if="authStage==='verify'" :disabled="!resendEnabled || resendLoading"
                                       :loading="resendLoading"
                                       rounded outlined text block color="grey" height="60" link @click="do_resend">
                                    <v-icon left color="secondary">mdi-email-sync-outline</v-icon>
                                    ارسال مجدد
                                    <v-chip small v-show="!resendEnabled" :disabled="!resendEnabled" pill
                                            color="grey lighten-3" class="mr-2">
                                        {{ resendTimerCounter }}
                                    </v-chip>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <div class="copy-right">
                    <div class="grey--text text--lighten-1">&copy; 2024 VSABT. All rights reserved.</div>
                </div>
            </v-col>
            <v-col cols="12" lg="6" class="login-slider-wrapper d-none d-lg-block">
                <v-row class="fill-height" justify="center" align="center" no-gutters>
                    <v-col cols="7">
                        <v-card rounded="xl" elevation="5" height="640px" class="login-slider-inner">
                            <v-row>
                                <v-col cols="12" class="d-flex flex-row justify-center">
                                    <div class="login-logo2"></div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="d-flex flex-row justify-center">
                                    <v-card-title class="login-slider-title">به وی ثبت خوش آمدید</v-card-title>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-carousel :continuous="slider.continuous" :cycle="slider.cycle"
                                                :show-arrows="false" :interval="slider.interval"
                                                hide-delimiter-background delimiter-icon="mdi-star-four-points"
                                                height="430">
                                        <v-carousel-item v-for="slide in slider.slides" :key="slide.id">
                                            <v-sheet color="transparent" tile>
                                                <v-row class="fill-height" align="center" justify="center">
                                                    <div class="slide-image"
                                                         :style="{backgroundImage:`url('${slide.image}')`}"></div>
                                                    <div class="slide-text">{{ slide.text }}</div>
                                                </v-row>
                                            </v-sheet>
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import SupportChatBtn from "@/components/SupportChatBtn";
    import verificationMixin from "@/mixins/verificationMixin";

    export default {
        title:'ناحیه کاربری',
        name: "Login",
        components: {
            SupportChatBtn
        },
        data() {
            return {
                prevRoute: null,
                stage: 'init',
                loading: false,
                resendTimer: null,
                resendTimerTimeout: null,
                resendTimerCounter: 90,
                resendEnabled: false,
                resendLoading: false,
                googleLoginEnabled: false,
                loadingGoogle: false,
                registerCancelDisabled: false,
                verificationType: 'signup',
                rules: {
                    requiredEp: value => !!value || 'ایمیل یا شماره موبایل وارد نشده است',
                    requiredVerificationCode: value => !!value || 'کد تأیید وارد نشده است',
                    requiredFirstName: value => !!value || 'نام وارد نشده است',
                    requiredLastName: value => !!value || 'نام خانوادگی وارد نشده است',
                    requiredCountry: value => !!value || 'کشور وارد نشده است',
                    requiredPassword: value => !!value || 'گذرواژه وارد نشده است',
                },
                slider: {
                    cycle: true,
                    interval: 8000,
                    continuous: true,
                    slides: [
                        {
                            id: 1,
                            image: require('@/assets/images/svg/illustrations/930.svg'),
                            text: 'آیا میدونستید تو وی ثبت میتونین فلان کارو بکنین در حالیکه هیچ جای دنیا چنین امکانی براتون وجود نداره؟'
                        },
                        {
                            id: 2,
                            image: require('@/assets/images/svg/illustrations/945.svg'),
                            text: 'آیا میدونستید تو وی ثبت میتونین فلان کارو بکنین در حالیکه هیچ جای دنیا چنین امکانی براتون وجود نداره؟'
                        },
                        {
                            id: 3,
                            image: require('@/assets/images/svg/illustrations/942.svg'),
                            text: 'آیا میدونستید تو وی ثبت میتونین فلان کارو بکنین در حالیکه هیچ جای دنیا چنین امکانی براتون وجود نداره؟'
                        }
                    ]
                },
                message: {
                    active: false,
                    code: "",
                    color: "",
                    icon: "",
                    text: ""
                },
                credentials: null
            }
        },
        mixins:[verificationMixin],
        methods: {
            ...mapActions([
                "authChecker", "resendCode",
                "setStage", "InitializeAuth",
                "verification", "register",
                "login", "initializeResetPass",
                "resetPassword", "setLoadingScreen",
                "getCountryList", "clearCredentials"
            ]),
            clearForms: function (){
                let loginFormData = {
                    password:'',
                    newPassword:'',
                    firstName:'',
                    lastName: '',
                    country: '',
                    verificationCode: ''
                }
                Object.assign(this.credentials, loginFormData)
            },
            sendEp: async function () {
                this.clearForms()
                const data = {
                    data: this.credentials.username
                }
                if (this.$refs.initForm.validate()) {
                    const result = await this.InitializeAuth(data)
                    if (result) {
                        this.verificationType = 'signup'
                        this.resendTimerTimeout = setTimeout(() => {
                            this.resendEnabled = true
                            clearInterval(this.resendTimer)
                        }, (this.resendTimerCounter + 1) * 1000)
                        this.resendTimer = setInterval(() => {
                            this.resendTimerCounter -= 1
                        }, 1000)
                    }
                }
            },
            do_verification: async function () {
                let data = {}
                if (this.verificationType === 'signup') {
                    data = {
                        uniqueCode: this.credentials.uniqueCode,
                        verificationCode: this.credentials.verificationCode,
                        type: this.verificationType
                    }
                } else if (this.verificationType === 'forget') {
                    data = {
                        data: this.credentials.username,
                        verificationCode: this.credentials.verificationCode,
                        type: this.verificationType
                    }
                }

                if (this.$refs.verifyForm.validate()) {
                    this.verification(data)
                }
            },
            do_register: async function () {
                this.registerCancelDisabled = true
                const data = {
                    uniqueCode: this.credentials.uniqueCode,
                    firstName: this.credentials.firstName,
                    lastName: this.credentials.lastName,
                    country: this.credentials.country,
                    password: this.credentials.password,
                }

                const query = this.getUrlParams(this)
                let ref = null
                if(Object.keys(query).length !== 0){
                    if (query.hasOwnProperty('ref')){
                        ref = query.ref
                    }
                }

                if (this.$refs.registerForm1.validate() && this.$refs.registerForm2.validate()) {
                    await this.register({credentials: data, ref: ref})
                }
            },
            do_resend: async function () {
                this.resendLoading = true

                let data = {}
                if (this.verificationType === 'signup') {
                    data = {
                        uniqueCode: this.credentials.uniqueCode,
                        type: this.verificationType
                    }
                } else if (this.verificationType === 'forget') {
                    data = {
                        data: this.credentials.username,
                        type: this.verificationType
                    }
                }

                await this.resendCode(data)

                setTimeout(() => {
                    this.resendTimerCounter = 90
                    this.resendTimerTimeout = setTimeout(() => {
                        this.resendEnabled = true
                        clearInterval(this.resendTimer)
                    }, (this.resendTimerCounter + 1) * 1000)
                    this.resendTimer = setInterval(() => {
                        this.resendTimerCounter -= 1
                    }, 1000)
                    this.resendLoading = false
                    this.resendEnabled = false
                }, 500)
            },
            do_login: async function () {
                const query = this.getUrlParams(this)
                let ref = null
                if(Object.keys(query).length !== 0){
                    if (query.hasOwnProperty('ref')){
                        ref = query.ref
                    }
                }
                const data = {
                    data: this.credentials.username,
                    password: this.credentials.password,
                }
                if (this.$refs.loginForm.validate()) {
                    await this.login({credentials: data, ref: ref})
                }
            },
            do_checkForget: async function (stage) {
                this.clearForms()
                const data = {
                    data: this.credentials.username
                }
                if (stage === 'init'){
                    if (this.$refs.initForm.validate()) {
                        const result = await this.initializeResetPass(data)
                        if (result) {
                            this.verificationType = 'forget'
                            this.resendTimerTimeout = setTimeout(() => {
                                this.resendEnabled = true
                                clearInterval(this.resendTimer)
                            }, (this.resendTimerCounter + 1) * 1000)
                            this.resendTimer = setInterval(() => {
                                this.resendTimerCounter -= 1
                            }, 1000)
                        }
                    }
                } else if (stage === 'login'){
                    const result = await this.initializeResetPass(data)
                    if (result) {
                        this.verificationType = 'forget'
                        this.resendTimerTimeout = setTimeout(() => {
                            this.resendEnabled = true
                            clearInterval(this.resendTimer)
                        }, (this.resendTimerCounter + 1) * 1000)
                        this.resendTimer = setInterval(() => {
                            this.resendTimerCounter -= 1
                        }, 1000)
                    }
                }
            },
            do_resetPass: async function () {
                const data = {
                    data: this.credentials.username,
                    verificationCode:  this.credentials.verificationCode,
                    password: this.credentials.newPassword,
                }
                const query = this.getUrlParams(this)
                let ref = null
                if(Object.keys(query).length !== 0){
                    if (query.hasOwnProperty('ref')){
                        ref = query.ref
                    }
                }
                if (this.$refs.forgetForm.validate()) {
                    await this.resetPassword({credentials: data, ref: ref})
                }
            },
            googleLogin: async function () {
                this.loadingGoogle = true
                const googleUser = await this.$gAuth.signIn()
                // console.log(googleUser)
                this.loadingGoogle = true
            },
            discardOperation: function() {
                this.clearCredentials()
                this.credentials = this.initCredentials
                this.setStage('init')
            },
            goBackFn: function () {
                const query = this.getUrlParams(this)
                if(Object.keys(query).length === 0){
                    this.$router.go(-1)
                }
                else{
                    if (query.hasOwnProperty('action') && (query.action === 'logout' || query.action === 'auth')){
                        this.$router.push({name: 'Home'})
                    }
                }

            }
        },
        computed: mapGetters(["initCredentials", "notification", "isLoadingInProgress",
	                          "authStage", "loginStatus", "countries", "currentCurrency"]),
        async beforeRouteEnter(to, from, next){
            next(vm => {
                vm.prevRoute = from
            })
        },
        async created() {
            this.credentials = this.initCredentials
            this.message = this.notification
            this.loading = this.isLoadingInProgress
        },
        async beforeMount(){

        },
        async mounted() {
			const currency = this.currentCurrency.code
            await this.authChecker(currency)
            await this.getCountryList()

            if (this.loginStatus) {
                await this.$router.push({name: "Dashboard"})
            }
            this.setStage('init')
            await setTimeout(async () => {
                await this.setLoadingScreen(false)
            }, 1000)
        },

    }
</script>

<style>
    .login-form-wrapper {
        display: block;
        position: relative;
        background: url("~@/assets/images/svg/loginbg1.svg") no-repeat center center;
        background-size: cover;
        padding: 0 !important;
    }

    .login-slider-wrapper {
        display: block;
        position: relative;
        background: url("~@/assets/images/svg/loginbg2.svg") no-repeat center center;
        background-size: cover;
        padding: 0 !important;
    }

    #auth-wrapper {
        display: block;
        position: relative;
    }

    #auth-wrapper .login-title {
        font-size: 1.8rem;
        font-weight: bold;
        color: var(--c_secondary);
    }

    #auth-wrapper .login-title:after {
        content: "";
        display: block;
        width: 150px;
        height: 10px;
        background: url("~@/assets/images/svg/hzsep.svg") no-repeat center right;
        margin: 20px 0;
    }

    #auth-wrapper .login-subtitle {
        color: var(--c_gray_5);
        margin-bottom: 80px;
    }

    /*#login-wrapper .login-form{}*/

    #auth-wrapper .shown-auth-cred {
        font-weight: bold;
        color: var(--c_secondary);
    }

    #auth-wrapper .login-divider-text {
        color: #e0e0e0;
    }

    .login-return-btn {
        position: absolute;
        top: 70px;
        right: 30px;
        z-index: 100;
    }

    .copy-right {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-items: center;
        direction: ltr;
        width: 100%;
        text-align: center;
        bottom: 50px;
    }

    .copy-right div {
        flex: 1 0 auto;
    }

    .login-slider-wrapper .login-slider-inner {
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(30px);
        padding: 30px;
    }

    .login-slider-wrapper .login-logo2 {
        display: block;
        width: 150px;
        height: 55px;
        background: url("~@/assets/images/svg/logoc2.svg") no-repeat;
        background-size: contain;
    }

    .login-slider-inner .login-slider-title {
        font-size: 1.1rem;
        font-weight: normal;
        color: var(--c_indigo);
        mix-blend-mode: luminosity;
    }

    .login-slider-inner .slide-image {
        display: block;
        position: relative;
        width: 100%;
        height: 300px;
        background-size: 57%;
        background-position: center;
        margin: 0 auto 10px;
    }

    .login-slider-inner .slide-text {
        display: block;
        position: relative;
        font-size: 0.9rem;
        color: #747474;
        mix-blend-mode: luminosity;
        width: 100%;
        text-align: center;
        padding: 0 20px;
    }

    .submit-btn{
        font-size: 1rem !important;
        font-weight: 700 !important;
        letter-spacing: normal !important;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    @media (max-width: 600px) {
        /*.c */
    }

    @media (min-width: 600px) and (max-width: 960px) {

    }

    @media (min-width: 960px) and (max-width: 1264px) {

    }

    @media (min-width: 1264px) and (max-width: 1904px) {

    }

    @media (min-width: 1904px) {

    }
</style>
