const state = {
    loadingCompleted: false
};

const getters = {
    panelPageLoaded: (state) => (state.loadingCompleted)
};

const actions = {
    setPanelAuthenticationResult: ({ commit }, status) => {
        commit("_setPanelAuthenticationResult", status)
    }
};

const mutations = {
    _setPanelAuthenticationResult: (state, status) => (state.loadingCompleted = status)
};

export default {
    state,
    getters,
    actions,
    mutations
};
