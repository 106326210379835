<template>
    <!--TODO: SET ROUTE LINKS WITH NAME PROPERTY-->
    <div class="footer-widget">
        <div class="footer-widget-title">{{ widgetTitle }}</div>
        <v-list color="transparent" dark rounded dense>
            <v-list-item v-for="link in links" :key="link.id" link :to="link.target">
                <v-list-item-title v-text="link.title"></v-list-item-title>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "FooterLinkListWidget",
        props: {
            widgetTitle: {
                type:String,
                default: 'پیوند ها'
            },
            links: {
                type:Array,
                default: () => []
            },
        },
    }
</script>

<style scoped>
    .footer-widget{
        display: block;
        position: relative;
        padding-top: 15px;
        padding-right: 20px;
    }

    .footer-widget .footer-widget-title:before{
        content: "";
        display: block;
        position: relative;
        border-top: 7px solid white;
        mix-blend-mode: overlay;
        width: 25%;
        margin-bottom: 20px;
    }

    .footer-widget .footer-widget-title{
        display: block;
        position: relative;
        font-family: var(--font_text_1);
        font-weight: 800;
        font-size: 1.4rem;
        margin-bottom: 30px;
        mix-blend-mode: overlay;
    }

    .footer-widget ul.footer-link-list{
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .footer-widget ul.footer-link-list li{
        display: block;
        position: relative;
    }

    .footer-widget ul.footer-link-list li a{
        display: block;
        position: relative;
        text-decoration: none;
        font-family: var(--font_text_1);
        font-weight: normal;
        font-size: 1.1rem;
        color: rgba(255,255,255,0.6);
        line-height: 3rem;
        transition: var(--transition);
    }

    .footer-widget ul.footer-link-list li a:hover{
        color: rgba(255,255,255,1);
    }
</style>
