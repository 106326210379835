<template>
    <div class="dev-wrapper">
        <div class="dev-bg"></div>

        <v-container tag="section" id="developers">
            <v-row>
                <v-col cols="12" lg="8">
                    <div class="dev-illustration"></div>
                </v-col>
                <v-col cols="12" lg="4">
                    <h1 class="section-title"><v-badge :value="true" color="error" content="به زودی" >برای توسعه دهنده ها</v-badge></h1>

                    <p class="section-description">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                        و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
                        کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                        آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت ایجاد کرد.
                    </p>
                    <v-btn block x-large rounded height="60" class="section-btn"
                           elevation="0" dark>
                        <v-icon class="section-btn-icon" left size="30">mdi-code-braces</v-icon>
                        اطلاعات بیشتر
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "HomeDevelopersSection"
    }
</script>

<style>
    section#developers{
        position: relative;
        margin-top: 200px;
        z-index: 10;
    }

    .dev-wrapper{
        position: relative;
    }

    .dev-bg {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 1800px;
        background: url("~@/assets/images/svg/dev-bg.svg") no-repeat top center;
        background-size: 1910px;
	    top: -510px;
	    right: -115px;
    }

    section#developers h1.section-title{
        display: block;
        text-align: center;
        font-weight: 800;
        font-size: 2rem;
        color: var(--c_indigo);
        text-shadow: 0 0 10px rgba(55,79,144,0.2);
        mix-blend-mode: luminosity;
    }

    section#developers h1.section-title:after{
        content: "";
        display: block;
        width: 150px;
        height: 10px;
        background: url("~@/assets/images/svg/hzsep.svg")no-repeat center right;
        margin: 30px auto 30px;
    }

    section#developers p.section-description{
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 300;
        color: var(--c_gray_5);
        padding: 0 20px;
        line-height: 2rem;
        z-index: 10;
        margin-bottom: 35px;
    }

    section#developers .section-btn-icon{
        mix-blend-mode: overlay;
        opacity: 0.5;
        margin-left: 20px;
    }

    section#developers .section-btn{
        background: var(--c_gradient_3);
        margin-bottom: 30px;
        transition: all .5s;
    }

    section#developers .section-btn:hover{
        box-shadow: var(--shadow_3) !important;
    }
    section#developers .dev-illustration{
        display: block;
        position: relative;
        width: 100%;
        height: 600px;
    }

    section#developers .dev-illustration:after{
        content: "";
        display: block;
        position: absolute;
        background: url('~@/assets/images/svg/illustrations/4.svg') no-repeat center right;
        background-size: 100% 100%;
        width: 100%;
        height: 650px;
        top: -20px;
        right: -70px;
    }

    @media (max-width: 600px) {
        section#developers{
            margin-top: 80px;
        }
        section#developers .dev-illustration{
            height: 300px;
        }
        section#developers .dev-illustration:after{
            height: 300px;
            top: 0;
            right: -10px;
        }
    }
    @media (min-width: 600px) and (max-width: 960px){
        section#developers{
            margin-top: 120px;
        }
        section#developers .dev-illustration{
            height: 470px;
        }
        section#developers .dev-illustration:after{
            height: 470px;
            top: 0;
            right: -20px;
        }
    }
    @media (min-width: 960px) and (max-width: 1264px){
        section#developers .dev-illustration:after{
            right: -40px;
        }
    }
    @media (min-width: 1264px) and (max-width: 1904px){

    }
    @media (min-width: 1904px){

    }
</style>
