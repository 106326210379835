<template>
    <div class="footer-about-widget">
        <div class="footer-logo"></div>
        <div class="footer-about">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته شک نکنید
        </div>
        <div class="footer-social mx-0">
            <a href="https://wa.me/message/ZDH3SLOUE6WVG1" id="whatsappLink" target="_blank">
                <v-btn icon x-large color="primary">
                    <v-icon color="primary">mdi-whatsapp</v-icon>
                </v-btn>
                <v-tooltip color="primary" activator="#whatsappLink" top><span>واتس اپ</span></v-tooltip>
            </a>
            <a href="https://t.me/vsabt" id="telegramLink" target="_blank">
                <v-btn icon x-large color="primary">
                    <v-icon color="primary">mdi-send</v-icon>
                </v-btn>
                <v-tooltip color="primary" activator="#telegramLink" top><span>تلگرام</span></v-tooltip>
            </a>
            <a href="https://www.instagram.com/vsabt_com/" target="_blank" id="instagramLink">
                <v-btn icon x-large color="primary">
                    <v-icon color="primary">mdi-instagram</v-icon>
                </v-btn>
                <v-tooltip color="primary" activator="#instagramLink" top><span>اینستاگرام</span></v-tooltip>
            </a>
            <a href="https://github.com/VSABT" id="githubLink" target="_blank">
                <v-btn icon x-large color="primary">
                    <v-icon color="primary">mdi-github</v-icon>
                </v-btn>
                <v-tooltip color="primary" activator="#githubLink" top><span>گیت هاب</span></v-tooltip>
            </a>
            <a href="https://www.linkedin.com/company/vsabt/" target="_blank" id="linkedinLink">
                <v-btn icon x-large color="primary">
                    <v-icon color="primary">mdi-linkedin</v-icon>
                </v-btn>
                <v-tooltip color="primary" activator="#linkedinLink" top><span>لینکدین</span></v-tooltip>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterAboutWidget"
    }
</script>

<style scoped>
    .footer-about-widget{
        display: block;
        position: relative;
        padding-top: 30px;
    }

    .footer-about-widget:before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--border_radius);
        background: rgba(255,255,255,0.1);
        mix-blend-mode: overlay;

    }

    .footer-about-widget .footer-logo{
        display: block;
        position: relative;
        width: 50%;
        height: 60px;
        background: url("~@/assets/images/svg/logow2.svg") no-repeat center center;
        background-size: contain;
        margin: 10px auto 30px;
        mix-blend-mode: overlay;
    }

    .footer-about-widget .footer-about{
        display: block;
        position: relative;
        font-family: var(--font_text_1);
        font-weight: 300;
        font-size: 1rem;
        line-height: 2rem;
        text-align: center;
        mix-blend-mode: overlay;
        padding: 0 30px;
        margin-bottom: 60px;
    }

    .footer-about-widget .footer-social{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px 50px;

    }

    .footer-about-widget .footer-social:before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-bottom-right-radius: var(--border_radius);
        border-bottom-left-radius: var(--border_radius);
        background: rgba(255,255,255,0.5);
        mix-blend-mode: overlay;
    }

    .footer-about-widget .footer-social a{
        text-decoration: none;
        font-size: 2rem;
        color: var(--c_purple);
        z-index: 10;
        transition: var(--transition);
    }

    .footer-about-widget .footer-social a:hover{
        color: var(--c_indigo);
    }
</style>
