<template>
    <div class="intro-wrapper">
        <div class="intro-bg" :style="{height: `${windowHeight+320}px`}"></div>
        <v-container tag="section" class="intro" id="header" :style="{height: `${windowHeight+400}px`}">
            <v-row class="d-flex" :style="{height: `${windowHeight-100}px`}">
                <v-col cols="12" lg="7" xl="6" class="intro-inner">
                    <div v-if="randomSelection" class="intro-title-wrapper text-center text-lg-right">
                        <div class="intro-title">نام دلخـواهت<br/>رو انتخاب کن</div>
                        <div class="subtitle">بقیه رو بــه وی ثـبت بسپــار !</div>
                    </div>
                    <div v-else class="intro-title-wrapper text-center text-lg-right">
                        <div class="intro-title">شروع همه چیـز از<br/>یک انتخاب درست</div>
                        <div class="subtitle">با وی ثبت بهترین دامنه را پیدا کنید !</div>
                    </div>
                    <v-row>
                        <v-col cols="12">
                            <v-card rounded="xl" elevation="5" class="intro-search-area overflow-hidden" :loading="searchResultLoading" ref="introSearchArea">
                                <template slot="progress">
                                    <v-progress-linear  color="secondary" absolute height="4" indeterminate reversed></v-progress-linear>
                                </template>
                                <v-form class="px-5 pt-5" id="searchForm" ref="search" @submit.prevent="searchDomain" lazy-validation >
                                    <v-text-field label="دامنه مورد نظر شما" rounded outlined height="60" ref="searchField" :disabled="searchResultLoading"
                                                  @keyup="clearSearch" :rules="[rules.required]" @blur="$refs.search.resetValidation()"
                                                  color="secondary" background-color="grey lighten-4" v-model="domain"
                                                  hint="دامنه خود را به صورت example یا example.com وارد کنید">
                                        <template  v-slot:append>
                                            <v-btn class="intro-search-btn px-lg-10" :loading="searchResultLoading" @click="searchDomain"
                                                   color="secondary" text rounded height="60">
                                                <v-icon left>mdi-magnify</v-icon>
                                                جستجو
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-form>

                                <div class="search-results"  v-show="showSearchResults || searchResultLoading">
                                    <v-fade-transition mode="out-in" hide-on-leave>
                                        <v-simple-table v-show="showSearchResults && searchResults.length !== 0" class="mt-4">
                                            <template>
                                                <tbody>
                                                <tr v-for="res in searchResults" :key="res.id" :disabled="!res.isAvailable">
                                                    <td style="max-width:10px">
                                                        <v-checkbox v-model="selectedDomains" :value="res"
                                                                    :disabled="!res.isAvailable"
                                                                    color="secondary" class="mt-0" hide-details></v-checkbox>
                                                    </td>
                                                    <td>
                                                        {{res.name}}.<span style="color: var(--c_secondary)">{{res.extension}}</span>
                                                    </td>
                                                    <td>
                                                        <v-chip :color="res.isAvailable? 'success': 'error'" small>
                                                            {{res.isAvailable? 'قابل ثبت': 'ثبت شده'}}
                                                        </v-chip>
                                                    </td>
                                                    <td :class="`price ${!res.isAvailable?'disabled':''} ${currentCurrency.code.toLowerCase()}`">
                                                        <v-badge v-if="res.isAvailable" :color="res.register.badge?'warning':'secondary'" inline left
                                                                 :value="res.register.badge || res.register.discountPrice"
                                                                 :content="!!res.register.badge?res.register.badge:
                                                            `${Math.round((1-res.register.discountPrice/res.register.originalPrice)*100)}%`" >
                                                            {{ !!res.register.discountPrice? res.register.discountPrice: res.register.originalPrice}}
	                                                        <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                                        </v-badge>
                                                        <v-btn v-else color="secondary" text rounded small link>
                                                            <v-icon small left>mdi-information-outline</v-icon>
                                                            اطلاعات بیشتر
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-fade-transition>
                                    <v-fade-transition>
                                        <v-overlay v-if="searchResultLoading" absolute color="#ffffff">
                                            <v-progress-circular indeterminate size="64" color="secondary"></v-progress-circular>
                                        </v-overlay>
                                    </v-fade-transition>
                                </div>

                                <v-fade-transition mode="out-in" hide-on-leave>
                                    <v-card-actions class="search-result-actions pa-5" v-show="showSearchResults && !showAddToCartMsg">
                                        <v-row>
                                            <v-col cols="6" class="d-flex justify-start">
                                                <v-fade-transition mode="out-in">
                                                    <v-btn text rounded large color="secondary" v-show="selectedDomainCount" @click="addSelectedToCart" :loading="addToCartLoading">
                                                        <v-badge :value="selectedDomainCount" :content="selectedDomainCount" color="secondary" inline>
                                                            <v-icon small left>mdi-cart-plus</v-icon>
                                                            افزودن به سبد
                                                        </v-badge>
                                                    </v-btn>
                                                </v-fade-transition>
                                            </v-col>
                                            <v-col cols="6" class="d-flex justify-end">
                                                <v-btn text rounded large color="secondary"><v-icon small left>mdi-feature-search-outline</v-icon>جستجوی پیشرفته</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-fade-transition>

                                <v-fade-transition mode="out-in" hide-on-leave>
                                    <v-card-actions class="search-add-to-cart-actions pa-5" v-show="showAddToCartMsg">
                                        <v-row>
                                            <v-col cols="8" class="d-flex justify-start align-center white--text">
                                                <v-icon right color="white">mdi-check-bold</v-icon>
                                                {{ addToCartMsg }}
                                            </v-col>
                                            <v-col cols="4" class="d-flex justify-end">
                                                <v-btn text rounded large color="white" class="d-none d-sm-inline-block"
                                                       @click="closeAddToCartMsg">
                                                    <v-badge :content="addToCartMsgCountDown" inline color="grey darken-2">
                                                        <v-icon left>mdi-arrow-left</v-icon>ادامه
                                                    </v-badge>
                                                </v-btn>
                                                <v-btn text rounded large color="white"><v-icon left>mdi-cart-check</v-icon>اتمام خرید</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-fade-transition>

                                <v-fade-transition mode="out-in" hide-on-leave>
                                    <v-card-actions class="intro-search-area-bottom" v-show="!showSearchResults && !showAddToCartMsg">
                                        <v-row v-if="!!briefPrices.length" class="last-prices pa-5">
                                            <v-col cols="6" sm="3" v-for="bp in briefPrices" :key="bp.id" class="py-3">
                                                <div class="pricing-item" :class="currentCurrency.code.toLowerCase()">
	                                                <span>{{ bp.tld }}</span> از
	                                                <SingleCounterWidget :end="bp.price" />
	                                                <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else class="last-prices pa-5">
                                            <v-col cols="6" sm="3" v-for="i in 4" :key="i" class="py-3">
                                                <v-skeleton-loader type="text" class="mt-1"></v-skeleton-loader>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-fade-transition>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-col>
                <v-col cols="12" lg="5" xl="6">
                    <div class="intro-illustration d-none d-lg-block"></div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SingleCounterWidget from "@/components/SingleCounterWidget";

export default {
    name: "HomeIntroSection.vue",
    components: {
        SingleCounterWidget
    },
    props: {
        lastPrices:{
            type: Array,
            default: () => []
        }
    },
    data(){
        return{
            randomSelection: 0,
            windowHeight: null,
            domain: null,
            showSearchResults: false,
            searchResultLoading: false,
            selectedDomains: [],
            selectedDomainCount: 0,
            showAddToCartMsg: false,
            addToCartLoading: false,
            addToCartMsg: "",
            addToCartMsgCountDown: 6,
            addToCartMsgCountDownInterval: null,
            addToCartMsgCountDownTimeout: null,
            rules: {required: value => !!value || 'دامنه مورد نظر را وارد کنید'}
        }
    },
    computed: mapGetters(["searchResults", "briefPrices", "currentCurrency"]),
    methods:{
        ...mapActions(["addToCart", "searchName", "fetchIndexBriefPrices"]),
        calcHeight: function () {
            this.windowHeight = window.innerHeight
        },
        searchDomain: function () {
            // this.$vuetify.goTo(400)
            if(this.$refs.search.validate()) {
                //     this.$refs.searchField.blur()
                //     this.closeAddToCartMsg(true)
                this.searchResultLoading = true
                setTimeout(() => {
                    this.searchName(this.domain)
                    this.$router.push({name: 'Search', query:{name:this.domain}})
                    // this.selectedDomains = []
                    // this.searchResultLoading = false
                    // this.showSearchResults = true
                }, 500)
            }
        },
        updateAddToCartBtn: function () {
            this.selectedDomainCount = this.selectedDomains==null? 0 : this.selectedDomains.length
        },
        clearSearch: function () {
            if(this.domain === "" || this.domain == null){
                this.selectedDomains = []
                this.showSearchResults = false
                this.closeAddToCartMsg(true)
            }
        },
        addSelectedToCart: function () {
            this.addToCartLoading = true
            setTimeout(()=>{
                this.addToCart(this.selectedDomains)
                this.selectedDomains = []
                this.addToCartMsg = "دامنه ها به سبد خرید اضافه شد"
                this.showAddToCartMsg = true
                this.addToCartLoading = false
                this.addToCartMsgCountDownInterval = setInterval(() =>{
                    this.addToCartMsgCountDown -= 1
                }, 1000)
                this.addToCartMsgCountDownTimeout = setTimeout( () => {
                    this.showAddToCartMsg = false
                    clearInterval(this.addToCartMsgCountDownInterval)
                    this.addToCartMsgCountDown = 6
                }, 6000)
            },2000)
        },
        closeAddToCartMsg: function (AllowCountDown=false) {
            this.showAddToCartMsg = false
            this.addToCartMsgCountDown = 6
            if(!AllowCountDown){
                clearInterval(this.addToCartMsgCountDownInterval)
                clearTimeout(this.addToCartMsgCountDownTimeout)
            }

        }
    },
    created(){
        this.windowHeight = window.innerHeight
        window.onscroll = () => {
            this.calcHeight();
        };
		const currency = this.currentCurrency.code
        this.fetchIndexBriefPrices(currency)
    },
    mounted() {
        let choices = [0, 1]
        let index = Math.floor(Math.random() * choices.length);
        this.randomSelection =  choices[index];
	    const currency = this.currentCurrency.code
	    this.fetchIndexBriefPrices(currency)
    },
    watch: {
        selectedDomains: function (n, o) {
            this.updateAddToCartBtn()
        },
    }
}
</script>

<style>
.intro{
    padding-top: 100px;
}
.intro-wrapper{
    display: block;
    position: relative;
    height: 100%;
    overflow: hidden;
}
.intro-bg{
    position: absolute;
    width: 1920px;
    background-size: 100% 100%;
    background: url("~@/assets/images/svg/header-bg.svg") no-repeat;
    top: -50px;
    left: -380px;
    right: 0;
    margin: auto;
    z-index: 0;
}

.intro .intro-inner{
    margin-top: 120px;
}

.intro .intro-title-wrapper .intro-title{
    position: relative;
    font-weight: 800;
    font-size: 4.4rem;
    line-height: 7rem;
    color: var(--c_gray_2);
    mix-blend-mode: luminosity;
    text-shadow: var(--shadow_1);
}

.intro .intro-title-wrapper .subtitle{
    position: relative;
    font-weight: 500;
    font-size: 2.1rem;
    color: var(--c_secondary);
    margin-top: 20px;
    text-shadow: var(--shadow_2);
    height: 100%;
}

.intro .intro-search-area{
    display: block;
    position: relative;
    margin-top: 80px;
    z-index: 98;
}

.intro-search-btn{
    font-size: 1rem !important;
    font-weight: 700 !important;
    letter-spacing: normal !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    /*background: var(--c_gradient_2_rev);*/
}
#searchForm .v-input__slot{
    padding-left: 0 !important;
}

#searchForm .v-input__append-inner{
    margin-top: 0 !important;
}

.intro-search-area-bottom{
    background: var(--c_gray_1);
}
/*============================================================*/
.search-wrapper{
    display: block;
    position: relative;
    background: white;
    border-radius: calc( 3 * var(--border_radius));
    box-shadow: var(--shadow_1);
    padding: 0;
}

.search-wrapper .search-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pricing-item{
    font-family: var(--font_text_1);
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    color: var(--c_gray_2);
}

.last-prices [class*='col-']:not(:last-child){
    border-left: rgba(183, 183, 183, 0.33) solid 1px !important;
}

.last-prices .pricing-loader{
    content: "";
    display: inline-block;
    margin-right: 3px;
}

.last-prices .pricing-item.irt:after{
    content: "";
    display: inline-block;
    background: url('~@/assets/images/svg/TOMAN.svg') no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px;
    vertical-align: sub;
    margin-right: 3px;
}

.last-prices .pricing-item span:first-child{
    font-weight: 700;
}

.last-prices .pricing-item span:first-child:after{
    content: '.';
}

.last-prices .pricing-item span:not(:first-child):not(:last-child){
    color: var(--c_secondary);
    font-weight: 700;
}

.last-prices .pricing-item.irt span:last-child{
    color: var(--c_secondary);
    font-weight: 700;
}

section#header table thead tr td {
    color: var(--c_gray_5);
    text-shadow: 0 0 0 var(--c_gray_4);
    font-family: var(--font_text_1);
    font-weight: 700;
    font-size: 0.9rem;
    padding: 15px 0;
    text-align: center;
}

section#header table thead tr td:first-child {
    padding-right: 30px;
    text-align: right;
}

section#header .search-results table thead tr td i.v-icon{
    font-size: 1.1rem;
    vertical-align: middle;
    color: var(--c_gray_5);
}

section#header .search-results table tbody tr:hover{
    background: var(--c_gray_1) !important;
}

section#header .search-results table tbody tr td{
    padding: 20px 0;
    font-size: 1rem;
    font-weight: 700;
    color: var(--c_gray_5);
    text-align: center;
    border-bottom: 1px solid var(--c_gray_1) !important;
}

section#header .search-results table tbody tr td:first-child{
    padding-right: 30px;
    height: auto;
}

section#header .search-results table tbody tr:last-child td{
    border-bottom: none !important;
}

/*section#header #suggestions .search-results table tbody tr td:last-child{*/
/*    border-right: none !important;*/
/*}*/

/*section#header .search-results-wrapper#suggestions .search-results table tbody tr td:last-child{*/
/*    border-right: none;*/
/*}*/

section#header .search-results table tbody tr td.price{
    text-align: center;
}

section#header .search-results table tbody tr td.price.irt:not(.disabled):after{
    content: "";
    display: inline-block;
    background: url("~@/assets/images/svg/TOMAN.svg") no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px;
    vertical-align: sub;
    margin-right: 5px;
}

.search-result-actions{
    display: flex;
    flex-direction: row;
}

.search-add-to-cart-actions{
    background: var(--c_success);
}

.intro-illustration{
    display: block;
    margin-right: -160px;
}

.intro-illustration:before {
    content: "";
    display: block;
    position: absolute;
    width: 1000px;
    height: 1000px;
    background: url("~@/assets/images/svg/illustrations/3.svg") no-repeat center;
    background-size: contain;
    top: 110px;
    z-index: 0;
}

@media (max-width: 600px) {
    .intro-bg{
        right: auto;
	    left: -90px;
    }

    .intro .intro-title-wrapper .intro-title{
        font-weight: 800;
        font-size: 3rem;
        line-height: 5rem;
        color: var(--c_gray_2);
        mix-blend-mode: luminosity;
        text-shadow: var(--shadow_1);
    }

    .intro .intro-title-wrapper .subtitle{
        font-weight: 500;
        font-size: 1.5rem;
        color: var(--c_secondary);
        margin-top: 20px;
        text-shadow: var(--shadow_2);
    }

    .last-prices [class*='col-']:not(:last-child){
        border-left: none !important;
    }

    .last-prices [class*='col-']:nth-child(2n+1){
        border-left: rgba(183, 183, 183, 0.33) solid 1px !important;
    }
}
@media (min-width: 600px) and (max-width: 960px){
    .intro-bg{
        right: auto;
	    left: -70px;
    }
}
@media (min-width: 960px) and (max-width: 1264px){
    .intro-bg{
        right: auto;
	    left: -80px;
    }
}
@media (min-width: 1264px) and (max-width: 1904px){
    .intro-bg{
        right: auto;
    }
}
@media (min-width: 1904px){

}

</style>
