// import XLSX from 'xlsx'
import { read, utils } from "xlsx";

const state = {
    verificationStatus: false,
    fileProcessing: false,
    domains:[]
};

const getters = {
    verificationStatus: (state) => (state.verificationStatus),
    loadedDomains: (state) => (state.domains),
    isFileProcessing: (state) => (state.fileProcessing)
};

const actions = {
    loadDomainFileData: async ({ getters, commit, dispatch }, file) => {
        let parsedData = []
        let verification = null
        commit('_setProcessingStatus', true)
        try {
            let reader = new FileReader()
            reader.onload =  async function (e){
                const data = new Uint8Array(e.target.result)
                const workbook = read(data, {type: 'array'});
                const sheet = workbook.Sheets[workbook.SheetNames[0]]
                parsedData =  utils.sheet_to_json(sheet)
                verification = await dispatch('dataVerifier', parsedData)
                parsedData.forEach(_ => {
                    _.id = null
                    _.tld = null
                    _.sld = null
                    _.price = null
                    _.loading = true
                })
                if (verification){
                    commit('_updateDomainList', parsedData)
                    commit('_setVerificationStatus', 'success')
                } else{
                    commit('_setVerificationStatus', 'failed')
                }
            }
            reader.readAsArrayBuffer(file)

        } catch (e) {
            commit('_setVerificationStatus', 'error')
            commit('_setProcessingStatus', false)
        }
        setTimeout(()=>{
            commit('_setProcessingStatus', false)
        },2000)
    },
    dataVerifier: async ({ commit, dispatch }, data) => {
        let verified = true
        let validColNames = ['domain', 'transferCode']
        data.forEach(d => {
            let vfd = true
            let keys = Object.keys(d)
            keys.forEach(k => {
                if (!validColNames.includes(k)){
                    vfd = false
                }
            })
            if (vfd){
                if (!!!d.domain || d.domain.length === 0){
                    verified = false
                    return verified
                }
            } else {
                verified = false
                return verified
            }
        })
        return verified
    },

};

const mutations = {
    _updateDomainList: (state, domains) => (state.domains = domains),
    _setVerificationStatus: (state, status) => (state.verificationStatus = status),
    _setProcessingStatus: (state, status) => (state.fileProcessing = status)
};

export default {
    state,
    getters,
    actions,
    mutations
};
