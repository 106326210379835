const state = {
    params:{
        domain: '',
        status: '',
        transferable: false,
        host: '',
        parserType: '',
        last: 0,
        price: 0,
        privacy: false,
    },
    showRawText: false,
    resultData: {},
    rawTextDta: "",
    error: false,
    error_msg: '',
};

const getters = {
    whoisResultData: (state) => (state.resultData),
    whoisRawData: (state) => (state.rawTextDta),
    whoisParams: (state) => (state.params),
    showRowTextData: (state) => (state.showRawText),
    hasError: (state) => (state.error),
    errorMessage: (state) => (state.error_msg)
};

const actions = {
    searchWhois: async ({ commit, dispatch }, searchData) => {
        commit("_clearWhoisData")
        let url = `/domain/whois?domain=${searchData.domain}`
        if (searchData["refresh"] === true){
            url = `/domain/whois/refresh?domain=${searchData.domain}`
        }
        const result = await dispatch('apiCaller', {url: url, data: {}, reqType:'GET', callerID:'whois', authReq:true})
        if (result){
            if (result.code === 404){
                const _url = `/search/prices/list?base=${searchData.currency}&tlds[0]=.${searchData.ext}`
                const _result = await dispatch('apiCaller', {url: _url, data: {}, reqType:'GET', callerID:'whois', authReq:true})
                if (_result){
                    let price = false
                    if (Object.keys(_result.data).length !== 0){
                        price = !!_result.data["."+searchData.ext].register.discountPrice? _result.data["."+searchData.ext].register.discountPrice:_result.data["."+searchData.ext].register.originalPrice
                    }
                    const params = {
                        domain: searchData.domain,
                        status: "available",
                        price : price
                    }
                    commit("_setWhoisParams", params)
                }
                return true
            }
            else if (result.code === 200 || result.code === 201 ){

                const rawText = result.data.response.plain
                commit("_setWhoisRawText", rawText)

                const params = {
                    domain: result.data.domain,
                    status: result.data.status,
                    transferable: result.transferable,
                    host: result.data.host,
                    last: result.updatedAt,
                    parserType: result.data.response.main.parserType,
                    privacy: await dispatch("checkPrivacyProtection", rawText)
                }
                commit("_setWhoisParams", params)

                try {
                    let parsedData = await dispatch("whoisDataParser", {type: params.parserType, response: result.data.response})
                    commit("_setWhoisParsedData", parsedData)
                } catch (e) {
                    commit("_setRawDataVisibility", true)
                }

                if (searchData["refresh"] === true){
                    const message = {
                        code : result.code,
                        text: result.message,
                    }
                    commit('_notifySend', {message: message, type:'whois'})
                }

                return true
            }
            else {
                commit("_setWhoisResultError", result.message)
                commit("_setWhoisParams", {status: "unavailable"})

                return 'error'
            }
        }
        return false
    },
    whoisDataParser: ({ commit, dispatch }, data) => {
        const parserTypeList = ["common", "commonflat", "block", "indent"]
        const parserType = data.type.toLowerCase()
        if (parserTypeList.includes(parserType)){
            return dispatch(parserType, data.response)
        } else{
            commit("_setRawDataVisibility", true)
            return false
        }
    },
    common: ({}, rawResponse) => {
        const resp = rawResponse.extra
        const extraKeys = Object.keys(resp)

        let parsed = {
            domain:[
                {name: "Domain Name", value: rawResponse.main["domainName"] != false?rawResponse.main["domainName"]:"نامشخص"},
                {name: "Creation Date", value: rawResponse.main["creationDate"] != false?rawResponse.main["creationDate"]:"نامشخص"},
                {name: "Update Date", value: rawResponse.main["updatedDate"] != false?rawResponse.main["updatedDate"]:"نامشخص"},
                {name: "Expiration Date", value: rawResponse.main["expirationDate"] != false?rawResponse.main["expirationDate"]:"نامشخص"},
                {name: "DNSSEC", value: rawResponse.main["states"] != false?rawResponse.main["dnssec"]:"نامشخص"},
                {name: "States", value: rawResponse.main["states"] != false?rawResponse.main["states"]:"نامشخص"},
                {name: "Name Servers", value: rawResponse.main["nameServers"] != false?rawResponse.main["nameServers"]:"نامشخص"},
            ],
            registrant: [],
            registrar: [],
            administrator: [],
            tech: [],
        }
        const parsingScope = {
            administrator: ["admin", "authorized"],
            registrant:["registrant", "remarks", "holder"],
            registrar:["registrar", "nic"],
            tech:["tech"],
        }
        for (const [key, expressions] of Object.entries(parsingScope)) {
            extraKeys.forEach(entry => {
                const e = entry.toLowerCase()
                expressions.forEach(exp => {
                    if (e.includes(exp)){
                        parsed[key].push({name: entry, value: resp[entry]})
                    }
                })
            })
        }
        return parsed
    },
    commonflat: ({}, rawResponse) => {
        const resp = rawResponse.extra
        const extraKeys = Object.keys(resp)

        let parsed = {
            domain:[
                {name: "Domain Name", value: rawResponse.main["domainName"] != false?rawResponse.main["domainName"]:"نامشخص"},
                {name: "Creation Date", value: rawResponse.main["creationDate"] != false?rawResponse.main["creationDate"]:"نامشخص"},
                {name: "Update Date", value: rawResponse.main["updatedDate"] != false?rawResponse.main["updatedDate"]:"نامشخص"},
                {name: "Expiration Date", value: rawResponse.main["expirationDate"] != false?rawResponse.main["expirationDate"]:"نامشخص"},
                {name: "DNSSEC", value: rawResponse.main["states"] != false?rawResponse.main["dnssec"]:"نامشخص"},
                {name: "States", value: rawResponse.main["states"] != false?rawResponse.main["states"]:"نامشخص"},
                {name: "Name Servers", value: rawResponse.main["nameServers"] != false?rawResponse.main["nameServers"]:"نامشخص"},
            ],
            registrant: [],
            registrar: [],
            administrator: [],
            tech: [],
        }

        const parsingScope = {
            administrator: ["admin"],
            registrant:["registrant", "remarks", "holder"],
            registrar:["registrar", "nic", "authorized"],
            tech:["tech"],
        }
        for (const [key, expressions] of Object.entries(parsingScope)) {
            extraKeys.forEach(entry => {
                const e = entry.toLowerCase()
                expressions.forEach(exp => {
                    if (e.includes(exp)){
                        parsed[key].push({name: entry, value: resp[entry]})
                    }
                })
            })
        }
        return parsed
    },
    block: ({}, rawResponse) => {
        const resp = rawResponse.extra
        const extraKeys = Object.keys(resp)

        let parsed = {
            domain:[
                {name: "Domain Name", value: rawResponse.main["domainName"] != false?rawResponse.main["domainName"]:"نامشخص"},
                {name: "Creation Date", value: rawResponse.main["creationDate"] != false?rawResponse.main["creationDate"]:"نامشخص"},
                {name: "Update Date", value: rawResponse.main["updatedDate"] != false?rawResponse.main["updatedDate"]:"نامشخص"},
                {name: "Expiration Date", value: rawResponse.main["expirationDate"] != false?rawResponse.main["expirationDate"]:"نامشخص"},
                {name: "DNSSEC", value: rawResponse.main["states"] != false?rawResponse.main["dnssec"]:"نامشخص"},
                {name: "States", value: rawResponse.main["states"] != false?rawResponse.main["states"]:"نامشخص"},
                {name: "Name Servers", value: rawResponse.main["nameServers"] != false?rawResponse.main["nameServers"]:"نامشخص"},
            ],
            legal: [],
        }

        const parsingScope = {
            legal: ["admin","registrant", "holder","registrar", "authorized","tech", "remarks"],
        }
        for (const [key, expressions] of Object.entries(parsingScope)) {
            extraKeys.forEach(entry => {
                const e = entry.toLowerCase()
                expressions.forEach(exp => {
                    if (e.includes(exp)){
                        parsed[key].push({name: entry, value: resp[entry]})
                    }
                })
            })
        }
        return parsed
    },
    indent: ({}, rawResponse) => {
        const resp = rawResponse.extra
        const extraKeys = Object.keys(resp)

        let parsed = {
            domain:[
                {name: "Domain Name", value: rawResponse.main["domainName"] != false?rawResponse.main["domainName"]:"نامشخص"},
                {name: "Creation Date", value: rawResponse.main["creationDate"] != false?rawResponse.main["creationDate"]:"نامشخص"},
                {name: "Update Date", value: rawResponse.main["updatedDate"] != false?rawResponse.main["updatedDate"]:"نامشخص"},
                {name: "Expiration Date", value: rawResponse.main["expirationDate"] != false?rawResponse.main["expirationDate"]:"نامشخص"},
                {name: "DNSSEC", value: rawResponse.main["states"] != false?rawResponse.main["dnssec"]:"نامشخص"},
                {name: "States", value: rawResponse.main["states"] != false?rawResponse.main["states"]:"نامشخص"},
                {name: "Name Servers", value: rawResponse.main["nameServers"] != false?rawResponse.main["nameServers"]:"نامشخص"},
            ],
        }

        const parsingScope = {
            domain: ["admin","registrant", "holder","registrar", "authorized","tech", "remarks"],
        }
        for (const [key, expressions] of Object.entries(parsingScope)) {
            extraKeys.forEach(entry => {
                const e = entry.toLowerCase()
                expressions.forEach(exp => {
                    if (e.includes(exp)){
                        parsed[key].push({name: entry, value: resp[entry]})
                    }
                })
            })
        }
        return parsed
    },
    checkPrivacyProtection: ({}, rawText) => {
        const catchPhrase = ["guard", "privacy", "not disclose"]
        const text = rawText.toLowerCase()
        let isProtected = false
        catchPhrase.forEach((phrase) => {
            if (text.includes(phrase)){
                isProtected = true
            }
        })
        return isProtected
    },
    clearWhoisData: ({ commit }) => {
        commit("_clearWhoisData")
    }

};

const mutations = {
    _clearWhoisData: (state) => {
        state.params = {domain: '', status: '', host: '', parserType: '', last: 0, price: 0,  privacy: false, transferable: false}
        state.resultData = {}
        state.rawTextDta = ""
        state.showRawText = false
        state.error = false
        state.error_msg = ""
    },
    _setWhoisParams: (state, params) => {
        Object.assign(state.params, params)
    },
    _setWhoisRawText: (state, data) => {
        state.rawTextDta = data
    },
    _setRawDataVisibility: (state, status) => {
        state.showRawText = status
    },
    _setWhoisParsedData: (state, data) => {
        state.resultData = data
    },
    _setWhoisResultError: (state, msg) => {
        state.error = true
        state.params.status = "unavailable"
        state.error_msg = msg
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
