<template>
	<div class="locale-form-wrapper pa-6">
		<v-row>
			<v-col cols="4" class="d-flex align-center">
				<span class="mr-3">زبان</span>
			</v-col>
			<v-col cols="8">
				<v-select v-model="selectedLang" :items="langList" hide-details
				          height="40" color="secondary" background-color="grey lighten-5"
				          rounded outlined item-text="title" item-value="code"
				          item-color="secondary" append-icon="mdi-chevron-down"
				          :disabled="isLoadingInProgress.auth" auto-select-first
				          return-object @change="changeGlobalLocale()">
					<template v-slot:selection="{ item, index }">
						<v-avatar left size="30" class="ml-2">
							<v-img :src="item.flag"></v-img>
						</v-avatar>
						{{ item.title }}
					</template>
					<template v-slot:item="{ item }">
						<v-avatar left size="30" class="ml-2">
							<v-img :src="item.flag"></v-img>
						</v-avatar>
						{{ item.title }}
					</template>
				</v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4" class="d-flex align-center">
				<span class="mr-3">واحد پولی</span>
			</v-col>
			<v-col cols="8">
				<v-select v-model="selectedCurrency" :items="currencyList" hide-details
				          height="40" color="secondary" background-color="grey lighten-5"
				          rounded outlined item-text="title" item-value="code"
				          item-color="secondary" append-icon="mdi-chevron-down"
				          :disabled="isLoadingInProgress.auth" auto-select-first
				          return-object @change="changeGlobalLocale()">
					<template v-slot:selection="{ item, index }">
						<v-avatar left size="32" class="ml-2" color="grey lighten-3">
							<small>{{ item.code }}</small>
						</v-avatar>
						{{ item.title }}
					</template>
					<template v-slot:item="{ item }">
						<v-avatar left size="32" class="ml-2" color="grey lighten-5">
							<small>{{ item.code }}</small>
						</v-avatar>
						{{ item.title }}
					</template>
				</v-select>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "LocaleSettingsForm",
	data() {
		return{
			selectedLang: null,
			selectedCurrency: null,
		}
	},
	computed: mapGetters(["isLoadingInProgress", "langList", "currentLang", "currencyList", "currentCurrency"]),
	methods: {
		...mapActions(["setGlobalLocale"]),
		changeGlobalLocale() {
			this.setGlobalLocale({ lang: this.selectedLang.code, currency: this.selectedCurrency.code });
		}
	},
	mounted() {
		this.selectedLang = this.currentLang;
		this.selectedCurrency = this.currentCurrency;
	}
}
</script>

<style scoped>

</style>