import {mapActions, mapGetters} from "vuex";

export default {
    computed: mapGetters(["currentCurrency"]),
    methods: mapActions(["authChecker", "setLoadingScreen"]),
    async beforeMount(){
        const currency = this.currentCurrency.code
        await this.authChecker(currency)
    },
    mounted(){
        setTimeout(() => {
            this.setLoadingScreen(false)
        }, 1000)
    }
}
