import Vue from 'vue'
import VueRouter from 'vue-router'
import ComingSoon from '@/views/static/ComingSoon'
import Login from "@/views/auth/Login";
import Index from "@/views/index/Index";
import Home from "@/views/index/Home/Home";
import Search from "@/views/index/Domain/Search/Search";
import NotFound from "@/views/error/404"
import Forbidden from "@/views/error/403"
import ServerError from "@/views/error/500"
// import Domain from "@/views/index/Domain/Domain";
// import Pricing from "@/views/index/Domain/Pricing/Pricing";
// import Whois from "@/views/index/Domain/Whois/Whois";
// import Single from "@/views/index/Singles/Single";
// import About from "@/views/index/Singles/About";
// import Contact from "@/views/index/Singles/Contact";
// import Terms from "@/views/index/Singles/Terms";
// import Panel from "@/views/user/Panel";
// import Dashboard from "@/views/user/Dashboard/Dashboard";
// import DomainManagement from "@/views/user/DomainManagement/DomainManagement";
// import AllDomains from "@/views/user/DomainManagement/AllDomains";
// import Statistics from "@/views/user/Dashboard/DashboardContent";
// import ExpiringDomains from "@/views/user/DomainManagement/ExpiringDomains";
// import AccountSettings from "@/views/user/AccountSettings/AccountSettings";
// import EditProfile from "@/views/user/AccountSettings/EditProfile";
// import ChangePassword from "@/views/user/AccountSettings/ChangePassword";
// import SessionManagement from "@/views/user/AccountSettings/SessionManagement";
// import SupportPassword from "@/views/user/AccountSettings/SupportPassword";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Index,
        children: [
            {path: '', name: 'Home', component: Home},
            {
                path: '',
                component: () => import(/* webpackChunkName: "domain" */ '../views/index/Singles/Single'),
                children: [
                    {
                        path: 'about',
                        name: 'About',
                        component: () => import(/* webpackChunkName: "about" */ '../views/index/Singles/About')
                    },
                    {
                        path: 'contact',
                        name: 'Contact',
                        component: () => import(/* webpackChunkName: "contact" */ '../views/index/Singles/Contact')
                    },
                    {
                        path: 'terms-of-service',
                        name: 'Terms',
                        component: () => import(/* webpackChunkName: "terms" */ '../views/index/Singles/Terms')
                    },
                ]
            },
            {
                path: 'domain',
                component: () => import(/* webpackChunkName: "domain" */ '../views/index/Domain/Domain'),
                children: [
                    {path: '', redirect: 'pricing',},
                    {
                        path: 'pricing',
                        name: 'Pricing',
                        component: () => import(/* webpackChunkName: "pricing" */ '../views/index/Domain/Pricing/Pricing')
                    },
                    {
                        path: 'search',
                        name: 'Search',
                        component: () => import(/* webpackChunkName: "search" */ '../views/index/Domain/Search/Search')
                    },
                    {
                        path: 'transfer',
                        name: 'Transfer',
                        component: () => import(/* webpackChunkName: "Transfer" */ '../views/index/Domain/Transfer/Transfer')
                    },
                    {
                        path: 'whois',
                        name: 'Whois',
                        component: () => import(/* webpackChunkName: "whois" */ '../views/index/Domain/Whois/Whois')
                    },
                ]
            },
        ]
    },
    {
        path: '/panel',
        component: () => import(/* webpackChunkName: "panel" */ '../views/user/Panel'),
        children: [
            {path: '', redirect: 'dashboard'},
            {
                path: '',
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/Dashboard/Dashboard'),
                children: [
                    {
                        path: 'dashboard',
                        name: 'Dashboard',
                        component: () => import(/* webpackChunkName: "dashboard_content" */ '../views/user/Dashboard/DashboardContent')
                    },
                ]
            },
            {
                path: 'account-settings',
                component: () => import(/* webpackChunkName: "account_settings" */ '../views/user/AccountSettings/AccountSettings'),
                children: [
                    {path: '', name: 'AccountSettings', redirect: 'edit-profile'},
                    {
                        path: 'edit-profile',
                        name: 'EditProfile',
                        component: () => import(/* webpackChunkName: "edit_profile" */ '../views/user/AccountSettings/EditProfile')
                    },
                    {
                        path: 'change-password',
                        name: 'ChangePassword',
                        component: () => import(/* webpackChunkName: "change_password" */ '../views/user/AccountSettings/ChangePassword')
                    },
                    {
                        path: 'session-management',
                        name: 'SessionManagement',
                        component: () => import(/* webpackChunkName: "session_management" */ '../views/user/AccountSettings/SessionManagement')
                    },
                    {
                        path: 'support-password',
                        name: 'SupportPassword',
                        component: () => import(/* webpackChunkName: "support_password" */ '../views/user/AccountSettings/SupportPassword')
                    },
                ]
            },
            {
                path: 'domain-management',
                component: () => import(/* webpackChunkName: "domain_management" */ '../views/user/DomainManagement/DomainManagement'),
                children: [
                    {path: '', name: 'DomainManagement', redirect: 'all-domains'},
                    {
                        path: 'all-domains',
                        name: 'AllDomains',
                        component: () => import(/* webpackChunkName: "all_domains" */ '../views/user/DomainManagement/AllDomains')
                    },
                    {path: 'domain', redirect: 'all-domains'},
                    {
                        path: 'identities',
                        name: 'IdentityManagement',
                        component: () => import(/* webpackChunkName: "identity_management" */ '../views/user/DomainManagement/IdentityManagement')
                    },
                    {
                        path: 'nameservers',
                        name: 'NameServerManagement',
                        component: () => import(/* webpackChunkName: "ns_management" */ '../views/user/DomainManagement/NameServerManagement')
                    },
                ]
            },
            {
                path: 'domain-management/domain/:id',
                name: 'DomainInfo',
                props: true,
                component: () => import(/* webpackChunkName: "domain_info" */ '../views/user/DomainManagement/SingleDomainDetails')
            },
            {
                path: 'finance-management',
                component: () => import(/* webpackChunkName: "finance_management" */ '../views/user/FinanceManagement/FinanceManagement'),
                children: [
                    {path: '', name: 'FinanceManagement', redirect: 'transactions'},
                    {
                        path: 'transactions',
                        name: 'Transactions',
                        component: () => import(/* webpackChunkName: "transactions" */ '../views/user/FinanceManagement/Transactions')
                    },
                    {path: 'transaction', redirect: 'transactions'},

                ]
            },
            {
                path: 'finance-management/transaction/:tid',
                name: 'Transaction',
                props: true,
                component: () => import(/* webpackChunkName: "transaction_detail" */ '../views/user/FinanceManagement/SingleTransactionDetails')
            },
            {
                path: 'checkout',
                name: 'Checkout',
                component: () => import(/* webpackChunkName: "checkout" */ '../views/user/Checkout/Checkout'),
            },
            {
                path: 'support',
                component: () => import(/* webpackChunkName: "tickets" */ '../views/user/Support/Support'),
                children: [
                    {path: '', name: 'Support', redirect: 'tickets'},
                    {
                        path: 'tickets',
                        name: 'Tickets',
                        component: () => import(/* webpackChunkName: "tickets" */ '../views/user/Support/Tickets')
                    },
                    {path: 'ticket', redirect: '/support/ticket/new'},
                    {
                        path: 'announcements',
                        name: 'Announcements',
                        component: () => import(/* webpackChunkName: "announcements" */ '../views/user/Support/Announcements')
                    },
                    {path: 'announcement', redirect: 'announcements'},
                    {
                        path: 'faq',
                        name: 'FAQ',
                        component: () => import(/* webpackChunkName: "faq_list" */ '../views/user/Support/FAQsList')
                    },
                    {
                        path: 'contact-info',
                        name: 'ContactInfo',
                        component: () => import(/* webpackChunkName: "contact-info" */ '../views/user/Support/ContactInfo')
                    },
                ]
            },
            {
                path: 'support/ticket/new/:dept?',
                name: 'NewTicket',
                props: true,
                component: () => import(/* webpackChunkName: "new_ticket" */ '../views/user/Support/NewTicket')
            },
            {
                path: 'support/ticket/:id',
                name: 'TicketDetails',
                props: true,
                component: () => import(/* webpackChunkName: "ticket_details" */ '../views/user/Support/TicketDetails')
            },
            {
                path: 'support/announcement/:id',
                name: 'Announcement',
                props: true,
                component: () => import(/* webpackChunkName: "ticket" */ '../views/user/Support/AnnouncementDetails')
            },
            {
                path: '',
                component: () => import(/* webpackChunkName: "panel_premium" */ '../views/user/Premium/Premium'),
                children: [
                    {
                        path: 'premium',
                        name: 'Premium',
                        component: () => import(/* webpackChunkName: "premium" */ '../views/user/Premium/PremiumContent')
                    },
                ]
            },
            {
                path: '',
                component: () => import(/* webpackChunkName: "panel_help" */ '../views/user/Help/Help'),
                children: [
                    {
                        path: 'help',
                        name: 'Help',
                        component: () => import(/* webpackChunkName: "help" */ '../views/user/Help/HelpContent')
                    },
                ]
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/coming-soon',
        name: 'ComingSoon',
        component: ComingSoon
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        component: Forbidden
    },
    {
        path: '/error',
        name: 'Error',
        component: ServerError
    },
    {path: '/:pathMatch(.*)*', component: NotFound},
    // {
    //   path: '/about',
    //   name: 'About',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

// const routes = [
//   {
//     path: '/',
//     name: 'ComingSoon',
//     component: ComingSoon
//   },
//   { path: '/:pathMatch(.*)*', component: ComingSoon },
// ]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({y: 0}),
    routes
})

export default router
