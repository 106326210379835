<template>
    <div class="my-10 d-none d-lg-block">
        <v-container>
            <v-row>
                <v-col cols="3">
                    <v-card rounded="xl" elevation="0" class="search-feature-card">
                        <div class="search-feature-content">
                            <div class="image feature1"></div>
                            <div class="title">ارزان ترین</div>
                            <div class="description">
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
                                است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.
                            </div>
                        </div>
                        <v-card-actions class="action">
                            <v-btn rounded text large block color="primary">اطلاعات بیشتر</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="3">
                    <v-card rounded="xl" elevation="0" class="search-feature-card">
                        <div class="search-feature-content">
                            <div class="image feature2"></div>
                            <div class="title">سریعترین</div>
                            <div class="description">
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
                                است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.
                            </div>
                        </div>
                        <v-card-actions class="action">
                            <v-btn rounded text large block color="primary">اطلاعات بیشتر</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="3">
                    <v-card rounded="xl" elevation="0" class="search-feature-card">
                        <div class="search-feature-content">
                            <div class="image feature3"></div>
                            <div class="title">امن ترین</div>
                            <div class="description">
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
                                است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.
                            </div>
                        </div>
                        <v-card-actions class="action">
                            <v-btn rounded text large block color="primary">اطلاعات بیشتر</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="3">
                    <v-card rounded="xl" elevation="0" class="search-feature-card">
                        <div class="search-feature-content">
                            <div class="image feature4"></div>
                            <div class="title">هوشمند ترین</div>
                            <div class="description">
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
                                است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.
                            </div>
                        </div>
                        <v-card-actions>
                            <v-btn rounded text large block color="primary">اطلاعات بیشتر</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "SearchFeaturesSection"
    }
</script>

<style scoped>
    .search-feature-card{
        margin-top: 70px;
        box-shadow: rgba(0,0,0,.10) 0px 0px 30px 0px !important;
        transition: var(--transition);
    }
    .search-feature-card:hover{
        box-shadow: rgba(0,0,0,.10) 0px 0px 10px 0px !important;
    }
    .search-feature-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 30px;
    }
    .search-feature-content .image{
        position: relative;
        display: block;
        background-size: contain;
        height: 220px;
        width: 100%;
        margin-bottom: 30px;
        margin-top: -90px;
    }
    .search-feature-content .image.feature1{
        background: url("~@/assets/images/svg/illustrations/7971.svg") no-repeat center center;
    }
    .search-feature-content .image.feature2{
        background: url("~@/assets/images/svg/illustrations/7974.svg") no-repeat center center;
    }
    .search-feature-content .image.feature3{
        background: url("~@/assets/images/svg/illustrations/7973.svg") no-repeat center center;
    }
    .search-feature-content .image.feature4{
        background: url("~@/assets/images/svg/illustrations/7972.svg") no-repeat center center;
    }
    .search-feature-content .title{
        font: var(--font_text_1) !important;
        font-size: 1.5rem !important;
        font-weight: bold !important;
        margin-bottom: 20px;
        color: var(--c_primary);
    }
    .search-feature-content .description{
        text-align: center;
        color: var(--c_gray_5);
        font-weight: 300;
        font-size: 0.9rem;
        line-height: 1.8rem;
    }
</style>
