import { render, staticRenderFns } from "./FooterAboutWidget.vue?vue&type=template&id=872af95e&scoped=true"
import script from "./FooterAboutWidget.vue?vue&type=script&lang=js"
export * from "./FooterAboutWidget.vue?vue&type=script&lang=js"
import style0 from "./FooterAboutWidget.vue?vue&type=style&index=0&id=872af95e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "872af95e",
  null
  
)

export default component.exports