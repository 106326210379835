import Vue from 'vue'
import router from '../../router'

const state = {
    userAuthenticated: false,
    user: {},
    ticketUnreadCount: 0,
    messages: {
        count: 0,
        unread: 0,
        items:[]
    },
    announcementPopup: true,
    credentials:{
        type:'',
        username:'',
        email: '',
        phone: '',
        password:'',
        newPassword:'',
        firstName:'',
        lastName: '',
        country: '',
        uniqueCode: '',
        token: ''
    },
    stage: 'init'
};

const getters = {
    userInfo: (state) => (state.user),
    loginStatus: (state) => (state.userAuthenticated),
    userMessages: (state) => (state.messages),
    initCredentials: (state) => (state.credentials),
    authStage: (state) => (state.stage),
    countries: (state) => (state.countryList),
    announcementPopup: (state) => (state.announcementPopup),
    ticketUnreadCount: (state) => (state.ticketUnreadCount),
};

const actions = {
    setStage: ({commit}, st) => (commit('_authStageManager', st)),
    InitializeAuth: async ({commit, dispatch}, data) => {
        const url = '/user/auth/status'
        const result = await dispatch('apiCaller', {url: url, data:data, reqType:'POST', callerID:'auth', authReq:true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'auth'})
            } else {
                const credentials={
                    type: result.type,
                    phone: result.type === 'phone' ? result.phone: '',
                    email: result.type === 'email' ? result.email: '',
                    uniqueCode: result.uniqueCode,
                }
                const stage = result.next
                commit('_updateCredentials', credentials)
                commit('_authStageManager', stage)
                return true
            }
        }
        return false
    },
    verification: async ({ commit, dispatch }, data) => {
        const url = '/user/auth/verify'
        const result = await dispatch('apiCaller', {url: url, data:data, reqType:'POST', callerID:'auth', authReq:true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'auth'})
            } else {
                if (result.token){
                    const credentials={
                        token: result.token
                    }
                    commit('_updateCredentials', credentials)
                }
                const stage = result.next
                commit('_authStageManager', stage)
                return true
            }
        }
        return false
    },
    register: async ({ commit, dispatch }, info) => {
        const data = info.credentials
        const url = '/user/auth/signup'
        const result = await dispatch('apiCaller', {url: url, data:data, reqType:'POST', callerID:'auth', authReq:true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'auth'})
            } else {
                const credentials={
                    firstName: data.firstName,
                    lastName: data.lastName,
                    country: data.firstName,
                    password: data.firstName,
                }
                const message = {code : result.code, text: result.message}
                commit('_updateCredentials', credentials)
                commit("_notifySend", {message: message, type:'auth'})
                setTimeout(() => {
                    commit("_closeNotification")
                    commit("_clearCredentials")
                    if(!!!info.ref){
                        router.push({name: 'Dashboard'})
                    } else{
                        router.push({name: info.ref})
                    }
                }, 2000)
                return true
            }
        }
        return false
    },
    login: async ({ commit, dispatch }, info) => {
        const url = '/user/auth/login'
        const result = await dispatch('apiCaller', {url: url, data:info.credentials, reqType:'POST', callerID:'auth', authReq: true})
        if (result) {
            if (result.code !== 200) {
                const message = {code: result.code, text: result.message}
                commit('_notifySend', {message: message, type:'auth'})
            } else {
                const user = {
                    firstName: result.data.user.firstName,
                    lastName: result.data.user.lastName,
                    email: result.data.user.email,
                    phone: result.data.user.phone,
                    balance: result.data.user.balance,
                    avatar: result.data.user.avatar? result.data.user.avatar : require('@/assets/images/users/default.jpg'),
                    hasAvatar: !!result.data.user.avatar,
                    token: result.token,
                    announcements: result.data.user.announcements
                }
                const message = {code: result.code, text: result.message}
                commit("_updateUserInfo", user)
                commit("_updateUserStatus", true)
                commit("_notifySend", {message: message, type:'auth'})
                commit("_setCookieAuth", result.token)
                setTimeout(() => {
                    commit("_closeNotification")
                    commit("_clearCredentials")
                    if(!!!info.ref){
                        router.push({name: 'Dashboard'})
                    } else{
                        router.push({name: info.ref})
                    }
                }, 2000)
                return true
            }
        }
        return false
    },
    logOut: async ({ commit, dispatch }) => {
        const token = Vue.$cookies.get('VSABTSESSION')
        const url = '/user/auth/logout'
        const result = await dispatch('apiCaller', {url: url, data: {token: token}, reqType:'POST', callerID:'auth', authReq: true})
        if (result) {
            if (result.code === 200) {
                const user = {}
                commit("_updateUserInfo", user)
                commit("_updateUserStatus", false)
                commit("_setLoading", false)

                const message = {
                    code : result.code,
                    text: result.message,
                }
                //TODO: CLEAR CART ITEMS ON LOGOUT
                commit('_clearCart')
                commit('_removeAllAdvSearchItemsFromCart')
                commit('_notifySend', {message: message, type:'auth'})

                return true
            }
        }
        return false
    },
    authChecker: async ({ commit, dispatch }, currency="IRT") => {
        const token = Vue.$cookies.get('VSABTSESSION')
        const url = `/user/auth?base=${currency}`
        const data = token == null? {}:{token:token}
        const result = await dispatch('apiCaller', {url: url, data: data, reqType:'GET', callerID:'auth', authReq:(token!=null)})
        if(result){
            if(result.code === 201){
                const cookie = result.token
                commit("_setCookieAuth", cookie)
                dispatch("fetchCartData", currency)
                return false
            }
            else if (result.code === 200) {
                if (result.data.authenticated){
                    const user = {
                        ...result.data.user,
                        avatar: result.data.user.avatar? result.data.user.avatar : require('@/assets/images/users/default.jpg'),
                        hasAvatar: !!result.data.user.avatar,
                    }
                    commit("_updateUserStatus", true)
                    commit("_updateUserInfo", user)
                    dispatch("getStatus")
                    dispatch("fetchCartData", currency)
                    return true
                } else{
                    const credentials = {token:result.token}
                    commit("_updateCredentials", credentials)
                    commit("_updateUserStatus", false)
                    dispatch("fetchCartData", currency)
                    return false
                }
            }
        }
    },
    initializeResetPass: async ({ commit, dispatch }, data) => {
        const url = '/user/auth/forget'
        const result = await dispatch('apiCaller', {url: url, data:data, reqType:'POST', callerID:'auth', authReq: true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'auth'})
            } else {
                const credentials={
                    type: result.type,
                    phone: result.type === 'phone' ? result.phone: '',
                    email: result.type === 'email' ? result.email: '',
                }
                const stage = result.next
                commit('_updateCredentials', credentials)
                commit('_authStageManager', stage)
                return true
            }
        }
        return false
    },
    resetPassword: async ({ commit, dispatch }, info) => {
        const url = '/user/auth/changePassword'
        const result = await dispatch('apiCaller', {url: url, data:info.credentials, reqType:'POST', callerID:'auth', authReq: true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'auth'})
            } else {
                // commit("_setCookieRegister")
                const message = {code: result.code, text: result.message}
                commit("_notifySend", {message: message, type:'auth'})
                setTimeout(() => {
                    commit("_closeNotification")
                    commit("_clearCredentials")
                    if(!!!info.ref){
                        router.push({name: 'Dashboard'})
                    } else{
                        router.push({name: info.ref})
                    }
                }, 2000)
            }
        }
        return false
    },
    resendCode: async ({ commit, dispatch }, data) => {
        const url = '/user/auth/resend'
        const result = await dispatch('apiCaller', {url: url, data:data, reqType:'POST', callerID:'auth', authReq: true})
        if(result){
            const message = {code: result.code, text: result.message}
            commit("_notifySend", {message: message, type:'auth'})
        }
    },
    editUserInfo: ({ commit }, userData) => {
        if (!userData.avatar){
            userData.avatar = require('@/assets/images/users/default.jpg')
        }
        commit("_editUserInfo", userData)
    },
    updateUserBalance: ({ commit }, balance) => {
        commit("_updateUserBalance", balance)
    },
    updateUserAvatar: ({ commit }, opts) => {
        commit("_updateUserAvatar", {avatarUrl: opts.url, hasAvatar: !opts.isDelete})
    },
    setFieldVerifiedStatus: ({ commit }, opts) => {
        commit("_updateFieldVerificationStatus", {field: opts.field, status: opts.status})
    },
    clearCredentials: ({ commit }) => {
        commit("_clearCredentials")
    },
    getCountryList: async ({ commit, dispatch }) => {
        const url = '/tools/countries/short'
        const result = await dispatch('apiCaller', {url: url, data: {}, reqType:'GET', callerID:'auth', authReq: true})
        if(result && result.code === 200){
            const list = result.data
            commit("_loadCountries", list)
            return list
        } else{
            const message = {code: result.code, text: result.message}
            commit("_notifySend", {message: message, type:'auth'})
        }
    },
    getStatus: async ({ commit, dispatch, state }) => {
        if(state.userAuthenticated){
            try {
                const url = '/user/panel/stat'
                const result = await dispatch('apiCaller', {url: url, data: {}, reqType: 'GET', callerID: 'auth', authReq: true})
                if (result && result.code === 200) {
                    commit("_addNewMessages", {messages: result.data.announcement.messages, unread: result.data.announcement.count})
                    commit("_updateMessages")

                    commit("_updateUnreadTicketCount", result.data.ticket.count)

                    dispatch("updateUserBalance", result.data.finance.balance)
                } else {
                    const message = {code: result.code, text: result.message}
                    commit("_notifySend", {message: message, type: 'auth'})
                }
            } catch (e) {}
        }
    },
    markMessageAsRead: ({ commit }, id) => {
        commit("_markMessageAsRead", id)
        commit("_updateMessages")
    },
};

const mutations = {
    _updateCredentials: (state, credentials) => {
        // state.loadingInProgress.auth = false
        Object.assign(state.credentials, credentials)
    },
    _clearCredentials: (state) => (
        state.credentials = {
            type:'', username:'', email: '', phone: '', password:'', newPassword:'',
            firstName:'', lastName: '', country: '', uniqueCode: '', token: ''
        }),
    _authStageManager: (state, stage) => {
        // state.loadingInProgress.auth = false
        state.stage = stage
    },
    _setCookieRegister: (state) => {
        Vue.$cookies.set("accessToken",state.credentials.accessToken,"7d",'/','api.vsabt.com',true,'none');
    },
    _setCookieAuth: (state, cookie) => {
        Vue.$cookies.set("VSABTSESSION", cookie,"365d");
    },
    _updateUserInfo: (state, userData) => {
        state.user = userData
    },
    _editUserInfo: (state, userData) => {
        state.user = Object.assign(state.user, userData)
    },
    _updateUserBalance: (state, balance) => {
        state.user.balance = balance
    },
    _updateUserAvatar: (state, opts) => {
        state.user.avatar = opts.avatarUrl
        state.user.hasAvatar = opts.hasAvatar
    },
    _updateFieldVerificationStatus: (state, opts) => {
        state.user.verified[opts.field] = opts.status
    },
    _updateUserStatus: (state, userStatus) => {
        state.userAuthenticated = userStatus
    },
    _addNewMessages: (status, newMessages) => {
        status.messages.items = newMessages.messages
        status.messages.unread = newMessages.unread
    },
    _updateMessages: (status) => {
        status.messages.count = status.messages.items.length
    },
    _markMessageAsRead: (state, id) => {
        state.messages.items.forEach((item, index) => {
            if(item.id === id){
                // state.messages.items[index].read = true
                state.messages.items.splice(index, 1)
            }
        })
    },
    _loadCountries: (state, list) =>{
        state.countryList = [...list]
    },
    _updateUnreadTicketCount: (status, count) => {
        status.ticketUnreadCount = count
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
