import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
// import GAuth from 'vue-google-oauth2'
// import VueReCaptcha from 'vue-recaptcha-v3'
// import Vuebar from 'vuebar';
import titleMixin from './mixins/titleMixin'
import '@/assets/css/root.css'
import moment from "moment";

window.axios = axios
Vue.config.productionTip = false
Vue.mixin(titleMixin)
Vue.use(VueCookies)
// Vue.use(Vuebar)
// Vue.use(VueReCaptcha, { siteKey: '6Le3uqcbAAAAAIAHGEicWV8p4mlUP3dkOCtV8z_G' })

const numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
    const currency = store.getters.currentCurrency.code;
    if (currency === "IRT") {
        return numeral(value).format("0,0");
    } else if (currency === "USD") {
        return numeral(value).format("0,0.00");
    }
});

const NumberToPersianWord = require('number_to_persian_word');
const NumberToEnglishWord = require('number-to-words');
Vue.filter("convertNumber", function (value) {
    const currency = store.getters.currentCurrency.code;
    if (currency === "IRT") {
        return NumberToPersianWord.convert(value) + " تومان";
    } else if (currency === "USD") {
        return NumberToEnglishWord.toWords(value) + " dollars";
    }

});

Vue.filter("timestampToDate", function (value) {
    const _value = +value * 1000;
    return moment(_value).locale('fa').format('YYYY/M/D');
});


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
