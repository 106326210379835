<template>
	<!--TODO: SET MENU ROUTE LINKS WITH NAME PROPERTY-->
	<v-app-bar :fixed="navFixedStatus" :absolute="!navFixedStatus"
	           :color="navBgColor" :dark="!navFixedStatus"
	           :elevation="navFixedStatus?5:0" :class="navFixedStatus?'blur':''"
	           height="80" flat>
		<v-container>
			<v-row justify="space-between" align="center">
				<v-col class="d-flex align-center justify-start" cols="3" sm="6" md="6" lg="3" ref="user-area">

					<v-badge v-if="loginStatus" class="d-none d-lg-block" color="error" transition="scale-rotate-transition" :value="userMessages.unread && loginStatus" overlap dot>
						<v-chip class="d-none d-lg-block" color="secondary" text-color="white" link id="userAreaActivator">
							<v-avatar left>
								<v-img :src="userInfo.avatar" :lazy-src="userInfo.avatar">
									<template v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular indeterminate color="grey lighten-5" size="20"></v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</v-avatar>
							{{ userInfo.firstName }} {{ userInfo.lastName }}
							<v-icon right>mdi-chevron-down</v-icon>
							<v-menu v-model="menu" :close-on-content-click="false" transition="slide-y-transition"
							        nudge-width="200" nudge-bottom="10" offset-y left open-on-click rounded="xl"
							        activator="#userAreaActivator" content-class="elevation-5" max-width="465">
								<v-card class="y-trans">
									<v-list>
										<v-list-item>
											<v-avatar left class="ml-4" size="64">
												<v-img :src="userInfo.avatar" :lazy-src="userInfo.avatar">
													<template v-slot:placeholder>
														<v-row class="fill-height ma-0" align="center" justify="center">
															<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
														</v-row>
													</template>
												</v-img>
											</v-avatar>

											<v-list-item-content class="flex-shrink-0">
												<v-list-item-title class="font-weight-bold">{{ userInfo.firstName }} {{ userInfo.lastName }}</v-list-item-title>
												<v-list-item-subtitle class="mt-2">
													<v-chip color="grey lighten-3" small link :to="{name: 'Transactions'}">
														<v-icon left small color="secondary">mdi-wallet-plus-outline</v-icon>
														<span class="secondary--text font-weight-bold">اعتبار:</span>
														<span class="balance-amount" :class="currentCurrency.code.toLowerCase()">
															{{ userInfo.balance | formatNumber }}
															<span v-if="currentCurrency.code.toLowerCase()==='usd'" class="mr-1">$</span>
														</span>
													</v-chip>
												</v-list-item-subtitle>
											</v-list-item-content>

											<v-list-item-action class="mr-0">
												<div class="d-flex justify-space-around align-center">
													<v-btn large :color="showLangMenu ? 'secondary':'grey'" icon @click="triggerLangMenu">
														<v-badge left overlap color="secondary" :value="langList.length && loginStatus" :content="currentLang.code">
															<v-icon>mdi-earth</v-icon>
														</v-badge>
													</v-btn>
													<v-btn large :color="showAnnouncements ? 'secondary':'grey'" icon @click="triggerAnnouncements" class="mr-3">
														<v-badge left overlap color="error" :value="userMessages.unread && loginStatus" :content="userMessages.unread">
															<v-icon v-if="userMessages.unread">mdi-bell</v-icon>
															<v-icon v-else>mdi-bell-outline</v-icon>
														</v-badge>
													</v-btn>
												</div>
											</v-list-item-action>
										</v-list-item>
									</v-list>

									<v-divider></v-divider>

									<v-scroll-x-reverse-transition mode="out-in" hide-on-leave>
										<div v-if="!showAnnouncements && !showLangMenu" class="quick-access-wrapper pa-6">
											<v-row>
												<v-col v-for="(item, i) in quickAccessMenuItems" :key="i" cols="3" class="pa-1">
													<v-card v-if="i !== quickAccessMenuItems.length-1 " rounded="xl" class="quick-access-item pa-2"
													        v-ripple="{ class: `${item.color}--text` }" flat link :to="{name: item.target}">
														<div class="quick-access-item-content d-flex flex-column justify-center align-center">
															<div class="quick-access-item-icon mb-3">
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</div>
															<div class="quick-access-item-title">{{ item.title }}</div>
														</div>
													</v-card>
													<v-card v-else rounded="xl" class="quick-access-item pa-2" v-ripple="{ class: `${item.color}--text` }" flat link @click="logOut">
														<v-fade-transition>
															<v-overlay v-if="isLoadingInProgress.auth" absolute color="error">
																<v-progress-circular indeterminate size="24" color="error"></v-progress-circular>
															</v-overlay>
														</v-fade-transition>
														<div class="quick-access-item-content d-flex flex-column justify-center align-center">
															<div class="quick-access-item-icon mb-3">
																<v-icon :color="item.color" :disabled="isLoadingInProgress.auth">{{ item.icon }}</v-icon>
															</div>
															<div class="quick-access-item-title">{{ item.title }}</div>
														</div>
													</v-card>
												</v-col>
												<!--												<v-col cols="3">-->
												<!--													<v-btn large block text rounded color="secondary" link :to="{name: 'DomainManagement'}">-->
												<!--														<v-icon left>mdi-web</v-icon> دامنه های من-->
												<!--													</v-btn>-->
												<!--												</v-col>-->
												<!--												<v-col cols="3">-->
												<!--													<v-btn large block text rounded color="secondary" link :to="{name: 'AccountSettings'}">-->
												<!--														<v-icon left>mdi-account-cog-outline</v-icon> تنظیمات حساب-->
												<!--													</v-btn>-->
												<!--												</v-col>-->
												<!--												<v-col cols="3">-->
												<!--													<v-btn large block text rounded color="error" link @click="logOut"-->
												<!--													       :loading="isLoadingInProgress.auth">-->
												<!--														<v-icon left>mdi-logout-variant</v-icon>خروج از حساب-->
												<!--													</v-btn>-->
												<!--												</v-col>-->

											</v-row>
										</div>
									</v-scroll-x-reverse-transition>
									<v-scroll-x-reverse-transition mode="out-in" hide-on-leave>
										<v-list two-line v-if="showAnnouncements && !showLangMenu" class="py-0">
											<v-list-item v-if="userMessages.count" v-for="item in userMessages.items.slice(0, 5)" :key="item.id"
											             link :to="item.target" @click="openMessage(item.id)">
												<v-list-item-avatar class="d-flex justify-center">
													<v-icon :color="item.read?'grey':'secondary'">mdi-{{ item.read ? 'bell-outline':'bell'}}</v-icon>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title>{{ item.title }}</v-list-item-title>
													<v-list-item-subtitle class="mt-2 d-flex align-center"><v-icon size="18" right>mdi-clock-outline</v-icon>{{ item.date }}</v-list-item-subtitle>
												</v-list-item-content>
											</v-list-item>
											<v-list-item v-if="!userMessages.count" class="pb-5 pt-6">
												<v-list-item-content>
													<v-avatar><v-icon color="grey" x-large>mdi-bell-check-outline</v-icon></v-avatar>
													<v-list-item-title class="text-center grey--text mt-4">اطلاعیه جدیدی ندارید</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-btn v-if="userMessages.count && (userMessages.unread > 5)" height="60" block text tile color="secondary"
											       link :to="{name: 'Announcements', params: {activate: false}}">
												مشاهده {{ userMessages.unread-5 }} اطلاعیه خوانده نشده دیگر
												<v-icon>mdi-chevron-left</v-icon>
											</v-btn>
										</v-list>
									</v-scroll-x-reverse-transition>
									<v-scroll-x-reverse-transition mode="out-in" hide-on-leave>
										<div v-if="!showAnnouncements && showLangMenu" class="lang-items-wrapper pa-0">
											<LocaleSettingsForm />
										</div>
									</v-scroll-x-reverse-transition>
								</v-card>
							</v-menu>
						</v-chip>
					</v-badge>

					<v-btn v-else class="d-none d-lg-flex" x-large text
					       :loading="isLoadingInProgress.auth" :disabled="isLoadingInProgress.auth"
					       :color="navFixedStatus? 'secondary':navColorMode" rounded link :to="{name: 'Login'}">
						<v-icon left size="25">mdi-login-variant</v-icon>ورود/ ثبت نام
					</v-btn>

					<v-btn class="d-lg-none d-inline-block" icon x-large @click="openDrawer">
						<v-badge class="d-lg-none d-block" color="error" :value="userMessages.unread && loginStatus" transition="scale-rotate-transition" overlap dot >
							<v-icon size="25">mdi-menu</v-icon>
						</v-badge>
					</v-btn>

					<v-btn v-if="loginStatus" class="d-lg-none mr-2" icon x-large :to="{name: 'Dashboard'}">
						<v-icon size="25">mdi-view-dashboard-outline</v-icon>
					</v-btn>

					<v-btn v-if="!loginStatus" class="mr-2" icon x-large id="langMenuActivator"
					       :color="navFixedStatus? 'secondary':navColorMode">
						<v-badge :content="currentLang.code.toUpperCase()" color="secondary" left offset-x="10" offset-y="7">
							<v-icon size="25">mdi-earth</v-icon>
						</v-badge>
						<v-menu v-model="showLangMenu" :close-on-content-click="false" transition="slide-y-transition"
						        nudge-width="450" nudge-bottom="10" offset-y max-width="400" left open-on-click rounded="xl"
						        activator="#langMenuActivator" content-class="elevation-5">
							<v-card class="y-trans">
								<v-card-title class="justify-center py-5">
									زبان و واحد پولی
								</v-card-title>
								<v-divider></v-divider>
								<LocaleSettingsForm />
							</v-card>
						</v-menu>
					</v-btn>

					<v-btn class="mr-2" icon x-large :color="navFixedStatus? 'secondary':navColorMode" id="cartAreaActivator">
						<v-badge color="error" :value="cart.count" :content="cart.count" transition="scale-rotate-transition" overlap>
							<v-icon size="25">mdi-cart-outline</v-icon>
						</v-badge>
						<v-menu v-model="cartMenu" :close-on-content-click="false" transition="slide-y-transition"
						        nudge-width="450" nudge-bottom="10" offset-y max-width="450" left open-on-click rounded="xl"
						        activator="#cartAreaActivator" content-class="elevation-5">
							<v-card class="y-trans">
								<v-card-title class="justify-center py-5">
									<v-badge inline color="secondary" :value="cart.count" :content="`${cart.count} مورد`">سبد خرید شما</v-badge>
								</v-card-title>
								<v-divider></v-divider>
								<v-scroll-x-reverse-transition mode="out-in" hide-on-leave>
									<v-list two-line v-if="cart.count" class="py-0">
										<v-list-item v-for="(item, i) in cart.items" :key="i" link>

											<v-list-item-avatar class="d-flex justify-center">
												<v-icon v-if="item.cat==='domain'" color="secondary">mdi-web</v-icon>
												<v-icon v-else color="secondary">mdi-cube-outline</v-icon>
											</v-list-item-avatar>

											<v-list-item-content>
												<v-list-item-title class="font-weight-regular">
													<span v-if="item.type==='register'">ثبت </span>
													<span v-else-if="item.type==='renew'">تمدید </span>
													<span v-else-if="item.type==='transfer'">انتقال </span>
													<span v-if="item.cat==='domain'">دامنه </span>
													<span class="font-weight-bold">{{ item.sld+'.'+item.tld }}</span>
												</v-list-item-title>

												<v-list-item-subtitle class="mt-2 d-flex align-center justify-start">
													<v-chip small color="blue-grey lighten-5" class="ml-2 pr-2">
														<v-avatar left>
															<v-img :src="item.registrar.logo" max-width="18" max-height="18"
															       :lazy-src="item.registrar.logo"></v-img>
														</v-avatar>
														{{ item.registrar.title }} </v-chip>
													<span class="cart-total-due" :class="currentCurrency.code.toLowerCase()">
														{{ item.price | formatNumber }}
														<span v-if="currentCurrency.code.toLowerCase()==='usd'" class="grey--text"> $</span>
													</span>
												</v-list-item-subtitle>

											</v-list-item-content>
											<v-list-item-action>
												<v-btn icon color="grey lighten-1" @click="deleteItem(item,i)"
												       :loading="isLoadingInProgress.cart && deletedItemIdx===i">
													<v-icon>mdi-delete-outline</v-icon>
												</v-btn>
											</v-list-item-action>
										</v-list-item>
									</v-list>
								</v-scroll-x-reverse-transition>
								<v-scroll-x-reverse-transition mode="out-in" hide-on-leave>
									<v-list v-if="!cart.count" class="py-0">
										<v-list-item>
											<v-list-item-content>
												<v-list-item-content>
													<v-avatar><v-icon color="grey" x-large>mdi-cart-remove</v-icon></v-avatar>
													<v-list-item-title class="text-center grey--text mt-4">سبد خرید شما خالیست</v-list-item-title>
												</v-list-item-content>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-scroll-x-reverse-transition>
								<v-divider v-if="cart.count"></v-divider>
								<v-card-actions v-if="cart.count" class="py-3">
									<v-row>
										<v-col cols="7" sm="6">
											<v-list two-line class="py-0">
												<v-list-item>
													<v-list-item-avatar class="d-flex justify-center" color="secondary">
														<v-icon color="white">mdi-currency-usd</v-icon>
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title class="font-weight-bold">جمع کل</v-list-item-title>
														<v-list-item-subtitle class="mt-2">
															<span class="cart-total-due" :class="currentCurrency.code.toLowerCase()">
																{{ cart.totalDue | formatNumber }}
																<span v-if="currentCurrency.code.toLowerCase()==='usd'" class="grey--text"> $</span>
															</span>
														</v-list-item-subtitle>
													</v-list-item-content>
												</v-list-item>
											</v-list>
										</v-col>
										<v-col cols="5" sm="6" class="d-flex align-center justify-lg-space-around">
											<v-btn text rounded large color="error" @click="cartCleaner" icon
											       :loading="isLoadingInProgress.cart && cartCleaning"
											       class="d-none d-sm-inline-block">
												<v-icon size="20">mdi-cart-remove</v-icon>
											</v-btn>
											<v-btn text rounded large link color="secondary" :to="{name: 'Checkout'}"><v-icon left>mdi-cart-check</v-icon>اتمام خرید</v-btn>
										</v-col>
									</v-row>
								</v-card-actions>
							</v-card>
						</v-menu>
					</v-btn>
				</v-col>
				<v-col class="d-none d-lg-flex justify-end align-center" cols="1" sm="1" md="1" lg="7" ref="menu">
					<div v-for="item in mainMenu" class="d-inline-block" :key="`menuItem_${item.id}`">
						<v-btn v-if="!item.submenu" large plain rounded class="ml-4" :to="'/'+item.target">{{ item.title }}</v-btn>
						<v-btn v-if="item.submenu" large plain rounded class="ml-4" :id="'menuBtn'+item.id" :to="'/'+item.target"
						       @click.native.stop.prevent.capture="nothing">
							{{ item.title }}
							<v-icon class="mr-2">mdi-chevron-down</v-icon>
							<v-menu open-on-hover bottom left offset-y origin="center center" transition="slide-y-transition"
							        rounded="xl" min-width="250" :activator="'#menuBtn'+item.id" content-class="elevation-5">
								<v-list class="py-0">
									<v-list-item v-for="subItem in item.submenu" :key="subItem.id" link
									             :to="subItem.relative?'/'+item.target+'/'+subItem.target:'/'+subItem.target">
										<v-list-item-icon class="ml-4"><v-icon v-text="'mdi-'+subItem.icon"></v-icon></v-list-item-icon>
										<v-list-item-title v-text="subItem.title"></v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-btn>

					</div>
				</v-col>
				<v-col class="d-flex align-center justify-end" cols="6" sm="6" md="6" lg="2" ref="logo">
					<router-link :to="{ name: 'Home' }">
						<div :class="!navFixedStatus?'mainLogo':'mainLogo2'"></div>
					</router-link>

				</v-col>
			</v-row>
		</v-container>
	</v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LocaleSettingsForm from "@/components/LocaleSettingsForm.vue";

export default {
	name: "MainNavigation",
	components: {LocaleSettingsForm},
	data: () => ({
		drawer: null,
		menu: false,
		cartMenu: false,
		showAnnouncements: false,
		showLangMenu: false,
		userAreaLoading: false,
		cartCleaning: false,
		deletedItemIdx: -1,
		quickAccessMenuItems: [
			{title: 'پنل کاربری', icon: 'mdi-view-dashboard-outline', color:'secondary', target: 'Dashboard'},
			{title: 'دامنه های من', icon: 'mdi-web', color:'secondary', target: 'DomainManagement'},
			{title: 'تنظیمات حساب', icon: 'mdi-account-cog-outline', color:'secondary', target: 'AccountSettings'},
			{title: 'خروج از حساب', icon: 'mdi-logout-variant', color:'error', target: 'Logout'},
		]
	}),
	computed: mapGetters([
		"cart", "loginStatus", "userInfo", "userMessages", "navBgColor",
		"mainMenu", "isLoadingInProgress", "navColorMode", "navFixedStatus",
		"currentLang", "langList", "currentCurrency"
	]),
	methods: {
		...mapActions(["clearCart", "deleteCartItem", "getStatus", "markMessageAsRead", "setGlobalLanguage", "logOut"]),
		openDrawer: function () {
			this.$emit('toggleDrawer')
		},
		triggerAnnouncements: function () {
			this.showAnnouncements = !this.showAnnouncements
			this.showLangMenu = false
		},
		triggerLangMenu: function () {
			this.showLangMenu = !this.showLangMenu
			this.showAnnouncements = false
		},
		nothing: function () {
			return false
		},
		deleteItem: async function(item, idx){
			this.deletedItemIdx = idx
			const result = await this.deleteCartItem(item)
			this.deletedItemIdx = -1
		},
		cartCleaner: async function(){
			this.cartCleaning = true
			const result = await this.clearCart()
			this.cartCleaning = false
		},
		openMessage: function (id){
			// this.markMessageAsRead(id)
			this.$router.push({name:'Announcement', params:{id: id}})
		}
	},
	watch: {
		'$route': function () {
			this.showAnnouncements = false
			this.showLangMenu = false
		},
		menu:{
			handler: function (val) {
				setTimeout(() => {
					if (!val) {
						this.showAnnouncements = false
						this.showLangMenu = false
					}
				}, 300)
			},
			deep: true
		},
	}
}
</script>

<style>
nav{
	position: absolute;
	display: block;
	width: 100%;
	height: 80px;
	top: 0;
	z-index: 999999;
	transition: var(--transition);
}

nav.emoji-mart-anchors {
	position: relative !important;

	display: flex  !important;
	flex-direction: row !important;

	width: inherit !important;
	height: inherit !important;
	top: inherit !important;
	z-index: inherit !important;
	transition: inherit !important;
}

.nav-wrapper{
	display: flex;
	position: relative;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
}

.mainLogo {
	display: block;
	flex: 0 0 auto;
	background: url('~@/assets/images/svg/logow2.svg') no-repeat;
	background-size: contain;
	width: 200px;
	height: 40px;
}

.mainLogo2 {
	display: block;
	flex: 0 0 auto;
	background: url('~@/assets/images/svg/logoc2.svg') no-repeat;
	background-size: contain;
	width: 200px;
	height: 40px;
}

.fixed {
	-webkit-box-shadow: 0 5px 1rem rgba(39,43,65,.08) !important;
	box-shadow: 0 5px 1rem rgba(39,43,65,.08) !important;
}
.blur{
	backdrop-filter: blur(20px) !important;
	transition: all .5s;
}
.y-trans{
	transition:all 0.5s ease;
}

.cart-registrar{
	font-size: 1rem;
	font-weight: bold;
	color: var(--c_secondary);
}

.cart-total-due{
	font-size: 1rem;
	font-weight: bold;
	color: var(--c_secondary);
}

.cart-total-due.irt:after{
	content: "";
	display: inline-block;
	background: url('~@/assets/images/svg/TOMAN.svg') no-repeat;
	background-size: contain;
	width: 17px;
	height: 17px;
	vertical-align: sub;
	margin-right: 3px;
	fill: var(--c_secondary);
}

.balance-amount{
	margin-right: 5px;
}

.balance-amount.irt:after{
	content: "";
	display: inline-block;
	background: url("~@/assets/images/svg/TOMAN.svg") no-repeat;
	background-size: contain;
	width: 14px;
	height: 14px;
	vertical-align: sub;
	margin-right: 5px;
	mix-blend-mode: difference;
}

.quick-access-wrapper{
	max-width: 100%;
}

.quick-access-item{
	overflow: hidden;
}

.quick-access-item-content{
}

.quick-access-item-icon{
}

.quick-access-item-title{
	font-size: 0.68rem;
	font-weight: bold;
	color: var(--c_gray_5);
	text-align: center;
}

@media (max-width: 600px) {
	.mainLogo, .mainLogo2 {
		height: 30px;
	}
}
</style>
