<template>
    <div class="coming-soon-wrapper">
        <v-container tag="section" class="d-flex fill-height">
            <v-row class="top" align="center" justify="center">
                <v-col cols="12" lg="5" xl="4" class="d-flex flex-row justify-center">
                    <div class="logo2 d-block d-lg-none"></div>
                </v-col>
                <v-col cols="12" lg="6" xl="4" offset-xl="1" class="d-flex flex-row justify-end">
                    <div class="logo1 d-none d-lg-block"></div>
                </v-col>
            </v-row>
            <v-row class="middle" align="center" justify="center">
                <v-col cols="12" lg="6" xl="4" offset-xl="1" order="1" order-lg="2">
                    <div class="banner">بـــــــــــه زودی<br>در کــنار شــما<br>خــواهیم بـود</div>
                </v-col>
                <v-col cols="12" lg="5" xl="4" order="2" order-lg="1" id="subscribeWrapper">
                    <v-lazy :options="{ threshold: 0.4 }" transition="scroll-y-transition">
                        <div class="box-image"></div>
                    </v-lazy>
                    <v-card :loading="loading" class="" elevation="5" rounded="xl" id="email-card">
                        <template slot="progress">
                            <v-progress-linear color="secondary" absolute height="4" indeterminate reversed></v-progress-linear>
                        </template>
                        <v-card-text>
                            <v-row align="center" class="mx-0">
                                <div class="box-text">جهت اطلاع از آخرین تغییرات و زمان آغاز به کار خدمات رسانی وی ثبت به شما کاربر گرامی در صورت تمایل میتوانید ایمیل یا شماره تلفن همراه خود را در این قسمت ثبت نمایید</div>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col cols="12">
                                    <v-form ref="subscription" id="epForm" class="pa-4 custom_input" @submit.prevent="submit_email">
                                        <v-text-field v-model="ep" label="ایمیل یا شماره تلفن همراه شما"
                                                      hint="برای مثال yourname@gmail.com یا 09123456789" height="60"
                                                      color="secondary" background-color="grey lighten-5" rounded
                                                      required :rules="[rules.required]" @blur="$refs.subscription.resetValidation()" outlined>
                                            <template v-slot:append>
                                                <v-btn class="submit-btn" large rounded text height="60"
                                                       :loading="loading" :disabled="loading" color="secondary"
                                                       @click.prevent="submit_email">ثبت
                                                    <v-icon right>mdi-arrow-left</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                        <v-fade-transition>
                            <v-overlay v-if="loading" absolute color="#ffffff">
                                <v-progress-circular indeterminate size="64" color="secondary"></v-progress-circular>
                            </v-overlay>
                        </v-fade-transition>
                        <v-snackbar v-model="message.active" bottom rounded="pill" absolute :multi-line="false">
                            <v-icon class="ml-1" :color="message.color">mdi-{{message.icon}}</v-icon>
                            {{ message.text }}
                            <template v-slot:action="{ attrs }">
	                            <v-btn color="light" icon v-bind="attrs" @click="message.active = false">
		                            <v-icon size="20">mdi-close</v-icon>
	                            </v-btn>
                            </template>
                        </v-snackbar>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="bottom" align="center" justify="center">
                <v-col cols="12" lg="5" xl="4" class="d-flex flex-row justify-center" dir="ltr" order="2" order-lg="1">
                    <span class="cpr grey--text">&copy; 2024 VSABT. All rights reserved.</span>
                </v-col>
                <v-col cols="12" lg="6" xl="4" offset-xl="1" order="1" order-lg="2" >
                    <div class="footer-social">
                        <a href="https://wa.me/message/ZDH3SLOUE6WVG1" id="whatsappLink" target="_blank">
                            <v-btn icon x-large color="success">
                                <v-icon color="green">mdi-whatsapp</v-icon>
                            </v-btn>
                            <v-tooltip color="green" activator="#whatsappLink" top><span>واتس اپ</span></v-tooltip>
                        </a>
                        <a href="https://t.me/vsabt" id="telegramLink" target="_blank">
                            <v-btn icon x-large color="info">
                                <v-icon color="info">mdi-send</v-icon>
                            </v-btn>
                            <v-tooltip color="info" activator="#telegramLink" top><span>تلگرام</span></v-tooltip>
                        </a>
                        <a href="https://www.instagram.com/vsabt_com/" target="_blank" id="instagramLink">
                            <v-btn icon x-large color="pink">
                                <v-icon color="pink">mdi-instagram</v-icon>
                            </v-btn>
                            <v-tooltip color="pink" activator="#instagramLink" top><span>اینستاگرام</span></v-tooltip>
                        </a>
                        <a href="https://github.com/VSABT" id="githubLink" target="_blank">
                            <v-btn icon x-large color="black">
                                <v-icon color="black">mdi-github</v-icon>
                            </v-btn>
                            <v-tooltip color="black" activator="#githubLink" top><span>گیت هاب</span></v-tooltip>
                        </a>
                        <a href="https://www.linkedin.com/company/vsabt" target="_blank" id="linkedinLink">
                            <v-btn icon x-large color="indigo lighten">
                                <v-icon color="indigo lighten">mdi-linkedin</v-icon>
                            </v-btn>
                            <v-tooltip color="indigo lighten" activator="#linkedinLink" top><span>لینکدین</span></v-tooltip>
                        </a>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>

</template>

<script>
    import { mapActions } from 'vuex';
    import md5 from "js-md5"

    export default {
        title: "به زودی",
        name: 'ComingSoon',
        data: () => ({
            ep:"",
            rules: {
                required: value => !!value || 'ایمیل یا شماره موبایل وارد نشده است',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'ایمیل نامعتبر است'
                },
            },
            loading: false,
            selection: 1,
            message: {active: false, code: "", color: "", icon: "", text: ""}
        }),
        methods: {
            ...mapActions(["setLoadingScreen"]),
            submit_email: async function() {
                if(this.$refs.subscription.validate()){
                    this.loading = true
                    let data = {
                        data: this.ep,
                        signature: md5(this.ep+"1104.1104")
                    }
                    try {
                        const res = await fetch('https://api.vsabt.com/v1/subscribe', {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                                'mode': 'no-cors'
                            },
                            body: JSON.stringify(data),
                        })
                        const result = await res.json()
                        if (result){
                            this.loading = false
                            this.message.code = result.code
                            this.message.color = this.message.code==200?'success':'error'
                            this.message.icon = this.message.code==200?'check-bold':'close-thick'
                            this.message.text = result.message
                            this.message.active = true
                        }
                    } catch (e) {
                        this.loading = false
                        this.message.code = 500
                        this.message.color = 'warning'
                        this.message.icon = 'wifi-strength-off-outline'
                        this.message.text = "خطا در اتصال"
                        this.message.active = true
                    }
                }
            },
        },
        mounted(){
            setTimeout(() => {
                this.setLoadingScreen(false)
            },2000)
        }
    }
</script>

<style scoped>
    .coming-soon-wrapper{
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background-size: auto 300%;
        background: url("~@/assets/images/svg/comingsoonbg.svg") no-repeat 45% 45%;;
    }

    .logo1 {
        display: block;
        background: url('~@/assets/images/svg/logow2.svg') no-repeat center;
        background-size: contain;
        width: 200px;
        height: 44px;
        mix-blend-mode: overlay;
    }

    .logo2 {
        display: block;
        background: url('~@/assets/images/svg/logoc2.svg') no-repeat center;
        background-size: contain;
        width: 200px;
        height: 44px;
        mix-blend-mode: multiply;
    }

    .banner{
        font-family: var(--font_text_1) !important;
        font-weight: 800;
        font-size: 5rem;
        color: var(--c_gray_2);
        mix-blend-mode: luminosity;
        text-shadow: var(--shadow_1);
        text-align: center;
        margin: 30px 0 100px;
    }

    .box-image {
        position: absolute;
        display: block;
        width: calc(100% - 2 * 12px);
        height: 300px;
        background: url("~@/assets/images/svg/illustrations/14.svg") no-repeat center;
        background-size: 50% 100%;
        margin: 0 auto;
        z-index: 999;
        top: -100px;
    }

    #subscribeWrapper{
        position: relative;
    }

    .box-text{
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 300;
        color: var(--c_gray_5);
        padding: 0 50px;
        line-height: 2rem;
        margin: 180px auto 30px;
    }

    .submit-btn{
        font-size: 1rem !important;
        font-weight: 700 !important;
        letter-spacing: normal !important;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .footer-social{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 30px;
    }

    .footer-social a{
        text-decoration: none;
        font-size: 2rem;
        color: var(--c_gray_2) !important;
        transition: var(--transition);
    }

    .footer-social a:hover{
        color: var(--c_indigo) !important;
    }

    .cpr{
        mix-blend-mode: multiply;
    }


    @media (max-width: 600px) {
        .banner{
            font-size: 3.6rem;
        }
    }

</style>
