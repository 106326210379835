<template>
    <v-container tag="section" id="faq">
        <v-row>
            <v-col cols="12" lg="4" class="px-10">
                <h1 class="section-title">پرسش های متداول</h1>
                <p class="section-description">
                    پیش از تماس با بخش پشتیبانی وی ثبت میتوانید در این قسمت تعدادی از پرسش هایی که بیشترین تکرار را داشته اند مشاهده نموده و پاسخ مربوطه را مطالعه فرمایید.
                    <br>
                    همچنین  امکان گفتگوی مستقیم با همکاران ما در بخش پشتیبانی آنلاین در قسمت پایین و سمت راست صفحه برای شما فراهم شده است.
                    <br>
                    در صورتی که پاسخ خود را از این روش ها پیدا نکرده اید، برای بررسی بیشتر از فرم تماس با ما در پیوند زیر استفاده کرده و پرسش خود را ثبت نمایید.
                </p>
                <v-btn block x-large rounded height="60" class="section-btn"
                       elevation="0" color="indigo darken-1" dark :to="{name: 'Contact'}">
                    <v-icon class="section-btn-icon" left size="30">mdi-headset</v-icon>
                    تماس با پشتیبانی
                </v-btn>
            </v-col>
            <v-col cols="12" lg="8">
                <v-card rounded="xl" elevation="5" class="faq-list-wrapper overflow-hidden">
                    <v-expansion-panels accordion focusable flat v-model="faqDefault">
                        <v-expansion-panel v-for="item in faqItems" :key="item.id" >
                            <v-expansion-panel-header ripple color="grey lighten-5" class="accordion-button">
                                {{ item.question }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="accordion-body">
                                <p class="accordion-body-text">{{ item.answer }}</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "HomeFAQSection",
        data() {
            return{
                faqDefault: 0,
            }
        },
        computed: mapGetters(["faqItems"])
    }
</script>

<style>
    section#faq{
        position: relative;
        z-index: 10;
    }

    section#faq h1.section-title{
        display: block;
        text-align: center;
        font-weight: 800;
        font-size: 2rem;
        color: var(--c_navy);
        text-shadow: 0 0 10px rgba(55,79,144,0.2);
        mix-blend-mode: luminosity;
    }

    section#faq h1.section-title:after{
        content: "";
        display: block;
        width: 150px;
        height: 10px;
        background: url("~@/assets/images/svg/hzsep2.svg")no-repeat center right;
        margin: 30px auto 30px;
    }

    section#faq p.section-description{
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 300;
        color: var(--c_gray_5);
        padding: 0 20px;
        line-height: 2rem;
        z-index: 10;
        margin-bottom: 35px;
    }

    section#faq .section-btn-icon{
        mix-blend-mode: overlay;
        opacity: 0.5;
        margin-left: 20px;
    }

    section#faq .section-btn{
        background: var(--c_gradient_2);
        margin-bottom: 30px;
        transition: all .5s;
    }

    section#faq .section-btn:hover{
        box-shadow: var(--shadow_2) !important;
    }

    section#faq .faq-list-wrapper{
        display: block;
        position: relative;
        border-radius: var(--border_radius);
        box-shadow: var(--shadow_1);
        overflow: hidden;
    }

    section#faq .faq-list-wrapper .accordion-button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 30px 30px;
        font-family: var(--font_text_1);
        font-weight: 700;
        font-size: 1.1rem;
        color: var(--c_gray_5);
        text-align: right;
        background-color: rgba(250,250,250,1);
        /*backdrop-filter: blur(5px);*/
        border: solid rgba(250,250,250,1);
        border-width: 0 0 1px 0;
    }

    section#faq .faq-list-wrapper .v-expansion-panel-header--active {
        color: var(--c_gray_3) !important;
    }

    section#faq .faq-list-wrapper .accordion-body-text{
        text-align: justify;
        font-family: var(--font_text_1);
        font-weight: 300;
        color: var(--c_gray_3);
        line-height: 2rem;
        padding: 20px 20px 10px;
    }
</style>
