<template>
    <div class="reseller-wrapper">
        <div class="reseller-bg"></div>
        <v-container tag="section" id="resellers">
            <v-row>
                <v-col cols="12" lg="4" order="2" order-lg="1">
                    <h1 class="section-title"><v-badge :value="true" color="error" content="به زودی" >نمایندگی فروش</v-badge></h1>
                    <p class="section-description">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                        و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
                        کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                        آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت ایجاد کرد.
                    </p>
                    <v-btn block x-large rounded height="60" class="section-btn"
                           elevation="0" color="indigo darken-1" dark>
                        <v-icon class="section-btn-icon" left size="30">mdi-finance</v-icon>
                        اطلاعات بیشتر
                    </v-btn>
                </v-col>
                <v-col cols="12" lg="8"  order="1" order-lg="2">
                    <div class="reseller-illustration"></div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "HomeResellerSection"
    }
</script>

<style>
    section#resellers{
        position: relative;
        margin-top: 250px;
        z-index: 10;
    }

    .reseller-wrapper{
        position: relative;
    }

    .reseller-bg {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 1800px;
        background: url("~@/assets/images/svg/reseller-bg.svg") no-repeat top center;
        background-size: 1910px;
	    top: -510px;
	    left: -180px;
    }

    section#resellers h1.section-title{
        display: block;
        text-align: center;
        font-weight: 800;
        font-size: 2rem;
        color: var(--c_indigo);
        text-shadow: 0 0 10px rgba(55,79,144,0.2);
        mix-blend-mode: luminosity;
    }

    section#resellers h1.section-title:after{
        content: "";
        display: block;
        width: 150px;
        height: 10px;
        background: url("~@/assets/images/svg/hzsep2.svg")no-repeat center right;
        margin: 30px auto 30px;
    }

    section#resellers p.section-description{
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 300;
        color: var(--c_gray_5);
        padding: 0 20px;
        line-height: 2rem;
        z-index: 10;
        margin-bottom: 35px;
    }

    section#resellers .section-btn-icon{
        mix-blend-mode: overlay;
        opacity: 0.5;
        margin-left: 20px;
    }

    section#resellers .section-btn{
        background: var(--c_gradient_1);
        margin-bottom: 30px;
        transition: all .5s;
    }

    section#resellers .section-btn:hover{
        box-shadow: var(--shadow_3) !important;
    }
    section#resellers .reseller-illustration{
        display: block;
        position: relative;
        width: 100%;
        height: 600px;
    }

    section#resellers .reseller-illustration:after{
        content: "";
        display: block;
        position: absolute;
        background: url('~@/assets/images/svg/illustrations/5.svg') no-repeat top left;
        background-size: 130% 100%;
        width: 100%;
        height: 750px;
        top: -150px;
        left: -120px;
    }

    @media (max-width: 600px) {
	    .reseller-bg {
		    left: 0;
	    }
        section#resellers{
            margin-top: 80px;
        }
        section#resellers .reseller-illustration{
            height: 350px;
        }
        section#resellers .reseller-illustration:after {
            height: 350px;
            background-position: center left;
            background-size: 150% 150%;
            top: 0;
            left: 0;
        }
    }
    @media (min-width: 600px) and (max-width: 960px){
	    .reseller-bg {
		    left: 0;
	    }
        section#resellers{
            margin-top: 80px;
        }
        section#resellers .reseller-illustration{
            height: 450px;
        }
        section#resellers .reseller-illustration:after {
            height: 450px;
            background-position: center left;
            background-size: 135% 120%;
            top: 0;
            left: 0;
        }
    }
    @media (min-width: 960px) and (max-width: 1264px){
	    .reseller-bg {
		    left: 0;
	    }
        section#resellers{
            margin-top: 80px;
        }
        section#resellers .reseller-illustration{
            height: 450px;
        }
        section#resellers .reseller-illustration:after {
            height: 450px;
            background-position: center left;
            background-size: 135% 120%;
            top: 0;
            left: 0;
        }
    }
    @media (min-width: 1264px) and (max-width: 1904px){
	    .reseller-bg {
		    left: 0;
	    }
        section#resellers{
            margin-top: 80px;
        }
        section#resellers .reseller-illustration{
            height: 570px;
        }
        section#resellers .reseller-illustration:after {
            height: 570px;
            background-position: top left;
            background-size: 130% 100%;
            top: -40px;
            left: -50px;
        }
    }
    @media (min-width: 1904px){

    }
</style>
