const state = {
    cart: {
        items:[],
        messages: [],
        count: 0,
        totalDue: 0
    }
};

const actions = {
    fetchCartData: async ({ commit, dispatch }, currency) => {
        const url = `/cart?base=${currency}`
        const result = await dispatch('apiCaller', {url: url, data:{}, reqType:'GET', callerID:'cart', authReq: true})

        const loading = {
            register: {
                registrantContact: false,
                administrativeContact: false,
                technicalContact: false,
                billingContact: false,
                registrar: false,
            },
            transfer: {},
            renew: {},
        }

        const rules = {
            register: {},
            transfer:{required: value => !!value || 'کد انتقال دامنه وارد نشده است',},
            renew: {},
        }

        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'cart'})
            } else {
                let items = result.items
                let messages = []
                if (Array.isArray(result.messages) && result.messages.length !== 0){
                    messages = [...result.messages]
                }
                items.forEach(_item => {
                    _item.price = _item.price.newPrice
                    _item.loading = loading[_item.type]
                    _item.rules = rules[_item.type]
                    _item.editable = false
                    _item.error = false
                })

                commit('_loadCartData', {items: items, messages: messages})
                return items
            }
        }
        return false
    },
    addToCart: async ({ commit, dispatch }, info) => {
        const itemToAdd = {
            ...info.item,
        }
        itemToAdd.registrar = itemToAdd.registrar.name
        const isItemInCart = await dispatch("isInCart", {...itemToAdd, callerID:info.callerID})
        if (isItemInCart){
            const message = {code: 400, text: "دامنه مورد نظر قبلا به سبد خرید اضافه شده است."}
            commit('_notifySend', {message: message, type:'cart'})
        } else{
            const url = '/cart/add'
            const result = await dispatch('apiCaller', {url: url, data:itemToAdd, reqType:'POST', callerID:'cart', authReq: true})
            if (result){
                if (result.code !== 200){
                    const message = {code : result.code, text: result.message}
                    commit('_notifySend', {message: message, type:'cart'})
                } else {
                    itemToAdd.id = result.id
                    itemToAdd.registrar = info.item.registrar
                    const message = {code: result.code, text: result.message}
                    commit('_notifySend', {message: message, type:'cart'})
                    commit('_addToCart', itemToAdd)
                    commit(`_set${info.callerID}ItemCartStatus`, {sld:itemToAdd.sld, tld:itemToAdd.tld, status:true})
                    return true
                }
            }
            return false
        }
        return false

    },
    deleteFromCart: async ({ state, dispatch, commit }, itemInfo) => {
        if(!itemInfo.hasOwnProperty('id')){
            for (let item of state.cart.items){
                if (item.sld === itemInfo.sld && item.tld === itemInfo.tld && item.cat === itemInfo.cat){
                    itemInfo.id = item.id
                }
            }
        }

        const url = '/cart/remove'
        const data = (({ id, cat }) => ({ id, cat }))(itemInfo)
        const result = await dispatch('apiCaller', {url: url, data:data, reqType:'POST', callerID:'cart', authReq: true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'cart'})
            } else {
                const message = {code: result.code, text: result.message}
                commit('_notifySend', {message: message, type:'cart'})
                commit('_deleteCartItem', itemInfo)
                commit(`_set${itemInfo.callerID}ItemCartStatus`, {sld:itemInfo.sld, tld:itemInfo.tld, status:false})
                return true
            }
        }
        return false

    },
    deleteCartItem: async ({ dispatch, commit }, info) => {
        if(info.hasOwnProperty("callerID")){
            return await dispatch('deleteFromCart', {sld:info.item.sld, tld:info.item.tld, cat:info.item.cat, callerID:info.callerID})
        } else{
            return await dispatch('deleteFromCart', {sld:info.sld, tld:info.tld, cat:info.cat, callerID:"AdvSearch"})
        }
    },
    clearCart: async ({ commit, dispatch }) => {
        const url = '/cart/remove'
        const result = await dispatch('apiCaller', {url: url, data:{}, reqType:'POST', callerID:'cart', authReq: true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'cart'})
            } else {
                const message = {code: result.code, text: result.message}
                commit('_notifySend', {message: message, type:'cart'})
                commit('_clearCart')
                commit('_removeAllAdvSearchItemsFromCart')
                return true
            }
        }
        return false
    },
    isInCart: async ({ state, commit }, info) => {
        if(state.cart.items.length){
            for (let _item of state.cart.items) {
                if (_item.sld === info.sld && _item.tld === info.tld && _item.type === info.type){
                    commit(`_set${info.callerID}ItemCartStatus`, {sld:info.sld, tld:info.tld, status:true})
                    return true
                }
            }
        }
        return false
    },
    saveOrderItemSetting: async function({ commit, dispatch, getters },item){
        const url = `/cart/${item.cat}/update`

        let orderSettings = {
            id: item.id,
            type: item.type,
        }
        if (item.cat === 'domain' && item.type === 'register'){
            let data = {
                privacy: false,
                administrativeContact: item.data.administrativeContact,
                billingContact: item.data.billingContact,
                registrantContact: item.data.registrantContact,
                technicalContact: item.data.technicalContact,
                nameServers: [],
                comment: item.data.comment,
            }
            if (item.data.nameServers){
                item.data.nameServers.forEach(_ => {data.nameServers.push(_.value)})
            }
            orderSettings = {...orderSettings, ...data}
        }
        else if(item.cat === 'domain' && item.type === 'transfer'){
            let data = {
                transferCode: item.data.transferCode,
                comment: item.data.comment,
            }
            orderSettings = {...orderSettings, ...data}
        }


        const result = await dispatch('apiCaller', {url: url, data:orderSettings, reqType:'POST', callerID:'cartSave', authReq: true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'cart'})
            } else {
                const message = {code: result.code, text: result.message}
                commit('_notifySend', {message: message, type:'cart'})
                return await dispatch('fetchCartData', getters.currentCurrency.code)
            }
        }
        return false
    },
    fetchRegistrarList: async function({ commit, dispatch, getters }, {tld, type}){
        const url = `/search/prices/tld?tld=${tld}&type=${type}&base=${getters.currentCurrency.code}`
        const result = await dispatch('apiCaller', {url: url, data:{}, reqType:'GET', callerID:'registrars', authReq: true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'registrars'})
            } else {
                return result.data
            }
        }
        return false
    }
};

const getters = {
    cart: (state) => (state.cart)
};

const mutations = {
    _loadCartData: (state, data) => {
        state.cart.items = data.items
        state.cart.messages = data.messages
        state.cart.count = state.cart.items.length
        state.cart.totalDue = 0
        state.cart.items.forEach(_item=>{
            state.cart.totalDue += _item.price
        })
        return state.cart
    },
    _addToCart: (state, newItem) => {
        state.cart.items = state.cart.items.concat(newItem)
        state.cart.count = state.cart.items.length
        state.cart.totalDue += newItem.price
        return state.cart
    },
    _deleteCartItem: (state, DeletedItem) => {
        state.cart.items = state.cart.items.filter(function(item, index, arr){
            return item.id !== DeletedItem.id
        });
        state.cart.count = state.cart.items.length
        state.cart.totalDue = 0
        state.cart.items.forEach(_item=>{
            state.cart.totalDue += _item.price
        })
        return state.cart
    },
    _clearCart: (state) => {
        state.cart.items = []
        state.cart.count = 0
        state.cart.totalDue = 0
    },
    _settransferItemCartStatus : () => {},
    _setrenewItemCartStatus : () => {},
};

export default {
    state,
    getters,
    actions,
    mutations
};
