<template>
    <v-container tag="section" id="features">
        <v-row>
            <v-col cols="12" lg="5">
                    <div class="feature-illustration"></div>
            </v-col>
            <v-col cols="12" lg="7">
                <v-row>
                    <v-col cols="12">
                        <h1 class="section-title">چرا باید وی ثبت را انتخاب کنیم؟</h1>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-for="f in features" :key="f.id" cols="12" md="6" >
                        <v-lazy :options="{ threshold: 1 }" transition="slide-y-transition">
                            <v-card flat rounded="xl" class="pa-5 feature-item-wrapper">
                                <div class="d-flex flex-row feature-item">
                                    <div class="icon-wrapper my-auto">
                                        <v-icon class="icon">mdi-{{ f.icon }}</v-icon>
                                    </div>
                                    <div class="text-wrapper">
                                        <h4>{{ f.title }}</h4>
                                        <p>{{ f.desc }}</p>
                                    </div>
                                </div>
                            </v-card>
                        </v-lazy>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "HomeFeatureSection.vue",
        data(){
            return{
                features:[
                    {id:"1", icon:"currency-usd", title:"بهترین قیمت", desc:" ثبت، تمدید و انتقال دامنه با بهترین قیمت موجود در میان ثبت کننده های دنیا"},
                    {id:"2", icon:"clock-fast", title:"ثبت آنی", desc:"ثبت سریع و خودکار دامنه با اتصال هوشمند به بهترین ثبت کننده ها"},
                    {id:"3", icon:"cloud-search-outline", title:"جستجوی پیشرفته", desc:"ابزار های بسیار قدرتمند جهت تسهیل انتخاب نام مورد نظر شما"},
                    {id:"4", icon:"flare", title:"مدیریت هوشمند", desc:"استفاده از آخرین تکنولوژی های روز برای مدیریت تعاملی و هوشمند دامنه ها"},
                    {id:"5", icon:"all-inclusive", title:"تنوع پسوند ها", desc:"امکان ثبت بیش از 700 پسوند متنوع در TLD های سراسر جهان"},
                    {id:"6", icon:"headset", title:"پشتیبانی 7/24", desc:"پاسخگویی به سوالات فنی مشتریان در همه ساعات شبانه روز"},
                ]
            }
        },
    }
</script>

<style scoped>
    section#features{
        position: relative;
        margin-top: -300px;
        z-index: 1;
    }

    section#features .section-title{
        display: block;
        text-align: center;
        font-weight: 800;
        font-size: 1.8rem;
        color: var(--c_secondary);
        text-shadow: 0 0 10px rgba(8,201,195,0.2);
        mix-blend-mode: luminosity;
    }

    section#features .section-title:after{
        content: "";
        display: block;
        width: 150px;
        height: 10px;
        background: url("~@/assets/images/svg/hzsep.svg")no-repeat center right;
        margin: 30px auto 20px;
    }

    section#features .feature-item-wrapper{
        transition: var(--transition);
    }

    section#features .feature-item-wrapper:hover{
        box-shadow: rgba(0,0,0,.10) 0px 0px 30px 0px !important;
    }

    section#features .feature-item{
        display: block;
    }

    section#features .feature-item .img-wrapper{
        flex: 0 0 auto;
        position: relative;
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    section#features .feature-item .icon-wrapper{
        flex: 0 0 auto;
        position: relative;
        display: block;
    }

    section#features .feature-item .icon-wrapper .icon{
        font-size: 3rem;
        color: var(--c_indigo);
        margin-left: 1.33333rem;
        background: #e9edf6;
        border-radius: 20px;
        padding: 15px;
        /*background: linear-gradient(45deg, var(--c_primary), var(--c_secondary));*/
        /*-webkit-background-clip: text;*/
        /*-webkit-text-fill-color: transparent;*/
    }

    section#features .feature-item h4{
        color: var(--c_indigo);
        font-size: 1.2rem;
        font-weight: 800;
        margin-bottom: .8rem;
    }

    section#features .feature-item p{
        color: var(--c_gray_5);
        font-weight: 300;
        font-size: 0.9rem;
        line-height: 1.8rem;
        margin: 0;
    }

    section#features .feature-illustration{
        position: relative;
        display: block;
        width: 100%;
        height: 600px;
        background: url("~@/assets/images/svg/illustrations/762.svg") no-repeat center center;
        background-size: contain;
    }



    @media (max-width: 600px) {
        section#features .feature-illustration{
            margin-top: -200px;
        }
    }
    @media (min-width: 600px) and (max-width: 960px){

    }
    @media (min-width: 960px) and (max-width: 1264px){

    }
    @media (min-width: 1264px) and (max-width: 1904px){
        section#features{
            margin-top: 0;
        }
    }
    @media (min-width: 1904px){

    }
</style>
