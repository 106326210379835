export default {
    methods: {
        getUrlParams: (vm) => (vm.$route.query),
        validateUrl: (vm) => ((/^(http[s]?:\/\/)?((www)\.)?((([a-zA-Z0-9چجحخهعغفقثصضشسیبلاتنمکگپوئدذرزطظ])([-a-zA-Z0-9چجحخهعغفقثصضشسیبلاتنمکگپوئدذرزطظ]{1,63})[a-zA-Z0-9چجحخهعغفقثصضشسیبلاتنمکگپوئدذرزطظ])\.?)((([\w]+)\.)?([\w]+))?$/g).test(vm)),
        validateUrlWithExt: (vm) => ((/^(http[s]?:\/\/)?((www)\.)?((([a-zA-Z0-9چجحخهعغفقثصضشسیبلاتنمکگپوئدذرزطظ])([-a-zA-Z0-9چجحخهعغفقثصضشسیبلاتنمکگپوئدذرزطظ]{1,63})[a-zA-Z0-9چجحخهعغفقثصضشسیبلاتنمکگپوئدذرزطظ])\.)((([\w]+)\.)?([\w]+))$/g).test(vm)),
        parseUrl: (vm) => {
            let input = vm.toLowerCase()
            const matches = (/^(http[s]?:\/\/)?((www)\.)?((([a-zA-Z0-9چجحخهعغفقثصضشسیبلاتنمکگپوئدذرزطظ])([-a-zA-Z0-9چجحخهعغفقثصضشسیبلاتنمکگپوئدذرزطظ]{1,63})[a-zA-Z0-9چجحخهعغفقثصضشسیبلاتنمکگپوئدذرزطظ])\.?)((([\w]+)\.)?([\w]+))?$/g).exec(input)
            const urlParts = {
                input: matches[0],
                protocol: matches[1],
                www: matches[3],
                name: matches[5],
                TLD1: matches[10],
                TLD2: matches[11],
                fullTLD: matches[8],
                filtered: matches[4] + matches[8]
            }
            return urlParts
        },
        NIDVerifier: (vm) => {
            if ((/^[0-9]{10}$/g).test(vm) && vm !== '1111111111'){
                let L = vm.length;
                if(L<8 || parseInt(vm,10)===0){return false}
                vm = ('0000'+vm).substr(L+4-10)
                if (parseInt(vm.substr(3,6),10)===0){return false}
                let c = parseInt(vm.substr(9,1),10)
                let s = 0;
                for (let i=0; i<9; i++){s += parseInt(vm.substr(i,1),10)*(10-i)}
                s = s%11
                return (s<2 && c===s) || (s>=2 && c===(11-s))
            }
            return false
        },
        isPostalCode: (vm) => ((/^[0-9]{10}$/g).test(vm)),
        isPhoneNumber: (vm) => ((/^[0-9]{10}$/g).test(vm)),
    }
}
