<template>
    <v-app class="body-font">
        <v-overlay :value="loadingScreen" z-index="9999999999" color="white" opacity="1">
            <v-container class="fill-height">
                <v-row align-content="center" justify="center">
                    <v-col cols="12" class="d-flex justify-center">
                        <img class="loading-logo" src="@/assets/images/svg/logoc1.svg">
                    </v-col>
                    <v-col cols="12">
                        <v-progress-linear color="secondary" indeterminate rounded height="6"></v-progress-linear>
                    </v-col>
                </v-row>
            </v-container>
        </v-overlay>
        <v-main>
            <transition name="fade" mode="out-in">
                <router-view/>
            </transition>
        </v-main>
    </v-app>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'App',
        computed:mapGetters(["userInfo", "loginStatus", "loadingScreen"]),
        methods: {
            ...mapActions(["authChecker","fetchCurrentLocale", "setLoadingScreen", "fixAppBar", "getStatus"]),
        },
        created(){
	        this.fetchCurrentLocale()
        },
        mounted(){
        },
        watch:{
            $route: {
                handler: async function (to, from){
	                this.fetchCurrentLocale()
                    await this.getStatus()
                    if(this.$route.params.hasOwnProperty('activate')){
                        this.setLoadingScreen(this.$route.params.activate)
                    } else{
                        this.setLoadingScreen(false)
                    }
                }
            }
        }
    };
</script>

<style>
    .fade-enter-active, .fade-leave-active{transition: opacity 0.3s ease-out;}
    .fade-enter-active{transition-delay: 1s;}
    .fade-enter, .fade-leave-to{opacity: 0;}
    .fade-enter-to, .fade-leave{opacity: 1;}

    .loading-logo{
        width: 120px;
        height: 120px;
        margin: 0;
    }
</style>
