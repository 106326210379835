<template>
	<v-container tag="section">
		<v-row class="my-5">
			<v-col cols="12">
				<v-card rounded="xl" elevation="5" :loading="isLoadingInProgress.search">
					<template slot="progress">
						<v-progress-linear  color="secondary" absolute height="4" indeterminate reversed></v-progress-linear>
					</template>
					<v-card-title>
						<v-row class="align-center">
							<v-col cols="12" md="6" lg="8" class="d-flex align-center justify-space-between justify-md-start">
								<div class="card-title-wrapper">
									<v-icon right color="secondary">mdi-format-list-checkbox</v-icon>
									<span class="card-title">نتایج جستجو برای : <span class="domain-title">{{ domain }}</span></span>
								</div>
							</v-col>
							<v-col cols="12" md="6" lg="4">
								<v-text-field v-model="tableOpts.searchTable" append-icon="mdi-magnify" color="secondary"
								              label="جستجو در میان نتایج" outlined background-color="grey lighten-5" single-line hide-details
								              rounded :disabled="isLoadingInProgress.search">
								</v-text-field>
							</v-col>
						</v-row>
					</v-card-title>
					<v-data-table :headers="tableOpts.headers" :items.sync="tableData" :search="tableOpts.searchTable"
					              fixed-header :show-select="tableOpts.showSelect" hide-default-footer ref="table"
					              class="pricing-table-all" :page.sync="tableOpts.footer.page" :must-sort="false"
					              :items-per-page="tableOpts.footer.itemsPerPage" :mobile-breakpoint="0"
					              @page-count="tableOpts.footer.pageCount = $event" @current-items="showingItems"
					              @pagination="tableContentHandler">

						<template v-slot:header.name="{ header }">
							<span class="table-header">{{ header.text }}</span>
						</template>
						<template v-slot:header.availability="{ header }">
							<v-icon right color="grey" size="20">mdi-progress-check</v-icon>
							<span class="table-header">{{ header.text }}</span>
						</template>
						<template v-slot:header.register="{ header }">
							<v-icon right color="grey" size="20">mdi-check-circle-outline</v-icon>
							<span class="table-header">{{ header.text }}</span>
						</template>
						<template v-slot:header.renew="{ header }">
							<v-icon right color="grey" size="20">mdi-autorenew</v-icon>
							<span class="table-header">{{ header.text }}</span>
						</template>
						<template v-slot:header.transfer="{ header }">
							<v-icon right color="grey" size="20">mdi-transfer</v-icon>
							<span class="table-header">{{ header.text }}</span>
						</template>
						<template v-slot:header.order="{ header }">
							<v-icon right color="grey" size="20">mdi-cog-outline</v-icon>
							<span class="table-header">{{ header.text }}</span>
						</template>

						<template v-slot:item="{ item, index }">
							<tr class="table-item" :disabled="item.availability==='unavailable'">
								<td class="table-cell" :class="item.isCustom?'cell-custom':'cell-normal'">
									<span class="table-content-cell dom">{{ item.domain }}</span>
									<span class="table-content-cell" :class="item.isCustom?'ext-custom':'ext-normal'">{{ '.' + item.name }}</span>
								</td>
								<td class="table-cell content-center">
									<v-fade-transition hide-on-leave mode="out-in">
										<v-btn v-if="!item.valid" text rounded :ripple="false" color="secondary" loading></v-btn>
									</v-fade-transition>
									<v-fade-transition hide-on-leave mode="out-in">
										<v-btn v-if="item.valid" text rounded :ripple="false"  :color="checkAvailable(item.availability)[2]">
											<v-icon left small>mdi-{{ checkAvailable(item.availability)[1] }}</v-icon>
											{{ checkAvailable(item.availability)[0] }}
										</v-btn>
									</v-fade-transition>
								</td>
								<td class="table-cell content-center">
									<v-badge :color="!!item.register.badge?'warning':'secondary'" left
									         :value="item.register.badge || item.register.discountPrice"
									         :content="!!item.register.badge?item.register.badge:`${Math.round((1-item.register.discountPrice/item.register.originalPrice)*100)}%`" >
										<span class="table-content-cell price" :class="currentCurrency.code.toLowerCase()">
											<span>{{ !!item.register.discountPrice? item.register.discountPrice: item.register.originalPrice | formatNumber }}</span>
											<span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
										</span>
									</v-badge>
								</td>
								<td class="table-cell content-center">
									<v-badge :color="!!item.renew.badge?'warning':'secondary'" left
									         :value="item.renew.badge || item.renew.discountPrice"
									         :content="!!item.renew.badge?item.renew.badge:`${Math.round((1-item.renew.discountPrice/item.renew.originalPrice)*100)}%`" >
										<span class="table-content-cell price" :class="currentCurrency.code.toLowerCase()">
											<span>{{ !!item.renew.discountPrice? item.renew.discountPrice: item.renew.originalPrice | formatNumber }}</span>
											<span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
										</span>
									</v-badge>
								</td>
								<td class="table-cell content-center">
									<v-badge :color="!!item.transfer.badge?'warning':'secondary'" left
									         :value="item.transfer.badge || item.transfer.discountPrice"
									         :content="!!item.transfer.badge?item.transfer.badge:`${Math.round((1-item.transfer.discountPrice/item.transfer.originalPrice)*100)}%`" >
                                        <span class="table-content-cell price" :class="currentCurrency.code.toLowerCase()">
	                                        <span>{{ !!item.transfer.discountPrice? item.transfer.discountPrice: item.transfer.originalPrice | formatNumber }}</span>
	                                        <span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
                                        </span>
									</v-badge>
								</td>
								<td class="table-cell content-center">
									<v-fade-transition hide-on-leave mode="out-in">
										<v-btn v-if="!item.valid" text rounded block :ripple="false" color="secondary" loading width="132"></v-btn>
									</v-fade-transition>
									<v-fade-transition hide-on-leave mode="out-in">
										<div v-if="item.valid && item.availability==='unavailable'">
											<v-btn v-if="item.valid && item.availability==='unavailable'" color="warning"
											       text rounded depressed class="mr-2" :id="`opt_menu_${index}`">
												<v-icon left>mdi-dots-horizontal</v-icon> اطلاعات این دامنه

												<v-menu close-on-content-click transition="slide-y-transition" content-class="elevation-5"
												        nudge-left="15" nudge-bottom="10" open-on-click rounded="xl" offset-y right
												        :activator="`#opt_menu_${index}`">
													<v-card class="y-trans">
														<v-list class="py-0">
															<v-list-item link :href="`http://${item.domain}.${item.name}`" target="_blank">
																<v-list-item-icon class="ml-4"><v-icon>mdi-link-variant</v-icon></v-list-item-icon>
																<v-list-item-title>رفتن به {{ item.domain+'.'+item.name }}</v-list-item-title>
															</v-list-item>
															<v-list-item link :to="`whois?domain=${item.domain}.${item.name}`" target="_blank">
																<v-list-item-icon class="ml-4"><v-icon>mdi-information-variant</v-icon></v-list-item-icon>
																<v-list-item-title>مشاهده اطلاعات Whois</v-list-item-title>
															</v-list-item>
															<v-list-item link :href="`https://intodns.com/${item.domain}.${item.name}`" target="_blank">
																<v-list-item-icon class="ml-4"><v-icon>mdi-database-search-outline</v-icon></v-list-item-icon>
																<v-list-item-title>مشاهده اطلاعات DNS</v-list-item-title>
															</v-list-item>
															<v-list-item link :to="`transfer?domain=${item.domain}.${item.name}`" target="_blank">
																<v-list-item-icon class="ml-4"><v-icon>mdi-transfer</v-icon></v-list-item-icon>
																<v-list-item-title>انتقال این دامنه به وی ثبت</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-card>
												</v-menu>
											</v-btn>
										</div>
									</v-fade-transition>
									<v-fade-transition hide-on-leave mode="out-in">
										<v-btn v-if="item.valid && item.availability==='available' && !item.inCart" color="secondary"
										       @click="openRegistrarSelectionDialog(item, 'register', index)"
										       text rounded depressed  class="mr-2"
										       :loading="isLoadingInProgress.cart && addToCartLoaderIndex===index">
											<v-icon left>mdi-plus-outline</v-icon>ثبت دامنه
										</v-btn>
									</v-fade-transition>
									<v-fade-transition hide-on-leave mode="out-in">
										<v-btn v-if="item.valid && item.availability==='available' && item.inCart" color="error"
										       @click="cartRemover(item, 'register', index)"
										       text rounded depressed  class="mr-2"
										       :loading="isLoadingInProgress.cart && removeFromCartLoaderIndex===index">
											<v-icon left>mdi-cart-minus</v-icon>حذف از سبد خرید
										</v-btn>
									</v-fade-transition>
									<v-fade-transition hide-on-leave mode="out-in">
										<v-btn v-if="item.valid && item.availability==='unknown'" color="grey"
										       :id="`${item.name}_reason`" text rounded depressed class="mr-2"
										       @click="retry({name: item.domain, extensions:[item.name]})">
											<v-icon left>mdi-refresh</v-icon>تلاش مجدد
										</v-btn>
										<!--<v-tooltip v-if="item.valid && item.availability==='unknown'" color="warning" :activator="`#${item.name}_reason`" top><span>مشاهده اطلاعات DNS</span></v-tooltip>-->

									</v-fade-transition>
								</td>
							</tr>
						</template>
					</v-data-table>
					<v-card-actions class="pa-6">
						<v-row>
							<v-col cols="12" md="6" lg="3" order="3" order-lg="1" class="d-flex flex-row align-center">
								<div class="footer-opt-label">تعداد در صفحه</div>
								<v-select v-model="tableOpts.footer.itemsPerPage" hide-details dense  append-icon="mdi-chevron-down"
								          background-color="grey lighten-5" color="secondary" class="footer-opt-select"
								          :disabled="isLoadingInProgress.search" item-color="secondary"
								          rounded outlined :items="tableOpts.footer.itemsPerPageOptions">
								</v-select>
							</v-col>
							<v-col cols="12" md="6" lg="6" order="2" order-sm="1" order-lg="2" class="d-flex justify-center align-center">
								<div class="d-none d-sm-flex">
									<v-pagination color="secondary" v-model="tableOpts.footer.page" class="bottom-pagination"
									              :length="tableOpts.footer.pageCount" circle total-visible="7">
									</v-pagination>
								</div>
							</v-col>
							<v-col cols="12" lg="3" order="1" order-sm="2" class="d-sm-none">
								<div class="d-flex d-sm-none align-center justify-space-around">
									<v-btn rounded depressed color="secondary"
									       @click="tableOpts.footer.page = (tableOpts.footer.page-1===0)?tableOpts.footer.pageCount:tableOpts.footer.page-1">
										<v-icon left>mdi-chevron-right</v-icon>صفحه قبل
									</v-btn>
									<v-btn color="secondary" rounded outlined :ripple="false">{{ tableOpts.footer.page }}</v-btn>
									<v-btn rounded depressed color="secondary"
									       @click="tableOpts.footer.page = (tableOpts.footer.page % tableOpts.footer.pageCount)+1">
										صفحه بعد<v-icon right>mdi-chevron-left</v-icon>
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-card-actions>
				</v-card>
				<v-dialog v-model="registrarSelectionDialog.show" class="dialog-wrapper" persistent scrollable
				          max-width="600px" activator="#reportBetterPriceBtn" retain-focus>
					<v-card rounded="xl" elevation="5" class="overflow-hidden"
					        :loading="isLoadingInProgress.registrars || isLoadingInProgress.cart">
						<template slot="progress">
							<v-progress-linear color="secondary" absolute height="4" indeterminate reversed></v-progress-linear>
						</template>
						<v-card-title class="d-flex align-center justify-space-between">
							<div class="dialog-title">
								<v-icon color="secondary" right>mdi-format-list-checks</v-icon>
								{{ registrarSelectionDialog.title }}
							</div>
							<v-btn color="grey darken-1" rounded large plain icon
							       @click="closeRegistrarSelectionDialog">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text class="pt-6">
							<v-row>
								<v-col cols="12">
									<p class="text-justify px-1">
										برای ثبت دامنه مورد نظر شما ثبت کننده های زیر قابل انتخاب هستند. لطفاً پس از بررسی دقیق قیمت ها یکی از این گزینه ها را انتخاب نموده و سپس برای ادامه فرایند روی افزودن به سبد خرید کلیک کنید.
									</p>
								</v-col>
								<v-col cols="12" class="d-flex align-center pl-4 pr-3 mb-2">
									<div class="select-registrar-label"><v-icon small right>mdi-dots-grid</v-icon>لیست ثبت کننده ها</div>
								</v-col>
								<v-col cols="12" v-if="isLoadingInProgress.registrars" class="loading-result">
									<v-list>
										<v-list-item class="mx-0 py-8">
											<v-list-item-content>
												<v-list-item-content>
													<v-progress-circular indeterminate size="45" color="secondary"></v-progress-circular>
													<v-list-item-title class="text-center grey--text text--lighten-1 mt-3">در حال دریافت اطلاعات</v-list-item-title>
												</v-list-item-content>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-col>
								<v-col cols="12" v-if="!!!registrarSelectionDialog.registersList.length && !isLoadingInProgress.registrars" class="no-result">
									<v-list>
										<v-list-item class="ma-8">
											<v-list-item-content>
												<v-avatar size="100"><v-icon color="grey lighten-2" size="100">mdi-check-circle-outline</v-icon></v-avatar>
												<v-list-item-title class="text-center grey--text text--lighten-1 mt-4">ثبت کننده ای یافت نشد</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-col>
								<v-col cols="12" v-if="!!registrarSelectionDialog.registersList.length && !isLoadingInProgress.registrars">
									<v-form ref="registrarSelection" lazy-validation @submit,prevent="">
										<v-item-group v-model="registrarSelectionDialog.selectedRegistrar">
											<v-row>
												<v-col v-for="(registrar, i) in registrarSelectionDialog.registersList"
												       :key="i" cols="12" class="pb-0">
													<v-item v-slot="{ active, toggle }" :value="registrar" :disabled="isLoadingInProgress.cart">
														<v-card rounded="xl" flat class="registrar-item pa-4 overflow-hidden"
														        :class="{'selected': active}" :ripple="{ class: 'secondary--text' }"
														        :disabled="isLoadingInProgress.cart" @click="toggle">
															<div class="registrar-content d-flex flex-column flex-sm-row justify-sm-space-between">
																<div class="registrar-logo d-flex justify-center justify-md-start flex-grow-0">
																	<v-badge right overlap color="secondary" icon="mdi-star-four-points" avatar bordered :value="registrar.suggested">
																		<v-avatar size="45">
																			<v-img :src="registrar.logo" :lazy-src="registrar.logo" contain></v-img>
																		</v-avatar>
																	</v-badge>
																</div>
																<div class="registrar_details d-flex flex-column pr-3 flex-sm-row align-sm-center justify-sm-space-between flex-grow-1 pr-sm-5">
																	<div class="registrar-name align-self-center text-center text-sm-start flex-grow-0 pl-3 pl-sm-0 my-3" :class="{'selected': active}">{{ registrar.title }}</div>
																	<div class="registrar-prices d-flex justify-space-around flex-grow-1 pl-5">
																		<div v-for="(priceItem, pi) in registrar.priceList"
																		     :key="`${priceItem.name}_${pi}`" class="d-flex justify-space-around flex-grow-1">
																			<div class="registrar-price" :class="{'best':priceItem.best}">
																				<div class="registrar-price-label">
																					{{ priceItem.title }}
																				</div>
																				<div class="registrar-price-value" :class="currentCurrency.code.toLowerCase()">
																					{{ priceItem.value | formatNumber }}
																					<span v-if="currentCurrency.code.toLowerCase()==='usd'"> $</span>
																				</div>
																			</div>
																			<v-divider v-if="pi<registrar.priceList.length-1" vertical class="mx-3"></v-divider>
																		</div>
																	</div>
																</div>
															</div>
															<v-expand-x-transition>
																<div v-show="active" class="registrar-selected-badge">
																	<v-icon color="white">mdi-check</v-icon>
																</div>
															</v-expand-x-transition>
														</v-card>
													</v-item>
												</v-col>
											</v-row>
										</v-item-group>
									</v-form>
								</v-col>
							</v-row>
						</v-card-text>
						<v-card-actions class="pb-4" v-if="!!registrarSelectionDialog.registersList.length && !isLoadingInProgress.registrars">
							<v-btn color="secondary" rounded block x-large text class="px-5"
							       :loading="isLoadingInProgress.cart"
							       :disabled="isLoadingInProgress.cart || !registrarSelectionDialog.selectedRegistrar"
							       @click="cartAdder(  registrarSelectionDialog.item,
                                                       registrarSelectionDialog.selectedRegistrar,
                                                       registrarSelectionDialog.type,
                                                       registrarSelectionDialog.index
                                                       )">
								<v-icon left>mdi-cart-plus</v-icon>
								افزودن به سبد خرید
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	name: "SearchResultsSection",
	props:{
		domain:{type: String, default: ""},
		searchResults:{type: Array, default: []},
		filterAvailable:{type: Boolean, default: false},
		filterPrice:{type: Array, default: []},
		searchInitiated:{type: Boolean, default: false},
	},
	data () {
		return {
			addToCartLoaderIndex:-1,
			removeFromCartLoaderIndex:-1,
			registrarSelectionDialog: {
				show: false,
				index: -1,
				item: null,
				type: null,
				title: "انتخاب ثبت کننده",
				registersList: [],
				selectedRegistrar: null,
			},
			tableOpts:{
				searchTable: '',
				hoveredItem: false,
				footer:{
					page: 1,
					itemsPerPage: 10,
					pageCount: 0,
					itemsPerPageOptions: [{text:'10', value:10},{text:'25', value:25},{text:'50', value:50},{text:'100', value:100},]
				},
				headers: [
					{ text: 'دامنه', value: 'name', align: 'start', sortable: false},
					{ text: 'قابلیت ثبت', value: 'availability', align: 'center', sortable: false,
						filter: value =>{
							if (!this.filterAvailable) return true
							return (value === 'available' || !value)
						}
					},
					{ text: 'ثبت', value: 'register', align: 'center', sortable: false,
						filter: value =>{
							if (!this.filterPrice.length) return true
							let price = !!value.discountPrice?value.discountPrice:value.originalPrice
							return ( price >= this.filterPrice[0] && price<= this.filterPrice[1]) || value === false
						}
					},
					{ text: 'تمدید', value: 'renew', align: 'center', sortable: false },
					{ text: 'انتقال', value: 'transfer', align: 'center', sortable: false },
					{ text: 'عملیات', value: 'order', align: 'center', sortable: false },
				],
			},
			currentItems: [],
			tableData: [],
			message: {active: false, code: "", color: "", icon: "", text: ""},
		}
	},
	computed: {
		...mapGetters(["isLoadingInProgress", "notification", "currentCurrency"]),
	},
	methods:{
		...mapActions(["setCountingAttrs", "advancedSearchUpdate", "addToCart", "deleteCartItem", "isInCart", "fetchRegistrarList"]),
		checkAvailable: function (state) {
			if (state === "available") return ["قابل ثبت", "check-outline" ,"success"]
			else if (state === "unavailable") return ["غیر قابل ثبت", "close-outline", "error"]
			else return ["نامشخص", "help", "warning"]
		},
		checkInCart: function (){
			let that = this
			this.currentItems.forEach(async (item) => {
				that.tableData[that.tableData.indexOf(item)].inCart = await this.isInCart({sld: item.domain, tld:item.name, type:'register', callerID:'AdvSearch'})
			})
		},
		showingItems: function(values){
			this.currentItems = values
			this.checkInCart()
		},
		updateTableAttrs: function(){
			this.setCountingAttrs({
				pageNumber: this.tableOpts.footer.page,
				itemsPerPage: this.tableOpts.footer.itemsPerPage
			})
		},
		tableContentHandler: async function() {
			this.updateTableAttrs()
			let extensions = []
			this.currentItems.forEach(ext => {
				extensions.push(ext.name)
			})
			this.$emit("searchUpdate", {name:this.domain, extensions:extensions})
		},
		retry: async function(failedItem) {
			this.updateTableAttrs()
			this.$emit("searchRetry", failedItem)
		},
		cartAdder: async function (item, registrar, type, idx) {
			this.addToCartLoaderIndex = idx

			let formData = {
				register:{"privacy": false, "nameServers": null, "registrantContact": null, "administrativeContact": null, "technicalContact": null, "billingContact": null, "comment": null},
				transfer:{"transferCode": null, "comment": null},
				renew:{},
			}

			const loading = {
				register: {registrantContact: false, administrativeContact: false, technicalContact: false, billingContact: false, registrar: false},
				transfer: {},
				renew: {},
			}

			const rules = {
				register: {},
				transfer:{required: value => !!value || 'کد انتقال دامنه وارد نشده است',},
				renew: {},
			}

			const cartItem = {
				id: null,
				sld: item.domain,
				tld: item.name,
				cat: 'domain',
				type: type,
				status: 1,
				price: !!!item[type].discountPrice?item[type].originalPrice:item[type].discountPrice,
				loading: loading[type],
				rules: rules[type],
				editable: false,
				error: false,
				registrar: registrar,
				data: formData[type]
			}
			const result = await this.addToCart({item:cartItem, callerID:'AdvSearch'})
			if (result) this.closeRegistrarSelectionDialog()
			this.addToCartLoaderIndex = -1
		},
		cartRemover: async function (item, type, idx) {
			this.removeFromCartLoaderIndex = idx
			const cartItem = {
				sld: item.domain,
				tld: item.name,
				cat: 'domain',
				type: type,
			}
			const result = await this.deleteCartItem({item:cartItem, callerID:'AdvSearch'})
			this.checkInCart()
			this.removeFromCartLoaderIndex = -1
		},
		openRegistrarSelectionDialog: async function (item, type, idx) {
			this.registrarSelectionDialog.item = item
			this.registrarSelectionDialog.type = type
			this.registrarSelectionDialog.index = idx
			this.registrarSelectionDialog.title = `انتخاب ثبت کننده برای ${item.domain}.${item.name}`
			this.registrarSelectionDialog.show = true
			const result = await this.fetchRegistrarList({tld:item.name, type: type})
			this.registrarSelectionDialog.registersList = result?result:[]
			if (this.registrarSelectionDialog.registersList.length === 1) this.registrarSelectionDialog.selectedRegistrar = this.registrarSelectionDialog.registersList[0]
			else if (this.registrarSelectionDialog.registersList.length > 1) {
				const suggested = this.registrarSelectionDialog.registersList.filter(registrar => registrar.suggested)
				if (suggested.length === 1) this.registrarSelectionDialog.selectedRegistrar = suggested[0]
				else if (suggested.length > 1) this.registrarSelectionDialog.selectedRegistrar = suggested[0]
			}
		},
		closeRegistrarSelectionDialog: function () {
			this.registrarSelectionDialog.show = false
			setTimeout(() => {
				this.registrarSelectionDialog.registersList = []
				this.registrarSelectionDialog.item = null
				this.registrarSelectionDialog.type = null
				this.registrarSelectionDialog.index = -1
				this.registrarSelectionDialog.title = "انتخاب ثبت کننده"
				this.registrarSelectionDialog.selectedRegistrar = null
			}, 300)
		},
	},
	created(){
	},
	mounted(){
		this.tableData = this.searchResults
	},
	watch:{
		searchResults: {
			immediate: true,
			deep: true,
			handler: function(n, o) {
				this.tableData = this.searchResults
			}
		},
		searchInitiated: {
			immediate: true,
			deep: true,
			handler: function(n, o) {
				this.tableOpts.footer.page = 1
			}
		},
	}
}
</script>

<style scoped>
.domain-title{
	color: var(--c_gray_5) !important;
	font-weight: bold;
}
.table-header{
	font-weight: bold !important;
	font-size: 0.9rem;
	color: var(--c_gray_5);
	/*margin-left: 5px;*/
	vertical-align: middle;
}

.table-item{
	position: relative;
}

.table-cell.content-center{
	text-align: center;
	height: 80px;
}

.table-content-cell{
	font-weight: 300 !important;
	font-size: 1rem;
}

.cell-custom{
	background: linear-gradient(90deg,transparent, rgba(255, 152, 0, .1));
}

.dom{
	font-weight: bold !important;
	color: var(--c_gray_5) !important;
}

.ext-normal{
	font-weight: bold !important;
	color: var(--c_secondary) !important;
}

.ext-custom{
	font-weight: bold !important;
	color: var(--c_warning) !important;
}

.price{
	color: var(--c_gray_5) !important;
}

.price.irt:after{
	content: "";
	display: inline-block;
	background: url("~@/assets/images/svg/TOMAN.svg") no-repeat;
	background-size: contain;
	width: 17px;
	height: 17px;
	vertical-align: sub;
	margin-right: 5px;
}

.table-price-history-btn{
	position: absolute;
	right: -50px;
	z-index: 500;
}

.bottom-pagination{
	position: relative;
	/*display: block;*/
	width: 100%;
	height: auto;
	bottom: 0;
	z-index: 0;
}

.footer-opt-label{
	flex: 1 0 auto;
	margin-left: 10px;
	font-weight: normal;
	font-size: 0.875rem;
	color: rgba(0,0,0,0.6);
}

.footer-opt-select{
	flex: 1 1 auto;
}

.select-registrar-label {
	display: flex;
	width: 100%;
	align-items: center;
}

.select-registrar-label:after {
	content: '';
	flex: 1 1 auto;
	height: 2px;
	margin-right: 20px;
	border-bottom: 1px solid #efefef;
}

.registrar-item{
	position: relative;
	border: 1px solid #efefef !important;
	transition: all .3s ease-in-out !important;
}

.registrar-item.selected{
	border: 1px solid var(--c_secondary) !important;
}

.registrar-item:hover {
	filter: grayscale(0.3);
	border-color: transparent !important;
	box-shadow: rgba(0, 0, 0, .07) 0px 0px 30px 0px !important;
}

.registrar-item:focus:before {
	opacity: 0.01 !important;
}

.registrar-item.selected:hover {
	border: 1px solid var(--c_secondary) !important;
	box-shadow: rgba(8, 201, 195, .1) 0px 0px 30px 0px !important;
}

.registrar-selected-badge{
	position: absolute;
	top: 0;
	left: 0;
	width: 35px;
	height: 100%;
	background: var(--c_secondary);
	opacity: 0.8;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0 !important;
	z-index: 100;
}

.registrar-name{
	font-weight: bold;
	font-size: 0.9rem;
	color: var(--c_gray_5);
	width: 100px;
}

.registrar-name.selected{
	color: var(--c_secondary);
}

.registrar-prices{}

.registrar-price-label{
	text-align: center;
	font-size: 0.8rem;
	color: var(--c_gray_2);
	margin-bottom: 5px;
}

.registrar-price-value{
	text-align: center;
	font-size: 0.9rem;
	color: var(--c_gray_3);
}

.registrar-price.best .registrar-price-value{
	font-weight: bold;
	color: var(--c_secondary);
}

.registrar-price-value.irt:after{
	content: "";
	display: inline-block;
	background: url("~@/assets/images/svg/TOMAN.svg") no-repeat;
	background-size: contain;
	width: 17px;
	height: 17px;
	vertical-align: sub;
	margin-right: 1px;
}

</style>


