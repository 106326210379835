<template>
        <v-container tag="section" id="counters">
            <v-row>
                <v-col cols="6" lg="3" v-for="counter in counterData" :key="counter.id">
                    <SingleCounterWidget class="counter" :end="counter.count" duration="5000"/>
                    <div class="counter-title">{{ counter.title }}</div>
                </v-col>
            </v-row>
        </v-container>
</template>

<script>
    import SingleCounterWidget from "@/components/SingleCounterWidget";

    export default {
        name: "FooterCounterWidget",
        components: {
            SingleCounterWidget
        },
        props: {
            counterData:{
                type: Array,
                default: () => []
            }
        }
    }
</script>

<style>
    section#counters{
        display: block;
        position: relative;
        margin-bottom: 40px;
    }

    section#counters:after{
        content: "";
        display: block;
        position: relative;
        border-bottom: 1px solid rgba(255,255,255, 0.8);
        mix-blend-mode: overlay;
        margin-top: 40px;
    }

    section#counters .counter-title{
        display: block;
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 500;
        font-size: 1.2rem;
        mix-blend-mode: overlay;
    }
    section#counters .counter{
        display: block;
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 800;
        font-size: 2.4rem;
    }
</style>
