import {breaking} from "vuetify/lib/util/console";

const state = {
    domains: [],
    advSearchResults: [],
    updateTime: "در حال بارگذاری",
    extPrices: [],
    countingAttrs:{
        pageNumber: 1,
        itemsPerPage: 10
    }
};

const getters = {
    extensionPrices: (state) => (state.extPrices),
    lastUpdateTime: (state) => (state.updateTime),
    searchResults: (state) => (state.domains),
    advancedSearchResults: (state) => (state.advSearchResults),
    getCountingAttrs: (state) => (state.countingAttrs)
};

const actions = {
    searchName: async ({ commit, dispatch }, domain) => {
        const res = [
            {id: 1, name: domain, extension: 'com', isAvailable: true, register: {originalPrice: 427000, discountPrice: null, badge: null}},
            {id: 2, name: domain, extension: 'net', isAvailable: true, register: {originalPrice: 427000, discountPrice: null, badge: 'ویژه'}},
            {id: 3, name: domain, extension: 'org', isAvailable: true, register: {originalPrice: 327000, discountPrice: 300000, badge: null}},
            {id: 4, name: domain, extension: 'info', isAvailable: true, register: {originalPrice: 240000, discountPrice: 235000, badge: null}},
        ]
        commit("_updateDomainSearchResults", res)
    },
    advancedSearch: async ({ commit, dispatch, state }, searchData) => {
        const page = state.countingAttrs.pageNumber
        const count = state.countingAttrs.itemsPerPage
        let extensions = searchData.extensions.slice((page-1)*count, page*count)
        let Rebuild = false

        if (state.advSearchResults.length === 0) {Rebuild = true}
        else if (searchData.name !== state.advSearchResults[0].domain){Rebuild = true}
        else{
            let itr = 0
            for (const ext of state.advSearchResults) {
                if(searchData.extensions[itr] !== ext.name){
                    Rebuild = true
                    break
                }
            }
        }

        if (Rebuild){
            commit("_clearAdvancedSearchResults")
            let customCounter = 0
            searchData.extensions.forEach( ext => {
                for (const ep of state.extPrices) {
                    if (ep.name === ext) {
                        let tmp = {...ep}
                        tmp.availability = false
                        tmp.domain = searchData.name
                        tmp.valid = false
                        tmp.inCart = false
                        if (searchData.custom !== false){
                            tmp.isCustom = (searchData.custom - customCounter > 0)
                            customCounter++
                        }
                        commit("_addToAdvancedSearchResults", tmp)
                    }
                }
            })
        }

        extensions.forEach(async function(ext){
            let url = `/domain/status?domain=${searchData.name}.${ext}`
            const result = await dispatch('apiCaller', {url: url, data: {}, reqType:'GET', callerID:'search', authReq:true})
            if (result.code === 200){
                const item = {name:result.domain.fullTLD, domain:result.domain.name, availability:result.status}
                await commit("_updateAdvancedSearchResults",item)
            }
            else {
                const item = {name:ext, domain:searchData.name, availability:'unknown'}
                await commit("_updateAdvancedSearchResults",item)
            }
        })
    },
    advancedSearchUpdate: async ({ commit, dispatch, state }, searchData) => {
        state.advSearchResults.forEach(async function(ext){
            if (searchData.extensions.includes(ext.name) && !ext.valid){
                let url = `/domain/status?domain=${searchData.name}.${ext.name}`
                const result = await dispatch('apiCaller', {url: url, data: {}, reqType:'GET', callerID:'search', authReq:true})
                if (result.code === 200){
                    const item = {name:result.domain.fullTLD, domain:result.domain.name, availability:result.status}
                    await commit("_updateAdvancedSearchResults",item)
                }
                else {
                    const item = {name:ext.name, domain:searchData.name, availability:'unknown'}
                    await commit("_updateAdvancedSearchResults",item)
                }
            }
        })
    },
    advancedSearchRetry: async ({ commit, dispatch, state }, searchData) => {
        state.advSearchResults.forEach(async function(ext){
            if (searchData.extensions.includes(ext.name)) {
                await commit("_retryMode", {ext: ext, active:true})
                let url = `/domain/status?domain=${searchData.name}.${ext.name}`
                const result = await dispatch('apiCaller', {url: url, data: {}, reqType:'GET', callerID:'search', authReq:true})
                if (result.code === 200){
                    const item = {name:result.domain.fullTLD, domain:result.domain.name, availability:result.status}
                    await commit("_updateAdvancedSearchResults",item)
                }
                await commit("_retryMode", {ext: ext, active:false})
            }
        })
    },
    getExtensionPrices: async ({ commit, dispatch }, currency) => {
        const url = `/search/prices/all?base=${currency}`
        const result = await dispatch('apiCaller', {url: url, data: {}, reqType:'GET', callerID:'search', authReq:true})
        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'search'})
            } else {
                commit("_setUpdateTime", result.last)
                commit("_updateExtensionPrices", result.data)
                return true
            }
        }
        return false
    },
    setCountingAttrs: ({ commit }, opts) => {
        commit("_updateCountingAttrs", opts)
    }
};

const mutations = {
    _setUpdateTime: (state, time) => (state.updateTime = time),
    _updateExtensionPrices: (state, results) => (state.extPrices = results),
    _updateDomainSearchResults: (state, results) => (state.domains = results),
    _updateCountingAttrs: (state, opts) => (state.countingAttrs = opts),
    _addToAdvancedSearchResults: (state, item) => (state.advSearchResults.push(item)),
    _clearAdvancedSearchResults: (state, item) => (state.advSearchResults = []),
    _updateAdvancedSearchResults: (state, newResult) => {
        state.advSearchResults.forEach(item => {
            if(item.name === newResult.name && !item.valid){
                item.availability = newResult.availability
                item.domain = newResult.domain
                item.valid = true
            }
        })
        return state.advSearchResults
    },
    _setAdvSearchItemCartStatus: (state, info) => {
        let breaker = {}
        try {
            state.advSearchResults.forEach(item => {
                if(item.name === info.tld && item.domain === info.sld){
                    item.inCart = info.status
                    throw breaker
                }
            })
        } catch (e){
            //pass
        }

        return state.advSearchResults
    },
    _removeAllAdvSearchItemsFromCart: (state) => {
        //TODO: NOT EFFICIENT .... USE FILTER INSTEAD OF FOREACH
        state.advSearchResults.forEach(item => {item.inCart = false})
        return state.advSearchResults
    },
    _retryMode: (state, failedItem) => {
        state.advSearchResults.forEach(item => {
            if(item.name === failedItem.ext.name){
                item.valid = !failedItem.active
            }
        })
        return state.advSearchResults
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
