import { render, staticRenderFns } from "./SearchResultsSection.vue?vue&type=template&id=d33c41a8&scoped=true"
import script from "./SearchResultsSection.vue?vue&type=script&lang=js"
export * from "./SearchResultsSection.vue?vue&type=script&lang=js"
import style0 from "./SearchResultsSection.vue?vue&type=style&index=0&id=d33c41a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d33c41a8",
  null
  
)

export default component.exports