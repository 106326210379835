<template>
	<!--TODO: SET MENU ROUTE LINKS WITH NAME PROPERTY-->
	<v-navigation-drawer v-model="openDrawer" fixed temporary right width="300" @input="updateDrawerStatus">
		<v-list v-if="loginStatus" class="mt-5">
			<v-list-item class="d-flex justify-center" :to="{name: 'AccountSettings'}" :ripple="false">
				<v-avatar size="100">
					<v-img :src="userInfo.avatar" :lazy-src="userInfo.avatar">
						<template v-slot:placeholder>
							<v-row class="fill-height ma-0" align="center" justify="center">
								<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
							</v-row>
						</template>
					</v-img>
				</v-avatar>
			</v-list-item>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-center"><h3>{{ userInfo.firstName }} {{ userInfo.lastName }}</h3></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item class="d-flex justify-center">
				<v-chip class="px-6" color="grey lighten-2" small>
					<v-icon left small color="secondary">mdi-wallet-plus-outline</v-icon>
					اعتبار:
					<span class="balance-amount" :class="currentCurrency.code.toLowerCase()">
						{{ userInfo.balance | formatNumber }}
						<span v-if="currentCurrency.code.toLowerCase()==='usd'" class="mr-1">$</span>
					</span>
				</v-chip>
			</v-list-item>
			<v-list-item class="d-flex justify-space-around mt-5">
				<v-btn icon large text color="grey" link class="grey lighten-4" :to="{name: 'Dashboard'}"><v-icon>mdi-view-dashboard-outline</v-icon></v-btn>
				<v-btn icon large text color="grey" link class="grey lighten-4" :to="{name: 'DomainManagement'}"><v-icon>mdi-web</v-icon></v-btn>
				<v-btn icon large text color="grey" link class="grey lighten-4" :to="{name: 'FinanceManagement'}"><v-icon>mdi-currency-usd</v-icon></v-btn>
				<v-badge color="secondary" :value="langList.length && loginStatus"
				         :content="currentLang.code.toUpperCase()" overlap left offset-x="20">
					<v-btn  icon large text :class="showLangMenu ? 'secondary lighten-5':'grey lighten-4'"
					        :color="showLangMenu ? 'secondary':'grey'" @click="triggerLangMenu">
						<v-icon>mdi-earth</v-icon>
					</v-btn>
				</v-badge>
				<v-badge color="error" :value="userMessages.unread && loginStatus" :content="userMessages.unread" overlap right>
					<v-btn icon large text :class="showAnnouncements ? 'secondary lighten-5':'grey lighten-4'"
					       :color="showAnnouncements ? 'secondary':'grey'"
					       @click.prevent="triggerAnnouncements">
						<v-icon>mdi-bell-outline</v-icon></v-btn>
				</v-badge>
			</v-list-item>
			<v-divider class="ma-3"></v-divider>
		</v-list>
		<v-scroll-x-reverse-transition mode="out-in" hide-on-leave>
			<div v-if="!showAnnouncements && !showLangMenu" class="drawer-main-menu-wrapper px-3"
			     :class="loginStatus?'pt-0':'pt-4'">
				<v-list rounded v-for="item in mainMenu" :key="item.title" >
					<v-list-item v-if="!item.submenu" :to="'/'+item.target" link>
						<v-list-item-icon class="ml-4"><v-icon>mdi-{{ item.icon }}</v-icon></v-list-item-icon>
						<v-list-item-content><v-list-item-title class="list-item-title">{{ item.title }}</v-list-item-title></v-list-item-content>
					</v-list-item>
					<v-list-group v-if="item.submenu" :key="item.title" :value="false" :prepend-icon="'mdi-'+item.icon" color="secondary">
						<template v-slot:activator>
							<v-list-item-title class="list-item-title">{{ item.title }}</v-list-item-title>
						</template>
						<v-list-item  class="" v-for="subItem in item.submenu" color="grey" :key="subItem.id" dense
						              :to="subItem.relative?'/'+item.target+'/'+subItem.target:'/'+subItem.target" link>
							<v-list-item-icon class="ml-4"><v-icon>mdi-{{ subItem.icon }}</v-icon></v-list-item-icon>
							<v-list-item-content><v-list-item-title>{{ subItem.title }}</v-list-item-title></v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</div>
		</v-scroll-x-reverse-transition>
		<v-scroll-x-reverse-transition mode="out-in" hide-on-leave>
			<div v-if="!showAnnouncements && showLangMenu" class="drawer-language-list-wrapper">
				<LocaleSettingsForm />
			</div>
		</v-scroll-x-reverse-transition>
		<v-scroll-x-reverse-transition mode="out-in" hide-on-leave>
			<v-list two-line v-if="showAnnouncements && !showLangMenu">
				<v-list-item v-if="userMessages.count" v-for="item in userMessages.items.slice(0, 5)" :key="item.id"
				             link :to="item.target" @click="openMessage(item.id)">
					<v-list-item-avatar class="d-flex justify-center"><v-icon :color="item.read?'grey':'secondary'">mdi-{{ item.read ? 'bell-outline':'bell'}}</v-icon></v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
						<v-list-item-subtitle class="mt-2 d-flex align-center"><v-icon size="18" right>mdi-clock-outline</v-icon>{{ item.date }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="!userMessages.count">
					<v-list-item-content>
						<v-list-item-content>
							<v-avatar><v-icon color="grey" x-large>mdi-bell-check-outline</v-icon></v-avatar>
							<v-list-item-title class="text-center grey--text mt-4">اطلاعیه جدیدی ندارید</v-list-item-title>
						</v-list-item-content>
					</v-list-item-content>
				</v-list-item>
				<v-btn v-if="userMessages.count && (userMessages.unread > 5)" height="50"   block text tile color="secondary"
				       link :to="{name: 'Announcements', params: {activate: false}}">
					مشاهده {{ userMessages.unread-5 }} اطلاعیه خوانده نشده دیگر
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
			</v-list>
		</v-scroll-x-reverse-transition>
		<template v-if="!loginStatus" v-slot:append>
			<div class="pa-2">
				<v-btn x-large block text rounded color="secondary" link :to="{name: 'Login'}">
					<v-icon left size="25">mdi-login-variant</v-icon>ورود/ ثبت نام</v-btn>
			</div>
		</template>
		<template v-else v-slot:append>
			<div class="pa-2">
				<v-btn x-large block text rounded color="grey" link @click="doLogout"
				       :disabled="isLoadingInProgress.auth" :loading="isLoadingInProgress.auth">
					<v-icon left size="25">mdi-logout-variant</v-icon>خروج از حساب</v-btn>
			</div>
		</template>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LocaleSettingsForm from "@/components/LocaleSettingsForm.vue";

export default {
	name: "MainNavigationDrawer",
	components: {LocaleSettingsForm},
	props:{
		drawer:{
			type: Boolean,
			default: false,
		},
	},
	data(){
		return{
			openDrawer: this.drawer,
			showAnnouncements: false,
			showLangMenu: false,
		}
	},
	watch: {
		drawer: function (n,o) {
			this.openDrawer = n;
			setTimeout(() => {
				if (!n) {
					this.showAnnouncements = false
					this.showLangMenu = false
				}
			}, 300)
		},
	},
	computed: mapGetters(["userInfo", "loginStatus", "userMessages", "mainMenu",
		"isLoadingInProgress", "currentLang", "langList", "currentCurrency"]),
	methods: {
		...mapActions(["getStatus", "markMessageAsRead", "setGlobalLanguage", "logOut"]),
		triggerAnnouncements: function () {
			this.showAnnouncements = !this.showAnnouncements
			this.showLangMenu = false
		},
		triggerLangMenu: function () {
			this.showLangMenu = !this.showLangMenu
			this.showAnnouncements = false
		},
		openMessage: function (id){
			// this.markMessageAsRead(id)
			this.$router.push({name:'Announcement', params:{id: id}})
		},
		updateDrawerStatus: function (e){
			this.$emit('updateDrawerStatus', e)
		},
		doLogout: async function(){
			const res = await this.logOut()
			const that = this
			if (res){
				this.updateDrawerStatus(false)
				setTimeout(function(){
					that.showAnnouncements = false
					that.showLangMenu = false
				}, 100)
			}
		}
	},
	mounted(){
		this.getStatus()
	},
}
</script>

<style scoped>
.list-item-title{
	font-weight: 700 !important;
}

.balance-amount{
	margin-right: 5px;
}

.balance-amount.irt:after{
	content: "";
	display: inline-block;
	background: url("~@/assets/images/svg/TOMAN.svg") no-repeat;
	background-size: contain;
	width: 14px;
	height: 14px;
	vertical-align: sub;
	margin-right: 5px;
	mix-blend-mode: difference;
}
</style>
