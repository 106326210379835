<template>
    <div>
        <div class="search-header-wrapper"></div>
        <v-container tag="section" class="fill-height">
            <v-row>
                <v-col cols="12">
                    <div class="search-header-title">جستجوی دامنه</div>
                </v-col>
                <v-col cols="12">
                    <div class="search-header-search-area">
                        <v-card rounded="xl" elevation="5" class="domain-search-area overflow-hidden"
                                :loading="isLoadingInProgress.search" ref="introSearchArea">
                            <template slot="progress">
                                <v-progress-linear  color="secondary" absolute height="4" indeterminate reversed></v-progress-linear>
                            </template>
                            <v-fade-transition>
                                <v-overlay v-if="isLoadingInProgress.search" absolute color="#ffffff">
                                    <v-progress-circular indeterminate size="64" color="secondary"></v-progress-circular>
                                </v-overlay>
                            </v-fade-transition>
                            <v-form class="px-5 pt-5" id="searchForm" ref="search" @submit.prevent="searchDomain" lazy-validation >
                                <v-text-field label="دامنه مورد نظر شما" rounded outlined height="60" ref="searchField" :disabled="isLoadingInProgress.search"
                                              @keyup="clearSearch" :rules="[rules.required, rules.url]" @blur="$refs.search.resetValidation()"
                                              color="secondary" background-color="grey lighten-4" v-model="domain"
                                              hint="دامنه خود را به صورت example یا example.com بدون www وارد کنید (حداقل 3 کاراکتر)">
                                    <template  v-slot:append>
                                        <v-btn class="intro-search-btn px-lg-10" :loading="isLoadingInProgress.search" @click="searchDomain"
                                               color="secondary" text rounded height="60">
                                            <v-icon left>mdi-magnify</v-icon>
                                            جستجو
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-form>
                            <v-card-actions class="search-filter-area-bottom">
                                <v-row class="search-filters-wrapper">
                                    <v-col cols="12" lg="8" order="2" order-lg="1"  class="search-options-wrapper d-md-flex flex-row justify-center justify-lg-start">
                                        <div class="search-option d-none d-md-block">
                                            <v-switch class="mt-0" color="secondary" hide-details label="فقط قابل ثبت"
                                                      @change="filterAvailable"
                                                      v-model="searchOpts.onlyAvailable"></v-switch>
                                        </div>
                                        <div class="search-option">
                                            <v-btn block :color="extSelectionMenu? 'secondary':'rgba(0,0,0,0.6)'" large text rounded @click="toggleExtSelector">
                                                انتخاب پسوند
                                                <v-chip x-small class="mr-2" color="secondary" pill v-show="searchOpts.selectedExtensions.length">
                                                    <span class="ml-2">{{ searchOpts.selectedExtensions.length }}</span>
                                                    مورد
                                                </v-chip>
                                                <v-icon right>mdi-chevron-{{ extSelectionMenu?'up':'down' }}</v-icon>
                                            </v-btn>
                                        </div>
                                        <div class="search-option">
                                            <v-btn block :color="priceRangeMenu? 'secondary':'rgba(0,0,0,0.6)'" large text rounded @click="togglePriceRangeSelector">
                                                محدوده قیمت
                                                <v-chip x-small class="mr-2" color="grey lighten-3" pill v-show="searchOpts.priceRange.range[0] !== searchOpts.priceRange.min ||searchOpts.priceRange.range[1] !== searchOpts.priceRange.max">
                                                    <span class="price-range-content">{{ searchOpts.priceRange.range[0] | formatNumber }}</span>
                                                    <span class="mx-4">تا</span>
                                                    <span class="price-range-content">{{ searchOpts.priceRange.range[1] |formatNumber }}</span>
                                                </v-chip>
                                                <v-icon right>mdi-chevron-{{ priceRangeMenu?'up':'down' }}</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="4" order="1" order-lg="2" class="search-options-wrapper-sorting justify-center justify-lg-end ">
                                        <span class="sorting-title">ترتیب:</span>
                                        <v-chip-group v-model="searchOpts.sortResults" @change="onSort"
                                                      mandatory active-class="secondary--text">
                                            <v-chip small value="bestSell">پر فروش ترین</v-chip>
                                            <v-chip small value="leastExpensive">ارزان ترین</v-chip>
                                            <v-chip small value="mostExpensive">گران ترین</v-chip>
                                        </v-chip-group>
                                    </v-col>
                                </v-row>
                                <v-expand-transition mode="out-in">
                                    <v-row v-show="priceRangeMenu" class="search-option-content price-range-wrapper">
                                        <v-col cols="6" lg="2" order="1" order-lg="1" class="text-center my-4">
                                            <span class="price-range-title">حداقل: </span>
                                            <span class="price-range-content">{{ searchOpts.priceRange.range[0] |formatNumber }}</span>
                                        </v-col>
                                        <v-col cols="12" lg="8" order="3" order-lg="2" class="my-4">
                                            <v-range-slider @change="filterPrice"
                                                            v-model="searchOpts.priceRange.range"
                                                            :max="searchOpts.priceRange.max"
                                                            :min="searchOpts.priceRange.min" :step="searchOpts.priceRange.step"
                                                            color="secondary" track-color="grey lighten-2" track-fill-color="secondary"
                                                            hide-details>
                                            </v-range-slider>
                                        </v-col>
                                        <v-col cols="6" lg="2" order="2" order-lg="3" class="text-center my-4">
                                            <span class="price-range-title">حداکثر: </span>
                                            <span class="price-range-content">{{ searchOpts.priceRange.range[1] | formatNumber }}</span>
                                        </v-col>
                                    </v-row>
                                </v-expand-transition>
                                <v-expand-transition mode="out-in">
                                    <v-row v-show="extSelectionMenu" class="search-option-content">
                                        <v-col cols="12" class="my-4">
                                            <v-row>
                                                <v-col cols="9" class="d-none d-lg-flex align-center">
                                                    <v-combobox v-model="searchOpts.selectedExtensions" background-color="grey lighten-3"
                                                                placeholder="هیج پسوندی انتخاب نشده است" hide-details color="secondary" readonly
                                                                chips clearable multiple filled rounded append-icon="" @update="alert('changed')"
                                                                @click:clear="clearAllSelectedExt">
                                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                                            <v-chip small v-bind="attrs" :input-value="selected" close close-icon="mdi-close"
                                                                    @click="select" @click:close="removeSelectedExt(item)">
                                                                {{ item }}
                                                            </v-chip>
                                                        </template>
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="6" lg="3" class="d-flex align-center">
                                                    <v-text-field v-model="extSearchStr" @input="filterShownExtensions"
                                                                  background-color="grey lighten-3" color="secondary"
                                                                  placeholder="جستجو"  filled rounded hide-details
                                                                  append-icon="mdi-magnify"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" lg="2" class="d-flex align-center d-lg-none">
                                                    <v-btn text x-large block rounded color="error" :disabled="searchOpts.selectedExtensions.length===0"
                                                           @click="clearAllSelectedExt">
                                                        <v-icon left>mdi-close-circle-multiple-outline</v-icon>
                                                        لغو انتخاب ها
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-btn-toggle v-model="searchOpts.tempSelectedExtensions" multiple
                                                                  @change="syncExtensionLists"
                                                                  color="secondary" background-color="transparent"
                                                                  active-class="secondary--text"
                                                                  class="ext-selection-group-wrapper">
                                                        <v-row>
                                                            <v-col v-for="ext in shownExtensions" :key="ext.value"
                                                                   :key.sync="ext.value" cols="6" sm="4" md="3" lg="2">
                                                                <v-btn block text rounded :value="ext.value"
                                                                       @click="toggleExtensionSelection(ext.value)">
                                                                    {{ ext.text }}
                                                                    <v-icon right :color="ext.selected?'secondary':'grey darken-1'">mdi-{{ext.selected?'check':'plus'}}</v-icon>
                                                                </v-btn>
                                                            </v-col>

                                                        </v-row>
                                                    </v-btn-toggle>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-expand-transition>
                            </v-card-actions>
                        </v-card>

                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="extNotFound" class="dialog-wrapper" @click:outside="extNotFound=false" @close="extNotFound=false"
                  max-width="600px" activator="#itemPriceHistoryBtn" retain-focus>
            <v-card rounded="xl" elevation="5" class="overflow-hidden">
                <v-card-title class="d-flex align-center justify-end pt-10">
                    <v-btn color="grey darken-1" rounded large plain icon @click="extNotFound=false" absolute>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="no-result">
                    <v-list>
                        <v-list-item class="ma-0">
                                <v-list-item-content>
                                    <v-avatar size="100"><v-icon color="warning" size="100">mdi-progress-alert</v-icon></v-avatar>
                                    <v-list-item-title class="text-center warning--text alert-title">پسوند مورد نظر وجود ندارد</v-list-item-title>
                                    <v-list-item-subtitle class="text-center grey--text alert-desc">
                                        شما میتوانید از منوی انتخاب پسوند پسوندهای معتبر را مشاهده و انتخاب نمایید
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from  'vuex';
    import verificationMixin from "@/mixins/verificationMixin";

    export default {
        name: "SearchHeaderSection",
        mixins:[verificationMixin],
        data(){
            return{
                rules: {
                    required: value => !!value || 'دامنه مورد نظر را وارد کنید',
                    url: value => !!this.validateUrl(value) || 'دامنه وارد شده معتبر نیست',
                },
                domain: '',
                priceRangeMenu: false,
                extSelectionMenu: false,
                shownExtensions: [],
                extSearchStr: "",
                extNotFound: false,
                searchOpts:{
                    onlyAvailable: false,
                    priceRange:{
                        max: 200000000,
                        min: 20000,
                        range: [20000, 200000000],
                        step: 100000,
                    },
                    allDomains: [],
                    selectedExtensions:[],
                    tempSelectedExtensions:[],
                    validSorts: ["bestSell", "leastExpensive", "mostExpensive"],
                    sortResults: 'bestSell'
                },
            }
        },
        computed: {
            ...mapGetters(["isLoadingInProgress", "extensionPrices", "getCountingAttrs"]),
            // extListLimit: function () {
            //     return this.shownExtensions
            // }
        },
        methods:{
            // ...mapActions([""]),
            searchDomain: async function() {
                if(this.$refs.search.validate()){

                    const parsedInput = this.parseUrl(this.domain)

                    if(typeof parsedInput.fullTLD !== 'undefined'){
                        for (const ext of this.searchOpts.allDomains) {
                            if(ext.text === parsedInput.fullTLD){
                                this.clearAllSelectedExt()
                                this.searchOpts.selectedExtensions.push(parsedInput.fullTLD)
                                this.searchOpts.tempSelectedExtensions.push(parsedInput.fullTLD)
                                break
                            }
                        }
                    }
                    this.domain = parsedInput.name

                    let extensions = []
                    let customSearch = false

                    let tempExtensions = [...this.searchOpts.allDomains]
                    if (this.searchOpts.sortResults === 'mostExpensive'){
                        tempExtensions.sort((a,b) => {
                            let priceA = !!a.register.discountPrice?a.register.discountPrice:a.register.originalPrice
                            let priceB = !!b.register.discountPrice?b.register.discountPrice:b.register.originalPrice
                            return (priceA<priceB)?1:-1
                        })
                    }
                    else if (this.searchOpts.sortResults === 'leastExpensive'){
                        tempExtensions.sort((a,b) => {
                            let priceA = !!a.register.discountPrice?a.register.discountPrice:a.register.originalPrice
                            let priceB = !!b.register.discountPrice?b.register.discountPrice:b.register.originalPrice
                            return (priceA>priceB)?1:-1
                        })
                    }

                    if (this.searchOpts.selectedExtensions.length){
                        tempExtensions.forEach(ext => {
                            if(this.searchOpts.selectedExtensions.includes(ext.text)){
                                extensions.push(ext.text)
                            }
                        })
                        customSearch = this.searchOpts.selectedExtensions.length
                    }

                    tempExtensions.forEach(ext => {
                        if(!extensions.includes(ext.text)){
                            extensions.push(ext.text)
                        }
                    })

                    let searchData = {
                        name: this.domain,
                        extensions: extensions,
                        custom: customSearch,
                        countingOpts:this.getCountingAttrs,
                    }

                    this.$emit("searchInit", searchData)

                    this.priceRangeMenu = false
                    this.extSelectionMenu = false
                }
            },
            clearSearch: function() {

            },
            selectExt: function(ext) {
                this.searchOpts.allDomains.forEach((domain, idx) => {
                    if (domain.text === ext){
                        this.searchOpts.selectedExtensions.push(ext)
                        this.searchOpts.tempSelectedExtensions.push(ext)
                        this.searchOpts.allDomains[idx].selected = true
                    }
                })
            },
            toggleExtensionSelection: function(ext) {
                this.searchOpts.allDomains.forEach((domain, idx) => {
                    if (domain.text === ext){
                        if (this.searchOpts.allDomains[idx].selected){
                            const index = this.searchOpts.selectedExtensions.indexOf(ext)
                            if (index >= 0) this.searchOpts.selectedExtensions.splice(index, 1)
                            this.searchOpts.allDomains[idx].selected = false
                        }else{
                            if (!this.searchOpts.selectedExtensions.includes(ext)){
                                this.searchOpts.selectedExtensions.push(ext)
                                this.searchOpts.allDomains[idx].selected = true
                            }
                        }

                    }
                })
            },
            removeSelectedExt: function(item) {
                const index = this.searchOpts.selectedExtensions.indexOf(item)
                if (index >= 0) this.searchOpts.selectedExtensions.splice(index, 1)
                if (index >= 0) this.searchOpts.tempSelectedExtensions.splice(index, 1)
                this.searchOpts.allDomains.forEach((domain, idx) => {
                    if (domain.text === item){
                        this.searchOpts.allDomains[idx].selected = false
                    }
                })
            },
            clearAllSelectedExt: function() {
                this.searchOpts.allDomains.forEach((domain, idx) => {
                    this.searchOpts.allDomains[idx].selected = false
                })
                this.searchOpts.selectedExtensions = []
                this.searchOpts.tempSelectedExtensions = []
            },
            updateSelectedExtensions: function() {
                this.searchOpts.allDomains.forEach((domain, idx) => {
                    if(this.searchOpts.tempSelectedExtensions.includes(domain.text)){
                        this.searchOpts.allDomains[idx].selected = true
                    }
                    if(this.searchOpts.selectedExtensions.includes(domain.text)){
                        this.searchOpts.allDomains[idx].selected = true
                    }
                })
            },
            togglePriceRangeSelector: function() {
                this.priceRangeMenu = !this.priceRangeMenu
                this.extSelectionMenu = false
            },
            toggleExtSelector: function() {
                this.extSelectionMenu = !this.extSelectionMenu
                this.priceRangeMenu = false
            },
            extensionExtractor: async function (extData) {
                let max = 0
                let min = 99999999999
                extData.forEach(ext => {
                    this.searchOpts.allDomains.push({
                        text: ext.name,
                        value: ext.name,
                        selected: false,
                        register: ext.register,
                        renew: ext.renew,
                        transfer: ext.transfer,
                    })
                    let price = !!ext.register.discountPrice?ext.register.discountPrice:ext.register.originalPrice
                    max = price>=max?price:max
                    min = price<=min?price:min
                })

                this.searchOpts.priceRange.max = max
                this.searchOpts.priceRange.min = min
                this.searchOpts.priceRange.range = [min, max]
                this.searchOpts.priceRange.step = (max - min) / 500
            },
            filterAvailable: function () {
                this.$emit("filterAvailable", this.searchOpts.onlyAvailable)
            },
            filterPrice: function () {
                this.$emit("filterPrice", this.searchOpts.priceRange.range)
            },
            onSort: function () {
                this.searchDomain()
            },
            filterShownExtensions: function () {
                if (this.extSearchStr.length) {
                    this.shownExtensions = [...this.searchOpts.allDomains.filter(item => item.text.startsWith(this.extSearchStr.toLowerCase()))]
                }
                else {
                    this.shownExtensions = [...this.searchOpts.allDomains.slice(0,24)]
                }
            },
            syncExtensionLists: function () {
                this.updateSelectedExtensions()
                for (const ext of this.searchOpts.allDomains){
                    if (ext.selected) {
                        if (!this.searchOpts.selectedExtensions.includes(ext.text)) {
                            this.searchOpts.selectedExtensions.push(ext.text)
                        }
                        if (!this.searchOpts.tempSelectedExtensions.includes(ext.text)) {
                            this.searchOpts.tempSelectedExtensions.push(ext.text)
                        }
                    }
                }
            }
        },
        async mounted(){
            await this.extensionExtractor(this.extensionPrices)
            this.filterShownExtensions()

            let query = this.getUrlParams(this)
            if (query.ext){
                this.selectExt(query.ext)
                if (this.searchOpts.selectedExtensions.includes(query.ext) ||
                    this.searchOpts.tempSelectedExtensions.includes(query.ext)){
                    this.extSelectionMenu = true
                } else{
                    this.extNotFound = true
                }
            }
            if (query.name){
                this.domain = query.name
            }
            if (query.onlyAvailable){
                this.searchOpts.onlyAvailable = (query.onlyAvailable === 'true')
            }
            if (query.sort){
                if (this.searchOpts.validSorts.includes(query.sort)){
                    this.searchOpts.sortResults = query.sort
                }
            }
            if (query.minPrice){
                let price = parseInt(query.minPrice)
                if(price <= this.searchOpts.priceRange.min || price > this.searchOpts.priceRange.max){
                    this.searchOpts.priceRange.range[0] = this.searchOpts.priceRange.min
                } else{
                    this.searchOpts.priceRange.range[0] = price
                }
            }
            if (query.maxPrice){
                let price = parseInt(query.maxPrice)
                if(price >= this.searchOpts.priceRange.max || price < this.searchOpts.priceRange.min){
                    this.searchOpts.priceRange.range[1] = this.searchOpts.priceRange.max
                } else{
                    this.searchOpts.priceRange.range[1] = price
                }
            }
            if (query.maxPrice && query.maxPrice){
                if(this.searchOpts.priceRange.range[1] < this.searchOpts.priceRange.range[0]){
                    let p = this.searchOpts.priceRange.range[0]
                    this.searchOpts.priceRange.range[0] = this.searchOpts.priceRange.range[1]
                    this.searchOpts.priceRange.range[1] = p
                }
            }
            if ((query.ext && query.name )|| query.name){
                setTimeout(async() => {
                    await this.searchDomain()
                }, 1000)
            }
        },
        watch: {
            searchOpts: {
                handler: function () {
                    this.updateSelectedExtensions()
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>
    .search-header-area{
        background-color: white;
        position: relative;
    }

    .search-header-wrapper{
        position: absolute;
        display: block;
        top: 0;
        left: auto;
        right: auto;
        width: 100%;
        height: 542px;
        background: url("~@/assets/images/svg/singlebg3.svg");
        background-repeat: no-repeat !important;
        background-position: center top !important;
        background-size: cover !important;
        z-index: 0;
    }
    .search-header-title{
        display: block;
        text-align: center;
        font-size: 4rem;
        font-weight: 800;
        color: white;
        mix-blend-mode: overlay;
        margin: 200px auto 100px;
    }

    .search-header-search-area{}

    .search-filter-area-bottom{
        display: block;
        position: relative;
        background: var(--c_gray_1);
        padding: 10px 0;
        transition: var(--transition);
    }

    .search-filters-wrapper{
        padding: 10px 30px;
    }

    .search-option-content{
        background: var(--c_gray_4);
        padding: 0 30px;
    }

    .price-range-wrapper{
        padding: 0 30px;
    }

    .price-range-title{
        font-weight: 300;
    }

    .price-range-content{
        font-weight: normal;
        color: var(--c_secondary);
    }

    .price-range-content.irt:after{
        content: "";
        display: inline-block;
        background: url('~@/assets/images/svg/TOMAN.svg') no-repeat;
        background-size: contain;
        width: 13px;
        height: 13px;
        vertical-align: sub;
        margin-right: 3px;
    }

    .search-options-wrapper{
        /*display: flex;*/
        /*flex-direction: row;*/
        /*justify-content: start;*/
        /*align-items: center;*/
    }

    .search-options-wrapper-sorting{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .search-option{
        flex: 0 1 auto;
        align-self: center;
        padding: 0 15px;
    }

    .search-option:not(:first-child){
        border-right: 1px solid var(--c_gray_4);
    }

    .search-option:first-child{
        padding-right: 0;
    }

    .sorting-title, .ext-selection-title{
        font-weight: normal;
        font-size: 0.875rem;
        color: rgba(0,0,0,0.6);
    }

    .ext-selection-group-wrapper{
        width: 100%;
        margin-top: 20px;
    }

    .no-result {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .alert-title{
        font-weight: 800;
        font-size: 1.2rem !important;
        margin-top: 24px;
    }

    .alert-desc{
        font-weight: 300;
        font-size: 1rem !important;
        margin-top: 20px;
    }

    @media (max-width: 600px) {
        .search-option:not(:first-child){
            border-right:  none !important;
        }
    }
    @media (min-width: 600px) and (max-width: 960px){
        .search-option:not(:first-child){
            border-right:  none !important;
        }

    }
    @media (min-width: 960px) and (max-width: 1264px){

    }
    @media (min-width: 1264px) and (max-width: 1904px){

    }
    @media (min-width: 1904px){

    }
</style>
