<template>
    <div>
        <HomeIntroSection />
        <HomeFeatureSection />
        <HomePricingSection />
        <HomeFAQSection />
        <HomeDevelopersSection />
        <HomeResellerSection />
        <HomeCustomersSection />
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import viewSetupsMixin from "@/mixins/viewSetupsMixin";
    import HomeIntroSection from "@/views/index/Home/HomeIntroSection";
    import HomeFeatureSection from "@/views/index/Home/HomeFeatureSection";
    import HomePricingSection from "@/views/index/Home/HomePricingSection";
    import HomeFAQSection from "@/views/index/Home/HomeFAQSection";
    import HomeDevelopersSection from "@/views/index/Home/HomeDevelopersSection";
    import HomeResellerSection from "@/views/index/Home/HomeResellerSection";
    import HomeCustomersSection from "@/views/index/Home/HomeCustomersSection";

    export default {
        title:"صفحه اصلی",
        name: "Home",
        components:{
            HomeIntroSection,
            HomeFeatureSection,
            HomePricingSection,
            HomeFAQSection,
            HomeDevelopersSection,
            HomeResellerSection,
            HomeCustomersSection,

        },
        props:{
            lastPrices:{
                type: Array,
                default: () => []
            },
            pricingTables: {
                type: Object,
                default: () => {}
            },
        },
        mixins: [viewSetupsMixin],
        methods: {
            ...mapActions(["configAppearance", "setLoadingScreen", "fixAppBar"])
        },
        mounted(){
            this.configAppearance('secondary')
            this.fixAppBar()
            window.onscroll = () => {
                this.fixAppBar()
            };
        }
    }
</script>

<style>
    main{}

    main > section{
        position: relative;
        display: block;
    }

    main > section h1.section-title{
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 800;
        margin-bottom: 40px;
        z-index: 10;
    }

    main > section p.section-description{
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 300;
        color: var(--c_gray_5);
        padding: 0 50px;
        line-height: 2rem;
        z-index: 10;
    }

    main > section a.section-link{
        position: relative;
        display: block;
        margin: 40px 50px 30px;
        padding: 18px 0;
        font-family: var(--font_text_1);
        font-weight: normal;
        color: white;
        border-radius: var(--border_radius);
        text-decoration: none;
        text-align: center;
        overflow: hidden;
        transition: var(--transition);
        z-index: 10;
    }

    main > section a.section-link i {
        position: absolute;
        font-size: 3.5rem;
        vertical-align: middle;
        margin-left: 7px;
        mix-blend-mode: overlay;
        opacity: 0.4;
        top: 2px;
        right: -60px;
        transition: var(--transition);
    }

    main > section a.section-link:hover i{
        right: 5px;
    }

    main > section.semi-section{
        /*margin-top: -200px;*/
        z-index: 3;
    }

    main > section.semi-section > .section-bg-1:before{
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 200vh;
        background: url("~@/assets/images/svg/71.svg") no-repeat;
        background-size: 100% auto;
        top: -250px;
        left: 0;
        right: 0;
    }

    main > section.semi-section > .section-bg-1 .particle-1 {
        content: "";
        position: absolute;
        display: block;
        width: 40vw;
        height: 100vh;
        background: url("~@/assets/images/svg/6.svg") no-repeat;
        background-size: contain;
        mix-blend-mode: overlay;
        top: 0;
        right: -220px;
    }

    main > section.semi-section > .section-bg-1 .particle-2 {
        content: "";
        position: absolute;
        display: block;
        width: 40vw;
        height: 100vh;
        background: url("~@/assets/images/svg/6.svg") no-repeat;
        background-size: contain;
        mix-blend-mode: overlay;
        top: 0;
        right: 39%;
    }

    main > section.semi-section > .section-bg-1 .particle-3 {
        content: "";
        position: absolute;
        display: block;
        width: 40vw;
        height: 100vh;
        background: url("~@/assets/images/svg/6.svg") no-repeat;
        background-size: contain;
        mix-blend-mode: overlay;
        top: 80vh;
        right: 53%;
        transform: scaleX(-1);
    }

    main > section.semi-section > .section-bg-2:before{
        content: "";
        position: absolute;
        display: block;
        width: 70%;
        height: 150vh;
        background: url("~@/assets/images/svg/2.svg") no-repeat;
        background-size: 100% auto;
        top: -500px;
        right: -10%;
    }

    main > section.semi-section > .section-bg-3:before {
        content: "";
        position: absolute;
        display: block;
        width: 70vw;
        height: 150vh;
        background: url("~@/assets/images/svg/2.svg") no-repeat;
        background-position-x: 450px;
        background-size: 100% auto;
        top: -460px;
        left: 0;
        transform: scaleX(-1);
    }
</style>
