import Vue from "vue";

const state = {
    globalSettings: {
        showLoadingScreen: true,
        language: {
            current: 'en',
            list: [
                {id:1, title:'فارسی', code:'FA', flag:'https://frf1.cdn.vsabt.com/flags/irn.svg', rtl:true, active:true},
                {id:2, title:'English', code:'EN', flag:'https://frf1.cdn.vsabt.com/flags/gbr.svg', rtl:false, active:true},
            ]
        },
        currency: {
            current: 'IRT',
            list: [
                {id:1, title:'تومان', code:'IRT', symbol:'تومان', active:true},
                {id:2, title:'دلار', code:'USD', symbol:'$', active:true},
            ]
        },
        appearance:{
            topBarColorMode: 'secondary',
            topBarFixStatus: false,
            topBarBg: "transparent",
        },
        menuItems: [
            {id:1, title:'خانه', icon:'home-outline', target:'', submenu:false},
            {id:3, title:'دامنه', icon:'web', target:'domain', submenu:[
                    {id:1, title:'تعرفه ها', icon:'chevron-left', target:'pricing', relative:true},
                    {id:2, title: 'جستجوی دامنه', icon:'chevron-left', target:'search', relative:true},
                    // {id:3, title: 'تمدید دامنه', icon:'chevron-left', target:'#', relative:true},
                    {id:4, title: 'انتقال دامنه', icon:'chevron-left', target:'transfer', relative:true},
                    {id:5, title: 'مشاهده WHOIS', icon:'chevron-left', target:'whois', relative:true},
                ]},
            {id:4, title:'درباره ما', icon:'information-outline', target:'about', submenu:false},
            {id:5, title:'تماس با ما', icon:'phone-outline', target:'contact', submenu:false},
        ],
        briefPrices:[],
        pricingTables:{cheapest:[], popular:[], special:[], suggestions:[]},
        faqItems: [
            {
                id: 1,
                question: "چطور دامنه جدید ثبت کنم؟",
                answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد."
            },
            {
                id: 2,
                question: "چطور دامنه ام را به وی ثبت منتقل کنم؟",
                answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد."
            },
            {
                id: 3,
                question: "خدای من! چرا قیمت های وی ثبت اینقدر پایین است؟",
                answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد."
            },
            {
                id: 4,
                question: "چطور باید هزینه خدمات وی ثبت را پرداخت کرد ؟",
                answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد."
            },
        ],
        customerComments: [
            {
                id:1,
                image:'https://randomuser.me/api/portraits/men/10.jpg',
                name:'امین حیایی',
                badge: {title:'مشتری ویژه', color:'secondary'},
                social: {icon:'twitter', color:'#2196F3', id:'@amiiin'},
                text: ' چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزا'
            },
            {
                id:2,
                image:'https://randomuser.me/api/portraits/men/20.jpg',
                name:'اکبر عبدی',
                badge: null,
                social: {icon:'linkedin', color:'#3F51B5', id:'@amiiin'},
                text: 'برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.'
            },
            {
                id:3,
                image:'https://randomuser.me/api/portraits/men/30.jpg',
                name:'نیما شمس',
                badge: {title:'مشتری با سابقه', color:'warning'},
                social: {icon:'instagram', color:'linear-gradient(to bottom right, #E91E63, #FF5722)', id:'@nimaaaaa'},
                text: 'ی طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروف'
            },
            {
                id:4,
                image:'https://randomuser.me/api/portraits/men/40.jpg',
                name:'شاهین قاسمی',
                badge: null,
                social: {icon:'linkedin', color:'#3F51B5', id:'@shahiiiin'},
                text: 'برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.'
            },
            {
                id:5,
                image:'https://randomuser.me/api/portraits/men/50.jpg',
                name:'حامد افشار',
                badge: null,
                social: {icon:'twitter', color:'#2196F3', id:'@haaaamed'},
                text: 'وای خدا باورتون نمیشه همین الان دامنه .com رو با چه قیمتی خریدم!!!! واقعاً قیمت های وی ثبت استثناییه'
            },
            {
                id:6,
                image:'https://randomuser.me/api/portraits/men/60.jpg',
                name:'محمد علوی',
                badge: null,
                social: {icon:'instagram', color:'linear-gradient(to bottom right, #E91E63, #FF5722)', id:'@haaaamed'},
                text: 'علاوه بر قیمت های رقابتی و بی نظیری که داره وی ثبت، میشه گفت گزینه خیلی خیلی خوبی برای وبمستر ها و سرویس های هاستینگ محسوب میشه'
            },
        ],
        footerStats: [
            {id:1, title:'پسوند بین المللی', count:614},
            {id:2, title:'دامنه ثبت شده', count:13415},
            {id:3, title:'کاربر فعال', count:10876},
            {id:4, title:'نماینده فروش', count:598}
        ],
        footerWidgets: [
            {id:1, widgetTitle:'با وی ثبت', type:'links', content:[
                    {id:1, title:'خانه', target:'/'},
                    {id:5, title:'ناحیه کاربری', target:'/panel'},
                    {id:2, title:'درباره ما', target:'/about'},
                    {id:3, title:'تماس با پشتیبانی', target:'/contact'},
                    {id:4, title:'قوانین و مقررات', target:'/terms-of-service'},
                ]
            },
            {id:2, widgetTitle:'توسعه دهندگان', type:'links', content:[
                    {id:1, title:'راهنمای توسعه دهندگان', target:''},
                    {id:2, title:'مستندات', target:''},
                    {id:3, title:'درخواست امکانات', target:''},
                    {id:4, title:'شرایط استفاده', target:''},
                    {id:5, title:'سوالات متداول', target:''},
                ]
            },
            {id:3, widgetTitle:'نمایندگی ها', type:'links', content:[
                    {id:1, title:'درباره دریافت نمایندگی', target:''},
                    {id:2, title:'راهنمای نمایندگان', target:''},
                    {id:3, title:'دریافت پنل نماینگی', target:''},
                    {id:4, title:'شرایط و ضوابط', target:''},
                    {id:5, title:'حریم خصوصی', target:''},
                ]
            },
        ],
        specialBottomNotice:{
            show: false,
            closed: true,
            content: "وی ثبت برای ارائه خدمات بهتر به شما کاربر گرامی از کوکی های مرورگر استفاده میکند",
            buttons:[
                {id:1, text:'متوجه شدم', color:'secondary', action:'close', target:''},
                {id:2, text:'اطلاعات بیشتر', color:'info', action:null, target:'/privacy-policy'}
            ],
            image: require('@/assets/images/svg/illustrations/cookie.svg'),
            duration:-1,
            color:"blue-grey darken-4",
        }
    }
};

const getters = {
    loadingScreen: (state) => (state.globalSettings.showLoadingScreen),
    navColorMode: (state) => (state.globalSettings.appearance.topBarColorMode),
    navBgColor: (state) => (state.globalSettings.appearance.topBarBg),
    navFixedStatus: (state) => (state.globalSettings.appearance.topBarFixStatus),
    mainMenu: (state) => (state.globalSettings.menuItems),
    briefPrices: (state) => (state.globalSettings.briefPrices),
    pricingTables: (state) => (state.globalSettings.pricingTables),
    faqItems: (state) => (state.globalSettings.faqItems),
    customerComments: (state) => (state.globalSettings.customerComments),
    footerStats: (state) => (state.globalSettings.footerStats),
    footerWidgets: (state) => (state.globalSettings.footerWidgets),
    specialBottomNotice: (state) => (state.globalSettings.specialBottomNotice),
    currentLang: (state) => (state.globalSettings.language.list.find(lang => lang.code === state.globalSettings.language.current)),
    currentCurrency: (state) => ((state.globalSettings.currency.list.find(cur => cur.code === state.globalSettings.currency.current))),
    langList: (state) => (state.globalSettings.language.list),
    currencyList: (state) => (state.globalSettings.currency.list),
};

const actions = {
    SpecialBottomNoticeIntersectHandler: ({ dispatch, state }) => {
        if(!state.globalSettings.specialBottomNotice.closed){
            dispatch('toggleSpecialBottomNotice')
        }
    },
    closeSpecialBottomNotice: ({ commit }) => {
        commit("_closeSpecialBottomNotice")
    },
    toggleSpecialBottomNotice: ({ commit }) => {
        commit("_toggleSpecialBottomNotice")
    },
    setLoadingScreen: ({ commit }, s) => {
        commit("_setLoadingScreen", s)
    },
    configAppearance: ({ commit }, colorMode) => {
        commit("_configAppearance", colorMode)
        },
    fixAppBar: ({ commit }) => {
        let viewHeight = window.innerHeight
        if (
            document.body.scrollTop > viewHeight ||
            document.documentElement.scrollTop > viewHeight
        ) {
            commit("_configNavBar", {fixed:true, bgColor:'rgba(255,255,255,0.8)'})
        } else {
            commit("_configNavBar", {fixed:false, bgColor:'transparent'})
        }
    },
    fetchIndexBriefPrices: async function ({ commit, dispatch },currency="IRT"){
        const url = `/search/prices/brief?base=${currency}`
        const result = await dispatch('apiCaller', {url: url, data:{}, reqType:'GET', callerID:'global', authReq: true})

        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'global'})
            } else {
                let prices = result.data
                prices.forEach((item,i) => (item.id = i))
                commit('_setBriefPrices', prices)
                return prices
            }
        }
        return false
    },
    fetchIndexPricingTables: async function ({ commit, dispatch },currency="IRT"){
        const url = `/search/prices/suggestion?base=${currency}`
        const result = await dispatch('apiCaller', {url: url, data:{}, reqType:'GET', callerID:'global', authReq: true})

        if (result){
            if (result.code !== 200){
                const message = {code : result.code, text: result.message}
                commit('_notifySend', {message: message, type:'global'})
            } else {
                let prices = result.data
                commit('_setIndexPricingTables', prices)
                return prices
            }
        }
        return false
    },
    setGlobalLocale: ({ commit }, locale) => {
        commit("_setGlobalLocale", locale)
    },
    fetchCurrentLocale: function ({ dispatch, commit, state }) {
        const locale = Vue.$cookies.get('VSABTLOCALE')
        if (locale){
            const [langCode, currencyCode] = locale.split('-')
            const lang = state.globalSettings.language.list.find(lang => lang.code === langCode)
            const currency = state.globalSettings.currency.list.find(cur => cur.code === currencyCode)
            dispatch('setGlobalLocale', {lang: lang.code, currency: currency.code})
        } else {
            dispatch('setGlobalLocale', {
                lang: state.globalSettings.language.list[0].code,
                currency: state.globalSettings.currency.list[0].code
            })
        }
    }
};

const mutations = {
    _closeSpecialBottomNotice: (state) => {
        state.globalSettings.specialBottomNotice.show = false
        state.globalSettings.specialBottomNotice.closed = true
    },
    _setLoadingScreen: (state, s) => {state.globalSettings.showLoadingScreen = s},
    _toggleSpecialBottomNotice: (state) => (state.globalSettings.specialBottomNotice.show = !state.globalSettings.specialBottomNotice.show),
    _configAppearance: (state, colorMode) => (state.globalSettings.appearance.topBarColorMode = colorMode),
    _configNavBar: (state, opts) => {
        state.globalSettings.appearance.topBarFixStatus = opts.fixed
        state.globalSettings.appearance.topBarBg = opts.bgColor
    },
    _setBriefPrices: (state, priceList) => (state.globalSettings.briefPrices = priceList),
    _setIndexPricingTables: (state, priceLists) => (state.globalSettings.pricingTables = priceLists),
    _setGlobalLocale: (state, locale) => {
        state.globalSettings.language.current = locale.lang? locale.lang : state.globalSettings.language.current
        state.globalSettings.currency.current = locale.currency? locale.currency : state.globalSettings.currency.current
        const localeValue = `${state.globalSettings.language.current}-${state.globalSettings.currency.current}`
        Vue.$cookies.set("VSABTLOCALE", localeValue,"365d");
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
