<template>
    <v-container tag="section" id="customers">
        <v-row>
            <v-col cols="12">
                <h1 class="section-title">دیدگاه شما برای ما مهم است</h1>
                <p class="section-description">
                    مشتریان درباره خدمات وی ثبت چه گفته اند؟
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div class="slider-wrapper">
                    <div class="author-image-wrapper">
                        <div class="image-item image2 d-lg-block d-none" :style="{backgroundImage: `url(${sliderProps.image2})`}"></div>
                        <div class="image-item image1 d-lg-block d-none" :style="{backgroundImage: `url(${sliderProps.image1})`}"></div>
                        <div class="image-item image0 active" :style="{backgroundImage: `url(${sliderProps.image0})`}">
                            <v-fab-transition hide-on-leave mode="out-in">
                                <div v-if="sliderProps.social.show" class="image-social-badge" :style="{background: sliderProps.social.color}">
                                    <v-icon color="white">mdi-{{ sliderProps.social.icon }}</v-icon>
                                </div>
                            </v-fab-transition>

                        </div>
                    </div>
                    <div class="author-text-wrapper">
                        <v-slide-x-reverse-transition mode="out-in">
                            <span class="title" v-html="" v-show="sliderProps.showComment">
                                {{ sliderProps.author }}
                                <v-chip v-if="sliderProps.badge" :color="sliderProps.badge.color" small>{{ sliderProps.badge.title }}</v-chip>
                            </span>
                        </v-slide-x-reverse-transition>
                        <v-slide-x-reverse-transition mode="out-in">
                            <p class="text" v-if="sliderProps.showComment">{{ sliderProps.text }}</p>
                        </v-slide-x-reverse-transition>
                    </div>
                    <div class="backdrop-layers"></div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "HomeCustomersSection",
        data() {
            return {
                sliderProps:{
                    image0: require('@/assets/images/users/default.jpg'),
                    image1: require('@/assets/images/users/default.jpg'),
                    image2: require('@/assets/images/users/default.jpg'),
                    text: "",
                    author: "",
                    social:{
                        show: false,
                        icon: '',
                        color: '',
                        id: '',
                    },
                    badge: null,
                    showComment: true
                },
                sliderContent: [],
            }

        },
        computed: mapGetters(["customerComments"]),
        methods: {
            slider: function (i, ){
                this.sliderProps.showComment = false
                this.sliderProps.social.show = false
                const s = this.sliderContent
                this.sliderProps.image0 = s[(i) % s.length].image
                this.sliderProps.image1 = s[(i+1) % s.length].image
                this.sliderProps.image2 = s[(i+2) % s.length].image

                setTimeout( () => {
                    this.sliderProps.author = s[(i) % s.length].name;
                    this.sliderProps.badge = s[(i) % s.length].badge;
                    this.sliderProps.text = s[(i) % s.length].text;
                    if (s[(i) % s.length].social){
                        this.sliderProps.social.icon = s[(i) % s.length].social.icon;
                        this.sliderProps.social.color = s[(i) % s.length].social.color;
                        this.sliderProps.social.id = s[(i) % s.length].social.id;
                    }
                    else{
                        this.sliderProps.social.icon = '';
                        this.sliderProps.social.color = '';
                        this.sliderProps.social.id = '';
                    }

                    setTimeout( () => {
                        this.sliderProps.showComment = true
                        this.sliderProps.social.show = true
                    }, 300);
                }, 400);
            }
        },
        created() {
            this.sliderContent = this.customerComments
        },
        mounted() {
            let i = 0
            this.slider(i, this.sliderContent)
            let timer = setInterval(() => {
                i++;
                this.slider(i, this.sliderContent)
            }, 7000)
        }
    }
</script>

<style>
    section#customers {
        position: relative;
        margin-top: 100px;
        margin-bottom: 150px;
    }

    section#customers h1.section-title{
        display: block;
        position: relative;
        text-align: center;
        font-weight: 800;
        font-size: 2rem;
        color: var(--c_indigo);
        text-shadow: 0 0 10px rgba(55,79,144,0.2);
        margin-top: 10px;
        margin-bottom: 25px;
        z-index: 10;
    }

    section#customers h1.section-title:before {
        content: "";
        position: absolute;
        background: url("~@/assets/images/svg/quote.svg") no-repeat center center;
        background-size: contain;
        width: 200px;
        height: 200px;
        top: -60px;
        z-index: -1;
    }

    section#customers p.section-description{
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 300;
        color: var(--c_gray_5);
        padding: 0 20px;
        line-height: 2rem;
        z-index: 10;
        margin-bottom: 35px;
    }

    section#customers .slider-wrapper{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 70px 0;
    }

    section#customers .slider-wrapper .author-image-wrapper{
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 25%;
    }

    section#customers .slider-wrapper .author-image-wrapper .image-item{
        display: block;
        width: 100px;
        height: 100px;
        background: url("~@/assets/images/users/default.jpg") no-repeat;
        background-size: cover;
        opacity: 0.3;
        border-radius: 100%;
        transition: all 1s ease-in-out;
    }

    section#customers .slider-wrapper .author-image-wrapper .image-item.active{
        position: relative;
        opacity: 1;
        width: 150px;
        height: 150px;
        box-shadow: var(--shadow_1);
        margin-left: -60px;
        z-index: 97;
    }

    section#customers .image-social-badge{
        position: absolute;
        width: 40px;
        height: 40px;
        color: white;
        left: 0;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: var(--shadow_1);
    }

    section#customers .slider-wrapper .author-text-wrapper {
        display: inline-block;
        flex: 1 0 auto;
        position: relative;
        background: white;
        border-radius: var(--border_radius);
        box-shadow: var(--shadow_1);
        padding: 30px 80px 30px 30px;
        height: 200px;
        max-width: 75%;
        transition: var(--transition);
        z-index: 1;
    }

    section#customers .slider-wrapper .backdrop-layers {
        display: block;
        position: absolute;
        height: 200px;
        width: 75%;
        top: 0;
        left: 0;
        z-index: 0;
    }

    section#customers .slider-wrapper .backdrop-layers:before{
        content: "";
        display: block;
        position: absolute;
        width: 90%;
        height: 80%;
        background-image: url("~@/assets/images/svg/8.svg");
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 100% 100%;
        right: 5%;
        top: 35%;
        border-radius: var(--border_radius);
        box-shadow: 0 0 50px rgba(119,119,119,.2);
        z-index: 0;
        opacity: .7;
    }

    section#customers .slider-wrapper .backdrop-layers:after{
        content: "";
        display: block;
        position: absolute;
        width: 80%;
        height: 80%;
        background-image: url("~@/assets/images/svg/8.svg");
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 100% 100%;
        right: 10%;
        top: 50%;
        border-radius: var(--border_radius);
        box-shadow: 0 0 50px rgba(119,119,119,.2);
        z-index: 0;
        opacity: .4;
    }

    section#customers .slider-wrapper .author-text-wrapper span.title{
        display: block;
        font-family: var(--font_text_1) !important;
        font-weight: 700;
        color: var(--c_secondary);
        margin-bottom: 15px;
    }

    section#customers .slider-wrapper .author-text-wrapper p.text{
        display: block;
        font-family: var(--font_text_1);
        font-weight: 300;
        color: var(--c_gray_5);
        line-height: 2rem;
    }

    section#customers .slider-wrapper .slide-list{
        display: none;
    }

    @media (max-width: 1264px){
        section#customers .slider-wrapper {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 70px 0;
        }
        section#customers .slider-wrapper .author-image-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: -40px;
        }
        section#customers .slider-wrapper .author-image-wrapper .image-item.active {
            margin-left: 0;
        }
        section#customers .slider-wrapper .author-text-wrapper {
            padding: 80px 40px 30px;
            min-height: 300px;
            height: 100%;
            width: 100%;
        }
        section#customers .slider-wrapper .author-text-wrapper span.title{
            text-align: center;
        }

        section#customers .slider-wrapper .author-text-wrapper p.text{
            text-align: center;
        }

        section#customers .slider-wrapper .backdrop-layers {
            height: 100%;
            width: 80%;
            top: 0;
            left: auto;
            right: auto;
        }

        section#customers .slider-wrapper .backdrop-layers:before{
            width: 90%;
            height: 50%;
            left: auto;
            top: 55%;
        }

        section#customers .slider-wrapper .backdrop-layers:after{
            height: 50%;
            left: auto;
            top: 60%;
        }
    }
    @media (min-width: 1264px) and (max-width: 1904px){

    }
    @media (min-width: 1904px){

    }
</style>
