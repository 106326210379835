<template>
    <div>
        <MainNavigation @toggleDrawer="drawer = !drawer"/>
        <MainNavigationDrawer :drawer="drawer" @updateDrawerStatus="onUpdateDrawerStatus"/>
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
        <MainFooterWrapper v-intersect="onIntersect"/>
        <SupportChatBtn v-show="!drawer" />
        <GoToTopBtn v-if="false" />
        <SpecialBottomNotice class="special-bottom-notice" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import MainNavigation from "@/views/index/Navigation/MainNavigation";
    import MainNavigationDrawer from "@/views/index/Navigation/MainNavigationDrawer";
    import MainFooterWrapper from "@/views/index/Footer/MainFooterWrapper";
    import SupportChatBtn from "@/components/SupportChatBtn";
    import GoToTopBtn from "@/components/GoToTopBtn";
    import SpecialBottomNotice from "@/components/SpecialBottomNotice";

    export default {
        name: "Index",
        components:{
            MainNavigation,
            MainNavigationDrawer,
            MainFooterWrapper,
            SupportChatBtn,
            GoToTopBtn,
            SpecialBottomNotice
        },
        data(){
            return{
                drawer: false,
                isIntersecting: false
            }
        },
        methods:{
            ...mapActions(["SpecialBottomNoticeIntersectHandler", "fixAppBar"]),
            onIntersect: function (entries, observer) {
                this.isIntersecting = entries[0].isIntersecting
            },
            onUpdateDrawerStatus: function (e){
                this.drawer = e
            },
        },
        watch:{
            isIntersecting: function(n, o){
                this.SpecialBottomNoticeIntersectHandler()
            },
        }
    }
</script>

<style scoped>

</style>
